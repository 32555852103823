var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('v-bottom-sheet',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-2","dark":"","right":"","bottom":"","fixed":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-table-edit")]),_vm._v(" Agregar Empleado ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":"","left":""},on:{"click":function($event){_vm.sheet = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('Employees')],1)],1),_c('v-card',{staticClass:"pt-10",attrs:{"elevation":"0","tile":"","width":"2000"}},[_c('v-btn',{on:{"click":_vm.GetDataCustomerReporte}},[_vm._v(" Descargar reporte ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-container',{staticStyle:{"margin-left":"0px","margin-right":"20px"},attrs:{"tile":"","elevation":"0"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","dense":"","solo":"","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.HeaderEmployees,"items-per-page":50,"items":_vm.Business,"hide-default-footer":true,"item-key":"id","search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id ? item.id : 'NA')+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name ? item.name : 'NA')+" ")]}},{key:"item.taxNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.taxNumber ? item.taxNumber : 'NA')+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.GetDataEmployee(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-table-edit")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isAvailable ? 'green' : 'red'}},[_vm._v("mdi-checkbox-blank-circle")])]}},{key:"item.employees",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"pt-2",attrs:{"label":"Operador","multiple":"","rules":_vm.requiredSelectRule,"items":item.employees.employeeId,"item-text":function (item) { return item.firstName + ' ' + item.lastName; },"item-value":"id","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"v-select__selection v-select__selection--comma"}):_vm._e()]}}],null,true),model:{value:(_vm.shipment.users),callback:function ($$v) {_vm.$set(_vm.shipment, "users", $$v)},expression:"shipment.users"}})]}}])})],1)],1)],1)]],2)],1)],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 blue darken-4 white--text"},[_vm._v(" Empleados ")]),_c('v-card-text',[_c('v-select',{staticClass:"mt-4",attrs:{"label":"Empleados","chips":"","deletable-chips":"","multiple":"","items":_vm.Employees,"item-text":function (item) { return item.firstName + ' ' + item.lastName; },"item-value":"id","dense":""},model:{value:(_vm.EmployesSelected),callback:function ($$v) {_vm.EmployesSelected=$$v},expression:"EmployesSelected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.putEmployees()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }