import Vue from 'vue'
import VueRouter from 'vue-router'
import Preview from '@/views/Pages/Test/PreviewComponent.vue'
import Login from "@/views/Pages/Auth/Login";
import Index from "@/views/Pages/Index";
import Quote from "@/views/Pages/Quote/Quote";
import Users from "@/views/Pages/Users/Users";
import Pricing from "@/views/Pages/Pricing/Pricing";
import ProceedQuote from "@/components/Pricing/ProceedQuote";
import Sales from "@/views/Pages/Sale/Sales";
import ProceedQuoteSales from "@/components/Sales/ProceedQuoteSales";
import IndexAdmin from "@/views/Pages/Users/IndexAdmin";
import TestSupplier from "@/components/Pricing/SuppliersAucomplete";
import PdfTest from "@/components/pdf-test";
import NotificationBar from "../components/NotificationBar";
import Places from "../components/Places";
import Test from "../components/Test/Test";
import pruebas from "../views/Pages/Operation/pruebas";
import operationsList from "../views/Pages/Operation/components/operationsList.vue"
import operationList2 from "../views/Pages/Operation/components/operationList2.vue"
import operationVenta from "../views/Pages/Operation/components/operationVenta.vue"
import RateCard from "@/components/Rates/RateCard";
import salesComprayVenta from "@/views/Pages/Sale/salesComprayVenta";
import shipment from "@/views/Pages/Sale/shipment";
import seniorAnalytics from "@/views/Pages/Sale/seniorAnalytics";
import PricingDashboard from "@/views/Pages/Pricing/PricingDashboard";

Vue.use(VueRouter)

const routes = [

  {
    path:'/',
    redirect:Login
  },
  {
    path:'/RateCard',
    redirect:RateCard
  },
  {
    path:'/Pdf',
    name:'Pdf',
    component:PdfTest
  },
  {
    path:'/pruebas',
    name:'pruebas',
    component:pruebas
  },
  {
    path: '/Login',
    name:'Login',
    component:Login
  },

  {
    path: '/Places',
    name:'Places',
    component:Places
  },
  {
    path: '/AppBar',
    name:'AppBar',
    component:NotificationBar
  },
  {
    path: '/app',
    name: "app",
    component: Index,
    children: [
      {
        path:'/seniorAnalytics',
        name:'seniorAnalytics',
        component:seniorAnalytics
      },
      {
        path:'/operationList2',
        name:'operationList2',
        component:operationList2
      },
      {
        path:'/operationVenta/:id',
        name:'operationVenta',
        component:operationVenta
      },
      {
        path: '/Quote',
        component: Quote,
        name: 'Quote'

      },
      {
        path: '/Users',
        component: Users,
        name: 'UserList'
      },

      {
        path: '/Pricing',
        component: Pricing,
        name: 'Pricing',
      },
      {
        path: '/pricing/dashboard',
        component: PricingDashboard,
        name: 'Pricing Dashboard',
      },
      {
        path: '/ProceedQuote/:id',
        component: ProceedQuote,
        name: 'ProceedQuote',

      },
      {
        path: '/Sales',
        component: Sales,
        name: 'Sales'
      },
      {
        path: '/salesCompraYVenta',
        component: salesComprayVenta,
        name: 'salesCompraYVenta'
      },
      {
        path: '/shipment/:id',
        component: shipment,
        name: 'shipment'
      },
      {
        path: '/ProceedQuoteSales/:id',
        component: ProceedQuoteSales,
        name: 'ProceedQuoteSales',

      },

      {
        path: '/AccountsAdmin',
        component: IndexAdmin,
        name: 'Register',

      },

      {
        path: '/TestFees',
        component: Test,
        name: 'TestFees',

      },
      {
        path: '/TestSupplier',
        component: TestSupplier,
        name: 'TestSupplier',
      },
      {
        path: '/Test',
        component: Test,
        name:'Test'
      },
      {
        path: '/ShipmentCreation',
        name: 'ShipmentCreation',
        component: () => import('@/views/Pages/Operation/components/ShipmentCreation')
      },
      {
        path: '/seniorAnalytics',
        name: 'seniorAnalytics',
        component: () => import('@/views/Pages/Sale/seniorAnalytics')
      },
      {
        path: '/Operation/:id',
        name: 'opspage',
        component: () => import('@/views/Pages/Operation/Operations')
      },

      {
        path: '/Embarques',
        name: 'Embarques',
        component: () => import('@/views/Pages/Sale/Embarques')
      },
      {
        path: '/Embarque',
        name: 'Embarque',
        component: () => import('@/views/Pages/Sale/Embarque')
      },
      {
        path: '/Embarque',
        name: 'Embarque',
        component: () => import('@/views/Pages/Sale/Embarque')
      },
      {
        path: '/OperationsList',
        name: operationsList,
        component: operationsList,
      }
    ]
  },

  {
    path: '/Preview',
    name:'Preview',
    component:Preview
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
