<template>
  <div :key="compKey">
    <slot name="detail">
      <v-row justify="center">
        <v-col md="12">
          <v-card elevation="0" outlined>
            <v-card-title>
              <v-row justify="center">
                <v-col md="12">
                  <v-row>
                    <v-col md="2">
                      <span class="subtitle-2">Día de solicitud</span>
                      <h5 class="subtitle-2 ">
                        {{ DataOrder.createdAt ? moment(DataOrder.createdAt).format('DD-MM-YYYY') : 'NA' }}</h5>
                    </v-col>
                    <v-col md="3">
                      <span class="subtitle-2">Mercancía lista</span>
                      <h5 class="subtitle-2 ">
                        {{ DataOrder.departureDate ? moment(DataOrder.departureDate).format('DD-MM-YYYY') : 'NA' }}</h5>
                    </v-col>
                    <v-col md="5" v-if="!isOperation">
                      <h2 class="font-weight-regular">{{ DataOrder.refCode ? DataOrder.refCode : 'NA' }}
                        <v-icon v-if="DataOrder.urgent" left size="40" color="red">mdi-alert-outline</v-icon>
                      </h2>
                    </v-col>


                    <v-col style="text-align: right" v-if="!isOperation">
                      <v-menu offset-y v-if="DataOrder.statusOrder!='Cancelada'">
                        <template v-slot:activator="{on: menu}">
                          <v-btn small icon v-on="menu" v-if="DataOrder.statusOrder !== 'Vendida'">
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list-item @click="ShowNewComment">
                            <v-list-item-content>
                              Devolver
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="ShowEditQuote">
                            <v-list-item-content>
                              Editar
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-menu>
                      <v-btn small icon @click="$router.push('/Pricing')">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <br>
                </v-col>

              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>

                <v-col md="2">
                  <span>Origen</span><br>
                  <!--                  <span class="font-weight-bold">{{ DataOrder.origin[0].country }}</span>-->
                  <span class="font-weight-bold">{{ DataOrder.origin && DataOrder.origin[0] && DataOrder.origin[0].address ? DataOrder.origin[0].address : 'NA' }}</span>
                </v-col>

                <v-col md="2">
                  <span>Destino</span><br>
                  <span class="font-weight-bold">{{ DataOrder.destination && DataOrder.destination[0] && DataOrder.destination[0].address ? DataOrder.destination[0].address : 'NA'}}</span>
                </v-col>

                <!--                <v-col md="2" v-if="DataOrder.transportationCategoryId == 2 || DataOrder.transportationCategoryId==1">-->
                <!--                  <span>CBM Total</span><br>-->
                <!--                  <span class="font-weight-bold">{{ DataOrder.cbmTotal }}</span>-->
                <!--                </v-col>-->


                <v-col md="2">
                  <span>Tipo</span><br>
                  <span class="font-weight-bold">{{ DataOrder && DataOrder.orderType && DataOrder.orderType.description ? DataOrder.orderType.description : 'NA' }}</span>
                </v-col>


                <v-col md="2">
                  <span>Target</span><br>
                  <span
                      class="font-weight-bold">{{ DataOrder.budget }}</span>
                </v-col>

                <v-col md="2">
                  <span>Industria</span><br>
                  <span class="font-weight-bold">{{ DataOrder.industry && DataOrder.industry.description ? DataOrder.industry.description : 'NA' }}</span>
                </v-col>

                <v-col md="2">
                  <span>Commodity</span><br>
                  <span class="font-weight-bold">{{ DataOrder.commodity ? DataOrder.commodity : 'NA' }}</span>
                </v-col>

                <v-col md="2">
                  <span>Frecuencía</span><br>
                  <span
                      class="font-weight-bold">{{ DataOrder.frequency }}</span>
                </v-col>

                <v-col md="2">
                  <span>Responsable Ventas</span><br>
                  <span class="font-weight-bold">
                        {{ DataOrder.salesManager && DataOrder.salesManager.firstName ? DataOrder.salesManager.firstName : 'NA' }}
                        {{ DataOrder.salesManager && DataOrder.salesManager.lastName ? DataOrder.salesManager.lastName : 'NA' }}
                      </span>
                </v-col>


                <v-col md="2">
                  <span>Cliente</span><br>
                  <span class="font-weight-bold">{{ DataOrder.client.name ? DataOrder.client.name : 'NA' }}</span>
                </v-col>
                <v-col md="2">
                  <span>Transbordo</span><br>
                  <span class="font-weight-bold">{{ DataOrder.landTransfer ? 'Si' : 'No' }}</span>
                </v-col>

                <v-col md="2" v-if="DataOrder.landServiceId">
                  <span>Land Service</span><br>
                  <span class="font-weight-bold">{{ landService.Description }}</span>
                </v-col>

                <v-col md="2" v-if="DataOrder.goodsCost != null">
                  <span>Costo de mercancia</span><br>
                  <span class="font-weight-bold">{{ DataOrder.goodsCost }}</span>
                </v-col>

                <v-col md="2">
                  <span>Time to request provider</span><br>
                  <span v-if="DataOrder.orderStatusLifetime.length" class="font-weight-bold">{{
                      DataOrder.orderStatusLifetime[0].dueDate | luxon({
                        output: {
                          zone: "local",
                          format: "short"
                        }
                      })
                    }}</span>
                </v-col>

                <v-col md="2">
                  <span>Expected price time</span><br>
                  <span v-if="DataOrder.orderStatusLifetime.length" class="font-weight-bold">{{
                      DataOrder.orderStatusLifetime[1].dueDate | luxon({
                        output: {
                          zone: "local",
                          format: "short"
                        }
                      })
                    }}</span>
                </v-col>


              </v-row>
            </v-card-text>
            <v-card-actions v-if="!isOperation">

              <v-row justify="center">
                <v-col md="12">
                  <v-btn v-if="show == false" class="offset-6" icon @click="show = !show">
                    <v-icon size="35">{{ 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-expand-transition mode="out-in">
              <div v-show="show">
                <v-card-text>
                  <v-row>


                    <v-col md="12">
                      <span>Comentarios</span><br>
                      <v-card outlined>
                        <v-card-text class="font-weight-bold" style="white-space: pre-wrap;">{{ DataOrder.comments }}</v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="12">
                      <br><br>
                      <v-card outlined dense>
                        <v-row>
                          <v-col md="8">
                            <v-card-title class="black--text">Detalle de Carga</v-card-title>
                          </v-col>
                          <v-col md="4">
                            <br>
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" rounded text v-bind="attrs" v-on="on">
                                  <span
                                      class="font-weight-bold black&#45;&#45;text">Servicios ({{
                                      DataOrder.srv.normal.length
                                    }})</span>
                                  <v-icon color="black">mdi-chevron-down</v-icon>

                                </v-btn>
                              </template>


                              <v-card>
                                <v-list subheader>
                                  <v-subheader>Servicios</v-subheader>
                                  <div v-for="item in DataOrder.srv.normal" :key="item.id">
                                    <v-list-item>
                                      {{ item.service.name }}
                                    </v-list-item>
                                  </div>
                                </v-list>



                                <v-card-actions>
                                </v-card-actions>
                              </v-card>
                            </v-menu>

                            <v-menu v-model="menuA" :close-on-content-click="false" :nudge-width="100">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" rounded text v-bind="attrs" v-on="on">
                                  <span
                                      class="font-weight-bold black&#45;&#45;text">Servicios Adicionales ({{
                                      DataOrder.srv.optional.length
                                    }})</span>
                                  <v-icon color="black">mdi-chevron-down</v-icon>

                                </v-btn>
                              </template>


                              <v-card>
                                <v-list subheader>
                                  <v-subheader>Servicios Adicionales</v-subheader>
                                  <div v-for="item in DataOrder.srv.optional" :key="item.id">
                                    <v-list-item>
                                      {{ item.service.name }}
                                    </v-list-item>
                                  </div>
                                </v-list>



                                <v-card-actions>
                                </v-card-actions>
                              </v-card>
                            </v-menu>



                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row v-for="data in DataOrder.cargo" :key="data.id">

                            <v-col md="2">
                              <span>Servicio</span>
                              <br>
                              <span
                                  class="font-weight-bold">{{DataOrder.transportationCategory && DataOrder.transportationCategory.description ? DataOrder.transportationCategory.description : 'NA'  }} - {{ data.container && data.container.description ? data.container.description : '' }}</span>
                            </v-col>

                            <v-col md="2">
                              <span>Cantidad</span><br>
                              <span
                                  class="font-weight-bold">{{ data.quantity }}</span>
                            </v-col>

                            <v-col md="2">
                              <span>Peso</span><br>
                              <span
                                  class="font-weight-bold">{{ data.weight }}</span>
                              <small>{{data.weightUnitMeasure}}</small>
                            </v-col>

                            <v-col md="2" v-if="DataOrder.transportationCategoryId == 1">
                              <span>Medidas</span><br>
                              <span class="font-weight-bold">{{ data.large.toFixed(2) }}x{{ data.width.toFixed(2) }}x{{ data.height.toFixed(2) }}</span>
                              <small>{{ data.unitMeasure }}</small>
                            </v-col>

                            <v-col md="2">
                              <span>Peligroso</span><br>
                              <span class="font-weight-bold">{{ data.dangerous ? 'Si' : 'No' }}</span>
                            </v-col>

                            <v-col md="2">
                              <span>Estibable</span><br>
                              <span class="font-weight-bold">{{ data.stackable ? 'Si' : 'No' }}</span>
                            </v-col>


                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>


                  <v-row justify="center">
                    <v-col md="12">
                      <v-btn class="offset-6" icon @click="show = !show">
                        <v-icon size="35">{{ 'mdi-arrow-up-drop-circle-outline' }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </slot>
  </div>
</template>
<script>
import folio_mixin from "@/mixins/folio_mixin";

export default {
  name: "DetailOrderTerrestre",
  mixins: [folio_mixin],
  components: {},
  props: {
    id: Number,
    isOperation: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    id: function (val) {
      if (val) {
        this.GetDataOrder();

      }
    },

    /*showDialogEdit:function (){
      this.$emit('ShowDialog',this.showDialogEdit)
    },
    showNewComment:function () {
      this.$emit('ShowComment',this.showNewComment)
    }*/
  },
  data() {
    return {
      compKey: 0,
      menu: false,
      menuA: false,
      tab: 0,
      show: false,
      landTransportation: '',
      active: false,
      showDialogCancelation: false,
      showDialogEdit: false,
      showNewComment: false,
      showExpand: false,
      editQuote: false,
      OrderStatus: [{
        name: 'Solicitado a Proveedor'
      },

        {
          name: 'Enviado a Ventas'
        },
      ],
      DataOrder: [],
      LandServices: [
        {
          Id: 1,
          Description: 'Consolidado',
          Status: 1
        },

        {
          Id: 2,
          Description: 'Dedicado',
          Status: 1
        },

        {
          Id: 3,
          Description: 'Hot Shot',
          Status: 1
        },
      ],
    }
  },
  computed: {
    landService(){
      return this.LandServices.find(el => el.Id === this.DataOrder.landServiceId)
    }
  },
  methods: {
    forceRerender() {
      console.log("Updated")
      this.GetDataOrder();
      this.compKey += 1;
    },
    ShowNewComment: function () {
      this.active = false;
      this.active = true;
      this.$emit('ShowComment', true)
    },
    ShowCancelation: function () {
      console.log('Emit cancelation event')
      this.showDialogCancelation = true;
      this.$emit('OpenCancelationModal', true)
    },
    ShowEditQuote: function () {
      this.showDialogEdit = true;
      this.editQuote = false;
      this.editQuote = true;
      this.$emit('ShowDialog', this.showDialogEdit)
    },

    mapOrder(order) {
      return {
        Id: order.id,
        TransportationId: order.transportationId,
        CommentsToPricer: order.commentsToPricer,
        TransportationCategoryId: order.transportationCategoryId,
        IncotermId: order.incotermId,
        OrderTypeId: order.orderTypeId,
        LandTransfer: order.landTransfer,
        LandTransportation: order.landTransportation,
        LandDedicated: order.landDedicated,
        LandFull: order.landFull,
        Terms: order.terms,
        Origin: order.origin,
        Destination: order.destination,
        StatusOrder: order.statusOrder,
        Pol: order.pol,
        Pod: order.pod,
        DepartureDate: order.departureDate,
        IndustryId: order.industryId,
        Budget: order.budget,
        Commodity: order.commodity,
        InsideBillOfLanding: order.insideBillOfLanding,
        Cargo: order.cargo,
        Services: order.services,
        ClientId: order.clientId,
        PricerId: order.pricerId,
        SalesManagerId: order.salesManagerId,
        Pricer: order.pricer,
        SalesManager: order.salesManager,
        Client: order.client,
        OrderType: order.orderType,
        Currency: order.currency,
        Incoterm: order.incoterm,
        Transportation: order.transportation,
        TransportationCategory: order.transportationCategory,
        Industry: order.industry,
        Frequency: order.frequency,
        Urgent: order.urgent,
        Comments: order.comments,
        CreatedAt: order.createdAt,
      }
    },

    MapOrderToList(obj) {
      let order = this.mapOrder(obj)

      this.formatFolio(order);

      this.DataOrder.folio = order.folio;

      this.DataOrder.cargo.forEach(element => {
        if (element.type == "Container") {
          if (element.weight <= 21000) {
            element.legal = true;
          } else
            element.legal = false;
        }
      })

      return order
    },


    GetDataOrder: function () {


      this.$emit('idOrder', this.id);

      console.log('Hello')

      this.$store.dispatch('working', true)

      this.$http.get(`Orders/${this.id}`).then(response => {

        if (response.data.code === 400) {
          this.$router.replace({
            name: "Pricing"
          })
        }
        console.log(response)
        this.DataOrder = response.data.data;
        var fecha = this.DataOrder.date;
        fecha = new Date(fecha);
        this.DataOrder.date = fecha;
        console.log(this.DataOrder.date);
        console.log("DETAIL", this.DataOrder);
        // this.id = 0;
        this.MapOrderToList(this.DataOrder);
        this.$emit('statusOrder', this.DataOrder.statusOrder);
        // console.log(this.DataOrder)
        // console.log(this.MapOrderToList(this.DataOrder))
        let cbmTotal = 0;
        this.DataOrder.cargo.forEach(element => {
          cbmTotal = cbmTotal + element.volumetricWeight
        })
        this.DataOrder.cbmTotal = cbmTotal

        this.DataOrder.cargo.forEach(element => {

          if (element.large>0 && element.width>0 && element.height>0){
            this.showMeasure = true;
          }

        })

        // Arrange Services
        this.DataOrder.srv = {
          normal: [],
          optional: []
        };

        const optionalServices = [15,8,1,16,17,18,19,20];

        this.DataOrder.services.forEach((el) => {
          let isOptional = optionalServices.includes(el.serviceId);
          if(isOptional){
            this.DataOrder.srv.optional.push(el);
          }else{
            this.DataOrder.srv.normal.push(el);
          }
        });


      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    }
  },
  mounted() {
    this.GetDataOrder();
  }
}
</script>

<style scoped>
>>> .v-application--wrap {
  min-height: 0px
}

</style>
