<template>
  <v-container class="fill-height">
    <v-row class="fill-height pb-14" align="end">
      <v-col>
        <div v-for="(history, index) in DataOrder.history" :key="index"
             :class="['d-flex flex-row align-center my-2', isCurrentUser(history.user) ? 'justify-end': null]">

          <v-sheet v-if="isCurrentUser(history.user)" class="mr-3 text-right" dark color="primary">
            <v-list-item dense>
              <v-list-item-action v-if="history.historyDocuments.length > 0">
                <v-menu offset-y>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon
                           small
                           v-bind="attrs"
                           v-on="on">
                      <v-icon small>
                        mdi-paperclip
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card flat tile width="300">
                    <v-virtual-scroll
                        :items="history.historyDocuments"
                        height="250"
                        item-height="42"
                    >
                      <template v-slot:default="{item}">
                        <v-list-item :key="item.id" @click="downloadDocument(item,history.id)">
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                              {{ item.fileName }}.{{ item.fileExtension }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon color="primary">
                              <v-icon>mdi-cloud-download</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-card>
                </v-menu>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ history.user.firstName }} {{ history.user.lastName }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap">
                  {{ history.comments }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
          <v-sheet v-if="!isCurrentUser(history.user)" class="ml-3 text-left" dark color="secondary">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ history.user.firstName }} {{ history.user.lastName }}
                </v-list-item-title>

                <v-list-item-subtitle class="text-wrap">
                </v-list-item-subtitle>

                <v-list-item-subtitle class="text-wrap">
                </v-list-item-subtitle>

                <v-list-item-subtitle class="text-wrap">
                  {{ history.reason.description }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="text-wrap">
                  {{ history.comments }}
                </v-list-item-subtitle>


              </v-list-item-content>
              <v-list-item-action v-if="history.historyDocuments.length > 0">
                <v-menu offset-y>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon
                           small
                           v-bind="attrs"
                           v-on="on">
                      <v-icon small>
                        mdi-paperclip
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card flat tile width="300">
                    <v-virtual-scroll
                        :items="history.historyDocuments"
                        height="250"
                        item-height="42"
                    >
                      <template v-slot:default="{item}">
                        <v-list-item :key="item.id" @click="downloadDocument(item,history.id)">
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                              {{ item.fileName }}.{{ item.fileExtension }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon color="primary">
                              <v-icon>mdi-cloud-download</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-card>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <!--  <v-timeline-->
  <!--      :dense="$vuetify.breakpoint.smAndDown"-->
  <!--  >-->
  <!--    <v-timeline-item-->
  <!--        v-for="(item, i) in DataOrder.history"-->
  <!--        :key="i"-->
  <!--        hide-dot-->
  <!--    >-->
  <!--      <template v-slot:opposite>-->

  <!--      </template>-->
  <!--      <span>{{item.user.firstName}} {{item.user.lastName}}</span>-->
  <!--      <br>-->
  <!--      <v-alert-->
  <!--          dense-->
  <!--          type="info"-->
  <!--      >-->

  <!--        {{item.comments}}-->
  <!--      </v-alert>-->
  <!--    </v-timeline-item>-->
  <!--  </v-timeline>-->
</template>

<script>
import {mapGetters} from "vuex";
import fileDownload from "js-file-download";

export default {
  name: "OrderHistory",
  props: {
    idOrder: {
      type: Number
    }
  },
  watch: {
    idOrder(val) {
      this.GetDataOrder(val);
    }
  },
  data() {
    return {
      DataOrder: [],
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'lastname'
    ]),
  },
  methods: {
    downloadDocument(doc,historyId) {
      this.$store.dispatch('working', true);
      this.$http({
        url: `/Orders/${this.idOrder}/OrderHistory/${historyId}/Document/${doc.id}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
        // this.$toast.info('Pdf Generado');
        // var file = new Blob([response.data], {type: 'application/pdf'});
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      }).catch(error => {
        this.$toast.error("Algo salio mal, intente mas tarde")
        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    isCurrentUser(user) {
      return `${user.firstName} ${user.lastName}` == `${this.name} ${this.lastname}`
    },
    GetDataOrder: function (idOrder) {


      this.$http.get(`Orders/${idOrder}`).then(response => {
        if (response.data.code == 200) {
          console.log(response)
          this.DataOrder = response.data.data;
          this.$emit('tab', 1);
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
        console.log(error)
      }).finally(() => {
      })
    },

  },
  mounted() {
    this.GetDataOrder(this.idOrder)
  }
}
</script>

<style scoped>


.v-timeline:before {
  bottom: 0;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 0px;
}

</style>
