<template>
  <div>
    <v-stepper v-model="eClientes" vertical>
      <v-stepper-step :complete="eClientes > 1" step="1">
        Datos Personales

      </v-stepper-step>

      <v-stepper-content step="1">
        <v-row class="justify-center">
          <v-col cols="3">
            <v-img contain :src="computedCompanyLogo" alt="Logo"></v-img>
          </v-col>
        </v-row>

        <v-form ref="clientForm" v-model="clientValid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="company.name" :rules="nameRules" label="Nombre(s)" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="company.email" :rules="emailRules" label="Correo Electronico" required>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="company.phone" :rules="HomePhoneRules" label="Telefono" required></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="company.taxNumber" :rules="HomePhoneRules" label="Rfc de cliente" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="company.countryId" label="Pais" :rules="CountryRules" :items="Country"
                                item-text="name" item-value="id" @change="GetDataCountry()" required></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="company.cityId" label="Ciudad" :rules="CityRules" :items="Cities"
                                :loading="loadingCities"
                                item-text="name" item-value="id" required></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="company.postalCode" label="Codigo Postal"></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-file-input label="Logo de cliente" v-model="companyLogo"
                              @change="ConvertFile(companyLogo)"></v-file-input>
              </v-col>
            </v-row>
            <h4 class="mt-6 font-weight-light">
              <v-icon left color="primary">mdi-account-multiple</v-icon>
              Contactos
            </h4>
            <v-divider></v-divider>
            <v-row v-for="(item,index) in company.contacts" :key="index">
              <v-col cols="12" md="3">
                <v-text-field v-model="item.name" :rules="nameRules" label="Nombre"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="item.email" :rules="emailRules" label="Correo"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="item.telephone" :rules="HomePhoneRules" label="Telefono"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="justify-center align-content-center">
                <v-btn icon color="error" v-if="index !== 0" @click="removeContact(index)" class="my-auto d-block">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon color="info" v-if="company.contacts.length-1 === index" @click="newContact"
                       class="my-auto d-block">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-btn color="primary" @click="NewCompany(company.id)" :disabled="!clientValid">
          Guardar
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: "Customers",
  props: {
    CustomerData: {},
    Companies: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    CustomerData: function () {
      this.setCustomers()
    }
  },
  computed: {
    computedCompanyLogo() {
      return this.company.configuration?.logo ? 'data:image/png;base64,' + this.company.configuration.logo : require('@/assets/placeholder.png')
    },
  },
  data() {
    return {
      clientValid: false,
      loadingCities: false,
      companyLogo: null,
      CountryData: {},
      Country: [],
      Cities: [],
      Servicios: [],

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 400,
        maxFilesize: 0.5,
        dictDefaultMessage: 'Logo del proveedor'
      },
      defaultContact: {
        email: null,
        name: null,
        telephone: null
      },
      eClientes: 1,
      e6: 1,
      company: {
        id: 0,
        cityId: null,
        countryId: null,
        postalCode:null,
        name: null,
        email: null,
        phone: null,
        employees: [],
        configuration: {
          id: null,
          logo: null
        },
        contacts: [
          {
            email: null,
            name: null,
            telephone: null
          }
        ],
      },
      nameRules: [
        v => !!v || 'Nombre es requerido',
      ],
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'Correo Invalido',
      ],
      HomePhoneRules: [
        v => !!v || 'Telefono es requerido',
      ],
      RfcRules: [
        v => !!v || 'RFC es requerido',
      ],
      CountryRules: [
        v => !!v || 'Seleccione País',
      ],
      CityRules: [
        v => !!v || 'Seleccione Ciudad',
      ],
      AddressRules: [
        v => !!v || 'Dirección es requerido',
      ],
      AnualRules: [
        v => !!v || 'Envio anual es requerido',
      ],
      FrecuenteRules: [
        v => !!v || 'Envio anual es requerido',
      ],
      CreditoRules: [
        v => !!v || 'Dias de credito es requerido',
      ],
      ServiciosRules: [
        v => !!v || 'Servicio es requerido',
      ],
    }
  },
  methods: {
    newContact() {
      this.company.contacts.push(JSON.parse(JSON.stringify(this.defaultContact)))
    },
    removeContact(index) {
      this.company.contacts.splice(index, 1);
    },
    GetServices: function () {
      this.$store.dispatch('working', true)
      this.$http.get('Services')
          .then(response => {
            if (response.data.code == 200) {
              this.Servicios = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {


            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },
    resetCompany() {
      this.company = {
        id: 0,
        cityId: null,
        countryId: null,
        employees: [],
        postalCode:null,
        name: null,
        email: null,
        phone: null,
        configuration: {
          logo: null
        },
        contacts: [
          {
            email: null,
            name: null,
            telephone: null
          }
        ],
      }
    },
    NewCompany: function (companyId) {

      this.$store.dispatch('working', true);
      if (companyId) {
        let exist = this.Companies.findIndex(element => element.id = companyId)
        if (exist != -1) {
          //alert('ya existe')
          this.company.name = this.company.name.replace(' sa de cv', '');
          this.company.name = this.company.name.replace(' s.a. de c.v. ', '');
          this.company.name = this.company.name.replace(' S de R. L.', '');
          console.log(this.company)
          this.$http.put(`Companies/${companyId}`, this.company)
              .then(response => {
                if (response.data.code == 200) {
                  console.log(response.data)
                  this.$emit('update')
                  this.$toast.success("Compañia actualizada")
                }
                if (response.data.code == 400 || response.data.code == 500)
                  this.$toast.error("Algo salio mal, intente mas tarde")
              })
              .catch(error => {
                console.log(error)
                this.$toast.error("Error al añadir compañia, intente mas tarde");
              })
              .finally(() => this.$store.dispatch('working', false))
        }

      } else {
        //alert('no existe')
        delete this.company.id;
        delete this.company.configuration.id
        this.company.name = this.company.name.replace(' sa de cv', '');
        this.company.name = this.company.name.replace(' s.a. de c.v. ', '');
        this.company.name = this.company.name.replace(' S de R. L.', '');
        console.log(this.company)
        this.$http.post('Companies', this.company)
            .then(response => {
              if (response.data.code == 200) {
                console.log(response.data)
                this.$emit('update')
                this.$toast.success("Compañia Agregada")
                this.resetCompany();
              }
              if (response.data.code == 400 || response.data.code == 500)
                this.$toast.error("Algo salio mal, intente mas tarde")
            })
            .catch(error => {
              console.log(error)
              this.$toast.error("Error al añadir compañia, intente mas tarde");
            })
            .finally(() => this.$store.dispatch('working', false))

      }

    },

    GetCountry: function () {
      this.$store.dispatch('working', true);
      this.$http.get('Countries')
          .then(response => {
            if (response.data.code == 200) {
              this.Country = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")

          })
          .catch(error => {

            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },
    GetCities: function (code) {
      this.$http.get(`Cities/Country/${code}`)
          .then(response => {

            if (response.data.code == 200) {
              this.Cities = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {

            console.log(error)

          }).finally(() => {
        this.$store.dispatch('working', false);
        this.loadingCities = false;
      })

    },
    UploadFile: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    GetDataCountry() {

      this.loadingCities = true;
      console.log(this.company.countryId)
      let dataCountry = this.Country.find(element => element.id == this.company.countryId)
      console.log(dataCountry.iso2)
      this.GetCities(dataCountry.iso2);
    },

    ConvertFile: function (file) {
      console.log(file);
      this.UploadFile(file).then(response => {
        console.log(response);
        var data = response.split(',')[1];
        this.company.configuration.logo = data;
        this.company.configuration.creditDays = 1;
      }).catch(error => {
        console.log(error)
      })
    },
    setCustomers() {
      if (this.CustomerData) {
        this.resetCompany()
        console.log(this.CustomerData)
        this.company.id = this.CustomerData.id;
        this.company.name = this.CustomerData.name
        this.company.email = this.CustomerData.email
        this.company.phone = this.CustomerData.phone
        this.company.postalCode = this.CustomerData.postalCode
        this.company.taxNumber = this.CustomerData.taxNumber
        if (this.CustomerData.contacts.length === 0){
          this.company.contacts[0] = {
            name: null,
            email: null,
            telephone: null,
          }
        } else {
          this.company.contacts = this.CustomerData.contacts
          let arr = {}
          this.company.contacts.push(arr)
          this.company.contacts.splice(-1)
        }

        // this.company.address = this.CustomerData.address
        // this.company.taxNumber = this.CustomerData.taxNumber

        this.company.countryId = this.CustomerData.countryId;


        this.company.cityId = this.CustomerData.cityId

        this.company.configuration.id = this.CustomerData.configuration.id

        this.company.configuration.logo = this.CustomerData.configuration.logo


        this.GetDataCountry();

        // this.company.Configuration.annualShipments = this.CustomerData.configuration.annualShipments
        // this.company.Configuration.frequentShipments = this.CustomerData.configuration.frequentShipments
        // this.company.Configuration.creditDays = this.CustomerData.configuration.creditDays
        // this.company.Services[0].serviceId = this.CustomerData.services[0].serviceId
      }
      // console.log('this is data C', this.company)
      // console.log('this is prop C', this.CustomerData)
    }
  },
  mounted() {
    this.GetCountry();
    this.GetServices();
  }
}
</script>

<style scoped>

</style>
