<template>
  <div>
    <v-card elevation="0">
      <v-row justify="center">
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="Data"
              :search="search"
              :hide-default-footer="true"
              :items-per-page="25"
              :pagination="options"
              item-key="name"
          >

            <template v-slot:expanded-item>
            </template>



            <template v-slot:item.action="{item}">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text @click="proceed(item.id)" v-bind="attrs" v-on="on">
                    <v-icon color="primary" left size="20">mdi-clock-check</v-icon>
                  </v-btn>
                </template>
                <span>Ver</span>
              </v-tooltip>
            </template>

            <template v-slot:item.salesManager="{item}">
              <div v-if="item.salesManager !== null">
                {{item.salesManager.firstName ? item.salesManager.firstName : ''}} {{item.salesManager.lastName}}
              </div>
            </template>

            <template v-slot:item.vigency="{item}">
              {{item.vigency ? item.vigency : 'NA' }}
            </template>

            <template v-slot:item.price="{item}">
              {{item.price ? item.price : 'NA' }}
            </template>

            <template v-slot:item.sale="{item}">
              {{item.sale ? item.sale : 'NA' }}
            </template>

            <template v-slot:item.urgent="{item}">
              <v-icon :color="item.urgent?'red':''">mdi-alert</v-icon>
            </template>



          </v-data-table>
          <v-pagination
              v-model="options.page"
              :length="MetaData.totalPages"
              @input="GetData()"
              @next="GetData()"
              @previous="GetData()"
          ></v-pagination>
        </v-col>

      </v-row>

    </v-card>
    <v-dialog
        v-model="testdialog"
        class="pa-0"
        width="1200"
        scrollable
    >
      <v-card>
        <v-toolbar
            flat
            dark
            color="primary"
        >
          <v-toolbar-title><v-icon left>mdi-comment-text-outline</v-icon>Cotizacion #3456</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              left
              @click="testdialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import folio_mixin from "@/mixins/folio_mixin";

export default {
  name: "Send",
  mixins: [folio_mixin],
  data(){
    return{
      e1:1,
      options:{
        page: 1,
        itemsPerPage: 25,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
      },
      headers: [
        { text: 'Folio', value: 'refCode' },
        {
          text: 'Recibida',
          value: 'createdAt'
        },
        { text: 'Mercancia lista', value: 'departureDate' },
        {
          text: 'Cliente',
          value: 'client.name'
        },
        {
          text: 'Responsable',
          value: 'salesManager'
        },
        {
          text: 'Servicio',
          value: 'orderType.description'
        },
        {
          text: 'Status',
          value: 'statusOrder'
        },
        {
          text: 'Urgente',
          value: 'urgent'
        },
        {
          text: 'Mejorar',
          value: 'action'
        },

      ],
      Data:[],
      MetaData:{},
      testdialog:false,
      search:"",
      expanded: [],
      singleExpand: false,
    }
  },
  methods:{

    GetData:function (){
      this.$store.dispatch('working',true);

      this.$http.get('Orders/Sent',
          {
            params:{
              PageSize:this.options.itemsPerPage,
              PageNumber:this.options.page,
            }
          }
      ).then(response => {
        if (response.data.code == 200){
          let res = response.data.data
          this.Data = res.map(item => {

            return {
              ...item,
              departureDate: this.$luxon(item.departureDate, {output: "full"}),
              createdAt: this.$luxon(item.createdAt, {output: "full"}),
              folio: this.returnFolio(item),
            }
          })
          this.MetaData = response.data.meta
          console.log(this.Data);
          this.QuantityStatus = this.Data.length
          this.$emit('SaleSendQuantity',this.QuantityStatus);
        }

        if (response.data.code == 400 || response.data.code == 500){
          this.$toast.error("Algo salio mal, intente mas tarde")
        }


      }).catch(error => {
        if(error.response.status == 401){
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
        console.log(error);
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },
    UpdateItemData: function(data){
      this.Data.unshift(data);
    },

    proceed:function (item){
      this.$router.push({path: `ProceedQuoteSales/${item}`})


    },

  },
  mounted() {
    this.GetData();
  }
}
</script>

<style scoped>
>>> .container {
  min-width: 100%;
}
</style>
