export default {
    state: {
        data: {
           status: 'status'
        },
        getters: {
            updateStatus: state => {
                state.coso2 = "jaj sijalo"       
            }
        },
        mutations: {
            newStatus: state => {
                state.coso2 = "mutation"
            }
        }
    },
}