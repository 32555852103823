<template>
  <div>
    <slot name="SupplierAutocomplete">
   <v-row no-gutters>
     <v-col md="3">
          <v-menu
              ref="autocomplete"
              v-model="isMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="400px"
              nudge-bottom="40px"
          >

            <template
                v-slot:activator="{ on: clickEvent }"
            >

              <v-autocomplete

                  v-model="supplierName"
                  label="Seleccione Proveedor"
                  v-on="clickEvent"
                  class="py-0"
                  dense
                  outlined
                  autocomplete="off"

              >

              </v-autocomplete>





            </template>


              <v-expansion-panels v-model="openPanels" accordion>
              <v-expansion-panel
                  v-for="item in suppliers"
                  :key="item.id"
              >

                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <v-col cols="12" class="pr-1">

                      <v-icon left>mdi-office-building</v-icon><span class="caption">{{ item.companyName }}</span>
                    </v-col>

                  </v-row>

                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-if="item.contact.length>0">
                      <v-subheader>
                        Seleccione Contacto
                      </v-subheader>
                      <v-container class="py-0">
                        <v-row
                            align="center"
                            justify="start"
                        >
                          <v-chip-group
                              column
                              multiple
                              active-class="primary&#45;&#45;text"
                          >
                            <v-row no-gutters>
                              <v-col md="12" class="text-right">
                                <div v-for="contact in item.contact" :key="contact">
                                  <v-chip
                                      v-model="chipSupplier"
                                      filter
                                      outlined
                                      @change="pushSupplier(item.id,item.companyName,contact.name,contact.id,contact.lastname,contact.email,contact.contactRoles.descripcion)"
                                  >
                                    <v-icon left>mdi-account</v-icon> {{contact.name}} {{contact.lastname}}
                                  </v-chip>
                                </div>

                              </v-col>
                            </v-row>
                          </v-chip-group>
                        </v-row>
                      </v-container>
                    </div>

                    <div v-else>
                      <v-subheader class="font-weight-regular text&#45;&#45;center">
                        No hay contactos
                      </v-subheader>
                    </div>



                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>


          </v-menu>
        </v-col>
     <br>
      <v-col md="12">
       <div>
         <v-menu
             v-for="(contact) in suppliersData"
             :key="contact"
             transition="scale-transition"
             max-width="400px"
             offset-y
             fixed
             bottom


         >

           <template v-slot:activator="{ on: clickEvent,attrs }"
           >
                 <v-chip
                     class="mx-7"
                     @click:close="Remove(contact.id)"
                     close
                     v-bind="attrs"
                     v-on="clickEvent"
                     close-icon="mdi-close"
                 >
                   <v-icon left>mdi-office-building</v-icon>{{ contact.CompanyName }}
                 </v-chip>


           </template>

           <v-card class="elevation-0">
             <v-toolbar  height="100%" color="grey lighten-2">
               <v-list dense color="grey lighten-2">
                 <v-list-item>
                   <v-list-item-avatar>
                     <v-icon size="35">mdi-account-circle</v-icon>
                   </v-list-item-avatar>
                   <v-list-item-content >
                     <v-list-item-title>{{contact.nameContact}} {{contact.lastnameContact}}</v-list-item-title>
                     <v-list-item-subtitle>{{contact.roleContact}}</v-list-item-subtitle>
                   </v-list-item-content>
                 </v-list-item>
               </v-list>
             </v-toolbar>
           </v-card>


         </v-menu>


       </div></v-col>
    </v-row>

    </slot>

  </div>

<!--  <v-autocomplete
      v-model="friends"
      :items="suppliers"
      outlined
      chips
      dense
      color="blue-grey lighten-2"
      label="Selecciona Proveedores"
      item-text="companyName"
      item-value="id"
      multiple
  >
    <template v-slot:selection="data">
      <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar"></v-img>
        </v-avatar>
        {{ data.item.companyName }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.avatar">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.companyName"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>

  <v-autocomplete
      v-model="friends"
      :items="suppliers"
      outlined
      chips
      dense
      color="blue-grey lighten-2"
      label="Selecciona Proveedores"
      item-text="companyName"
      item-value="id"
      multiple
  >
    <template v-slot:selection="data">
      <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar"></v-img>
        </v-avatar>
        {{ data.item.companyName }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.avatar">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.companyName"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>-->



</template>

<script>
export default {
  name: "SuppliersAucomplete",
  data() {

    return {
      data:[],
      supplierName:"",
      chipSupplier:false,
      cont:null,
      selectedChip:false,
      suppliers:[],
      selected: [],
      search: '',
      suppliersData:[],
      friends: [],
      Data: [{ id:0,name: 'CMA'}, {id:1,name: 'MERSK'}],
      items: [
        {
          text: 'Nature',
          icon: 'mdi-nature',
        },
        {
          text: 'Nightlife',
          icon: 'mdi-glass-wine',
        },
        {
          text: 'November',
          icon: 'mdi-calendar-range',
        },
        {
          text: 'Portland',
          icon: 'mdi-map-marker',
        },
        {
          text: 'Biking',
          icon: 'mdi-bike',
        },
      ],
      isUpdating: false,
      isMenuOpen: false,
      openPanels: null,
      trip: {
        name: '',
        location: null,
        start: null,
        end: null,
      },
      locations: ['Australia', 'Barbados', 'Chile', 'Denmark', 'Ecuador', 'France'],


    }
  },

  watch: {
    isMenuOpen: function () {
      if (!this.isMenuOpen) {
        this.openPanels = null
      }
    },
  },
  methods: {
    Remove:function (id){
      this.suppliersData = this.suppliersData.filter(function (item) {
        return item.id !== id;
      })
      this.chipSupplier = false;
    },
    GetSuppliers: async function () {
      await this.$http.get('Suppliers').then(response => {
        this.suppliers = response.data.data;
        //console.log('Suppliers:',this.suppliers.data.data);
      })
    },

    pushSupplier:function (id,data,idContact,nameContact,lastnameContact,email,role){

        this.cont = this.cont++;


        if (this.chipSupplier==true){
          this.suppliersData = this.suppliersData.filter(function (item) {
            return item.id !== id;
          })
          console.log(this.suppliersData);
        }

      if (this.chipSupplier==false){

        this.suppliersData.push(
            {
              id:id,
              CompanyName:data,
              idContact:nameContact,
              nameContact:idContact,
              lastnameContact:lastnameContact,
              emailContact:email,
              roleContact:role

            }
        )
        this.chipSupplier=true;
      }
      console.log(this.suppliersData);
      this.$emit('supplierInfo',this.suppliersData)

    }
  },
  mounted() {
    this.GetSuppliers();
  }
}
</script>

<style scoped>

.v-card {
  transition: opacity .2s ease-in-out;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}


.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

</style>
