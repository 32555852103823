<template>
  <v-card>
    <v-card-text>
      <br>
      <v-textarea
          outlined
          name="input-7-1"
          label="Comentarios"
          v-model="postBody"
      ></v-textarea>
      <v-row>
        <v-col md="2">
          <v-btn color="primary" @click="SendComments()">
            Enviar Comentarios
          </v-btn>
        </v-col>
      </v-row>


    </v-card-text>

  </v-card>
</template>

<script>
export default {
  name: "PricerComments",
  props:{
    Id:{
      type:String,
      default: "0"
    },
    data(){
      return{
        postBody: ''
      }
    },
    methods:{
      SendComments: function (){
        console.log("QUE SE DICE LOCA");
      }
    }
  },
}
</script>

<style scoped>

</style>
