export default {
    namespaced: true,
    state: {
        tareas: 'hola',
        rutas: [],
        nombre: 'alex'
    },
    mutations: {
        setRutas: (state, payload) => {
            state.rutas = payload
        }
    },
    actions: {
        addRutasAction: ({commit}, payload) => {
            commit("setRutas", payload);
        },
    },
    getters: {
        getRutas: state => {
            return state.rutas;
            // return state.rutas
        },
        mensaje(state) {
            return state.nombre
        }
    },
}
