<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <router-view/>
    </v-fade-transition>
    <VLoading :active="working" :opacity="0.75" style="z-index: 999"></VLoading>
  </v-app>
</template>


<script>
import {mapGetters} from "vuex"

import VLoading from "@/components/Global/loading/loader";
export default {
  name: 'App',
  components: {VLoading},
  computed: {
    ...mapGetters([
      'working'
    ])
  },
  data: () => ({
    //
  }),
  /*beforeCreate() {
    console.log('Connecting ')
  },*/
  methods: {
    sayHi: function (){
      // //allert('Holi')
    }
  },
  mounted(){
  },
  beforeDestroy(){
    this.$hub.Connection.stop();
  }
};
</script>

<style lang="scss">
  @import './styles/classes';
  @import './index.css';

  html, body {
    min-width: 1200px;
  }

  @media screen and (max-width: 1200px) {
    html, body {
      overflow-x: scroll;
    }

  }

</style>
