<template>
    <div>
        <!-- Test component header -->
        <v-card class="mx-auto text-center" max-width="600">
            <v-card-text>
                <p class="text-h4 text--primary">TEST COMPONENT</p>
                <p id="dataParagraph">Data: {{dataReceived}}</p>
                <p ref="propParagraph">Prop: {{propReceived}}</p>
                <v-container>
                    <v-row>
                        <v-col md="4"><p>Visible component</p> <v-icon class="visibleIcon" small color="green" v-show="true">mdi-eye</v-icon></v-col>
                        <v-col md="4"><p>Hidden component</p> ( <v-icon class="hiddenIcon" small color="green" v-show="false">mdi-eye</v-icon>)</v-col>
                        <v-col md="4"><p>Unexisted component</p> </v-col>
                    </v-row>
                </v-container>
                <div class="text--primary"><p v-show="handdleEmitShow">Functional test component</p>
                    <v-btn id="emitButton" @click="$emit('buttonEvent', 'This is an event')" v-on:click="handdleEmitShow = !handdleEmitShow">with</v-btn>
                    <v-btn id="methodButton" @click="buttonMethod" v-on:click="handdleEmitShow = !handdleEmitShow">more components</v-btn>

                </div>
            </v-card-text>
            <v-divider></v-divider>
            <!-- Test component body -->
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col md="4">
                            <p>Working with data</p>
                                <v-text-field v-model="dataName" label="Name"></v-text-field>
                                <v-combobox :items="dataList" v-model="dataSelected" label="Options"></v-combobox>
                                <v-checkbox v-model="dataSelect" :label="`${dataSelect}`"></v-checkbox>
                                <v-btn id="getButton" color="green" class="mb-3" dark @click="getData">Pick</v-btn>
                        </v-col>
                            <v-divider></v-divider>
                        <v-col md="4">
                        <p>Working with props</p>
                            <v-text-field v-model="propName" label="Name"></v-text-field>
                            <v-combobox :items="propList" v-model="propSelected" label="Options"></v-combobox>
                            <v-checkbox v-model="propSelect" :label="`${propSelect}`"></v-checkbox>
                            <v-btn id="sendButton" color="green" class="mb-3" dark @click="sendData">Send</v-btn>
                        </v-col>
                            <v-divider></v-divider>
                        <v-col md="4" class="text-center">
                        <p>Working with methods</p>
                            <div class="my-3" ref="bucles" v-for="item in dataArray" :key="item">{{item}}</div>
                            <br/>
                            <v-btn dark id="buclesButton" color="red" class="my-3" @click="removeElements">Remove</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <!-- Test component footer -->
            <p>Working with dialogs</p>
            <v-card-actions>
                <v-container>
                    <v-row>
                        <v-btn color="blue accent-4" class="mx-3" width="100" dark @click="$emit('event', true)">Trigger</v-btn>
                        <v-btn color="blue lighten-2" class="mx-3" width="100" dark v-on:click="dialog = true">Show</v-btn>
                    </v-row>
                </v-container>
                <v-btn icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-expand-transition>
            <div v-show="show">
                <v-divider></v-divider>
                <v-card-text>
                I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                </v-card-text>
            </div>
            </v-expand-transition>

            <v-dialog v-model="dialog" width="600">
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Privacy Policy
                    </v-card-title>
                    <v-card-text>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">I accept
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataReceived: 'Default data',

            handdleEmitShow: true,

            show: false,
            dialog: false,

            dataName: 'Nombre data',
            dataList: ['Data 1', 'Data 2',  'Data 3'],
            dataSelected: [],
            dataSelect: false,

            dataArray: ['Bucle 1', 'Bucle 2',  'Bucle 3', 'Bucle 4', 'Bucle 5'],

            dataSent: {
                name: null,
                selected: null,
                select: null,
            }
        }
    },
    props: {
        propReceived: {type: String, default: 'Default prop'},

        propName: {type: String, default: 'Nombre prop'},
        propList: {type: Array, default: () => ['Prop 1', 'Prop 2', 'Prop 3']},
        propSelected: {type: Array, default: () => []},
        propSelect: {type: Boolean, default: true},
    },
    methods: {
        buttonMethod: function () {
            this.$emit('buttonMethod', {id: 1, text: 'This is an object', value: true})
        },
        removeElements: function () {
            this.dataArray.length !== 0
            ? this.dataArray = this.dataArray.filter(element => !element.includes(this.dataArray.length))
            : this.dataArray = ['Bucle 1', 'Bucle 2',  'Bucle 3', 'Bucle 4', 'Bucle 5']
            this.$emit('emitRemove', this.dataArray.length)
        },
        getData: function () {
            this.dataName = 'Received name'
            this.dataSelected = ['Data 2']
            this.dataSelect = true
        },
        sendData: function () {
            this.dataSent = {
                name: this.propName,
                selected: this.propSelected,
                select: this.propSelect,
            }

            console.log(this.dataSent)
            this.$emit('dataSent', this.dataSent)
        }
    }
}
</script>

<style scoped>

</style>