<template>
  <div>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon> Registrar Embarque </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark left @click="$emit('closeDialog',false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
              <v-stepper
                  v-model="e6"
                  vertical
                  class="elevation-0"
              >
                <v-stepper-step
                    :complete="e6 > 1"
                    step="1"
                    @click="e6=1"
                >
                 Datos del cliente

                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-container>
                    <v-row >
                      <v-col md="4">
                        <v-text-field label="Provedor de cliente" dense
                          v-model="shipment.ClientSupplierName"   item-value="id" item-text="name" >
                        </v-text-field>
                      </v-col>

                      <v-col md="4">
                        <v-text-field label="Contacto" dense v-model="shipment.ContactName"  item-value="id" item-text="name" >
                        </v-text-field>
                      </v-col>

                      <v-col md="4">
                        <v-text-field v-model="shipment.ReferenceNumber"  label="Orden de cliente" dense>
                        </v-text-field>
                      </v-col>


                    </v-row>
                      <v-row>
                        <v-col md="12">
                          <v-combobox multiple
                                      dense
                                      small-chips
                                      :items="[]"
                                      label="Correos de provedor de cliente"
                                      hide-selected
                                      :rules="emailValid"
                                      :search-input:sync="searchEmails"
                                      v-model="shipment.emails">
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Escribe un correo y presiona <kbd>enter</kbd> para agregarlo.
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-combobox>
                        </v-col>

                      </v-row>
                  </v-container>
                </v-stepper-content>

                <v-stepper-step
                    :complete="e6 > 2"
                    step="2"
                    @click="e6=2"
                >
                  Operación
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-container>
                    <v-row>
                      <v-col md="4">
                        <v-autocomplete label="Operador" dense :items="operators"
                           v-model="shipment.OperatorId"  item-value="userId" :item-text="item => item.firstName +' '+ item.lastName" :rules="ClientRules">
                        </v-autocomplete>
                      </v-col>
                      <v-col md="4">
                        <v-autocomplete label="Tipo de cambio" dense :items="TypeChange"
                          v-model="shipment.ChangeTypeId" item-value="id" item-text="name">
                        </v-autocomplete>
                      </v-col>

                      <v-col md="10">
                        <v-textarea
                            outlined
                            v-model="shipment.Comments"
                            name="input-7-1"
                            label="Comentarios"
                        ></v-textarea>
                      </v-col>


                    </v-row>
                  </v-container>

                </v-stepper-content>

                <v-stepper-step
                    :complete="e6 > 3"
                    step="3"
                    @click="e6=3"
                >
                  Documentos
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-container>
                    <v-row>

                      <v-col md="10">


                        <file-drag v-model="shipment.DocumentDrag" @change="ConvertFile()">

                        </file-drag>


                        <!--                        <v-row v-for="(document,index) in shipment.Document" :key="document">
                                                  <v-col md="10">
                                                    <v-file-input  v-model="document.file" clear-icon="" @change="ConvertFile(document.file,index)" label="Documentos de Embarque" dense :rules="ClientRules">
                                                    </v-file-input>
                                                  </v-col>
                                                  <v-col md="2">
                                                    <v-icon color="primary" @click="addDocuments()">mdi-plus-circle</v-icon>
                                                  </v-col>
                                                </v-row>-->
                      </v-col>
                    </v-row>

                    <br><br>

                    <v-btn color="primary" @click="emitData">Aceptar</v-btn>

                  </v-container>
                </v-stepper-content>

              </v-stepper>




        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import FileDrag from "../Global/FileDrag/FileDrag";

export default {
  name: "registerShipmentDialog",
  components: {
    FileDrag
  },
  data(){
    return{
      searchEmails: null,
      emailValid: [
        v => {
          if (!v || v.length < 1) return "Debes agregar al menos un correo";
          else if (!v || v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (!/.+@.+\..+/.test(v[i])) {
                return "Un correo no es valido.";
              }
            }
          }
          return true;
        }
      ],
      companies:[],
      ContactCompany:[],
      Operators:[],
      TypeChange:[
        {
          id:1,
          name:'Ruta',
        },
        {
          id:2,
          name:'Precio'
        },
        {
          id:3,
          name:'Puertos'
        },
        {
          id:4,
          name:'Origen'
        },
        {
          id:5,
          name:'Destino'
        },
        {
          id:6,
          name:'Proveedor'
        },
        {
          id:7,
          name:'Cliente'
        }
      ],
      shipment:{
        ClientSupplierName:null,
        ContactName:null,
        ReferenceNumber:null,
        OperatorId:null,
        QuotationId:null,
        Comments:null,
        ChangeTypeId:null,
        Mails:null,
        DocumentDrag:[],
        Documents:[],
      },
      ClientRules: [
        v => !!v || 'Cliente es requerido',
      ],
      documents:[
        {
          file:null,
          fileName:null,
          fileExtension:null
        }
      ],
      emailnotification:[
        {
          email:null,
        }
      ],
      e6:1,
      dialog:true,
    }
  },
  methods:{

    emitData(){
      this.$emit('sellOrder',true);
      this.$emit('sellDataOrder',this.shipment);
    },

    getCustomers: function () {
      this.$store.dispatch('working', true)
      this.$http.get('Companies')
          .then(response => {
            console.log(response)
            this.showAddCompany = false
            this.companies = response.data.data;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
          }).finally(() => this.$store.dispatch('working', false))
    },
    getOperators: function () {
      this.$store.dispatch('working', true)
      this.$http.get('Employees/operators')
          .then(response => {
            console.log(response)
            this.operators = response.data.data;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
          }).finally(() => this.$store.dispatch('working', false))
    },
    addDocuments(){
      this.shipment.Document.push({
        file:null,
        fileName:null,
        fileExtension:null
      });
    },

    UploadFile: function (file) {
      return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error);
      });
    },

    ConvertFile: async function () {

      let documents = this.shipment.DocumentDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(',')[1];
        const dotPosition = doc.name.lastIndexOf('.');
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName = dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.shipment.Documents.push(
            {
              documentTypeId: 103,
              file: data,
              fileName: fileName,
              fileExtension: fileExt
            }
        )
      }

    },

    addEmail(email){
      this.shipment.Mails.push(email);
    },


  },
  mounted() {
    this.getCustomers();
    this.getOperators();
  }
}
</script>

<style scoped>

</style>
