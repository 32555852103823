<template>
  <div>

    <div class="row no-gutters position-relative">
      <div class="col-6 fixed-col-size">
        <googleMapsAutocompleteVuetify
            label="Origen"
            :rules="rules.required"
            :search="originAddress"
            :items="originItems"
            :selections="origin"
            @change="originSelected"
            @predictions="predictions = $event"
            @updateAddress="updateOriginAddress"
            @updateItems="updateOriginItems"
            @menuChanged="isOriginOpen = $event"
            :isOpen="isOriginOpen"
            :id="1"
            transportation="SEA"
            placeholder="Ingrese un origen"
        >
        </googleMapsAutocompleteVuetify>
      </div>





    </div>




  </div>
</template>

<script>
import googleMapsAutocompleteVuetify from "@/components/google-maps-autocomplete-vuetify/index";

export default {
  name: "Test",
  components:{
    googleMapsAutocompleteVuetify
  },
  data:() => ({
    origin: {},
    rules: {
      required: [value => !!value || ""]
    },
    predictions:null,
    originAddress: "",
    originSelections : {},
    destinationSelections:{},
    originItems : [],
    destinationItems : [],
    isOriginOpen: false,
    QuotData:{
      origin: {},
      destination: {},
      searchVariables: {},
      DestinationPort:{},
      OriginPort:{},
      AutocompleteOrigin:false,
      AutocompleteDestination:false,
    }
  }),
  methods:{
    getAirPorts(data){
      console.log(data);
      this.$httpPlacesApi.get(`/api/ports/airport/country/MX/location/22.4077,22.4077`).then(response=>{
        console.log(response);
      }).catch(error => {
        console.log(error)
      })
    },

    originSelected(selections) {
      try {
        console.log(selections)
        this.origin = selections
        this.$nextTick(() => {
          try {
            if(!Array.isArray(this.origin)) {
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
              this.QuotData.origin["country"] =  this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["ports"] = this.origin.ports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.ports.findIndex(e => e.selected);
              if(index !== -1) {
                this.QuotData.origin["ports"].name = this.origin.ports[index].name;
                this.QuotData.origin["ports"].city = this.origin.ports[index].city;
                this.QuotData.origin["ports"].coordinates = `${ this.origin.ports[index].lat},${ this.origin.ports[index].lon[1]}`;
                this.QuotData.origin["ports"].country = this.origin.ports[index].country;
                this.QuotData.OriginPort = this.origin.ports[index];
              }
              console.log("Graph: ",this.QuotData.OriginPort)
            }
          }
          catch(e){
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch(error){
        console.log(error)
      }
    },
    updateDestinationAddress(text){
      this.destinationAddress = text
    },

    updateOriginAddress(text){
      this.originAddress = text
    },

    updateOriginItems(items){
      console.log(items)
      this.originItems = items;
    },

    updateDestinationItems(items){
      this.destinationItems = items;
    },
  },
}
</script>

<style scoped>

.position-relative {
  position: relative;
}

</style>
