var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.Data,"search":_vm.search,"hide-default-footer":true,"items-per-page":25,"pagination":_vm.options,"item-key":"name"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(){return undefined},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){return _vm.proceed(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","left":"","size":"20"}},[_vm._v("mdi-clock-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])]}},{key:"item.salesManager",fn:function(ref){
var item = ref.item;
return [(item.salesManager !== null)?_c('div',[_vm._v(" "+_vm._s(item.salesManager.firstName ? item.salesManager.firstName : '')+" "+_vm._s(item.salesManager.lastName)+" ")]):_vm._e()]}},{key:"item.vigency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vigency ? item.vigency : 'NA')+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price ? item.price : 'NA')+" ")]}},{key:"item.sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sale ? item.sale : 'NA')+" ")]}},{key:"item.urgent",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.urgent?'red':''}},[_vm._v("mdi-alert")])]}}])}),_c('v-pagination',{attrs:{"length":_vm.MetaData.totalPages},on:{"input":function($event){return _vm.GetData()},"next":function($event){return _vm.GetData()},"previous":function($event){return _vm.GetData()}},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1)],1),_c('v-dialog',{staticClass:"pa-0",attrs:{"width":"1200","scrollable":""},model:{value:(_vm.testdialog),callback:function ($$v) {_vm.testdialog=$$v},expression:"testdialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-comment-text-outline")]),_vm._v("Cotizacion #3456")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":"","left":""},on:{"click":function($event){_vm.testdialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }