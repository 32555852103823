<template>
  <section class="w-100" @click="$emit('select', { placeId: item['place_id'] })">
    <div class="header-expansion py-2 d-flex" >
      <div class="mx-1 my-auto">
        <v-icon
            medium
            class="mr-1"
        >
          {{item.type === 'port' ? 'mdi-ferry' : 'mdi-city'}}
        </v-icon>
      </div>
      <div
        class="mx-1 my-auto text-truncate w-75"
        @click="$emit('select', { placeId: item['place_id'] })"
      >
        <span>{{item.description}}</span>
      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: 'AutocompleteDetails',
  props: {
    item: {
      type: Object,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return{
      model: {}
    }
  },

  methods: {
    resetNotSelectedPorts(indexes){
      if (this.multiple) {
        for(let i = 0; i < indexes.length; i++) {
          this.item.ports[indexes[i]].selected = false;
        }
      }
      else{
        this.item.ports[indexes[0]].selected = false;
      }
      this.model= -1;
    },
    getSelectedPortsIndexes(){
      return this.item.ports.reduce((accumulator, currentValue, index) => {
        currentValue.selected && accumulator.push(index);
        return accumulator;
      }, []);
    },
  }

}
</script>

<style scoped>
.header-expansion{
  cursor: pointer;
}
.w-75{
  width: 75%;

}
</style>
