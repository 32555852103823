var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-input',{staticClass:"mx-2",class:[_vm.isMenuOpen ? 'inputSelected border-none' : '']},[_c('v-menu',{key:_vm.id,attrs:{"offset-y":"","max-width":"500","min-width":"500","nudge-top":"25","offset-overflow":"","eager":true,"disable-keys":"","close-on-content-click":false,"max-height":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:'autocomplete_' + _vm.id,attrs:{"outlined":"","dense":_vm.dense,"prepend-inner-icon":_vm.inputIcon,"autocomplete":"new-password","placeholder":_vm.placeholder,"label":Array.isArray(_vm.lazySelections) && _vm.lazySelections.length == 0
              ? _vm.label
              : '',"loading":_vm.isLoading},on:{"input":_vm.inputChanged},model:{value:(_vm.internalSearch),callback:function ($$v) {_vm.internalSearch=$$v},expression:"internalSearch"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isMenuOpen),callback:function ($$v) {_vm.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c('v-list',{key:_vm.listKey,attrs:{"subheader":""}},[(_vm.lazyItems.length === 0)?[_c('v-subheader',[_vm._v(" No se encontraron registros. ")])]:[_c('v-list',{attrs:{"no-action":""}},[_vm._l((_vm.lazyItems),function(item){return [(item.airports && _vm.filterRoute==2 ? item.airports.length > 1 : item.airports.length > 0)?_c('v-list-group',{key:item.place_id,staticClass:"s-list-group-active",attrs:{"eager":true,"value":item.selected,"prepend-icon":item.type === 'port' ? 'mdi-airport' : 'mdi-city',"group":item.place_id,"no-action":""},on:{"click":function($event){return _vm.selectItem({ placeId: item['place_id'] })}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(" "+_vm._s(item.description)+" ")])]},proxy:true}],null,true)},[[_c('v-text-field',{staticClass:"pt-5 pl-2 pr-2 col-md-12",attrs:{"solo":"","label":"Buscar Aeropuerto","dense":"","outlined":""},on:{"input":function($event){return _vm.searchAutocomplete(item.place_id)}},model:{value:(_vm.searchAirPort),callback:function ($$v) {_vm.searchAirPort=$$v},expression:"searchAirPort"}})],_vm._l((item.airports),function(port){return [_c('v-list-item',{key:port._id,staticClass:"pl-12",attrs:{"input-value":port.selected},on:{"click":function($event){return _vm.selectPort({
                        placeId: item['place_id'],
                        portId: port._id,
                      })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-airport")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(port.name)+" "+_vm._s(port.city)+" "+_vm._s(port.country)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(Number.parseFloat(port.distance).toFixed(2))+" KM de distancia ")])],1)],1)]}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.loadMore),expression:"loadMore"}]})],2):_c('v-list-item',{key:item.place_id,attrs:{"input-value":item.selected,"active-class":"s-list--active"},on:{"click":function($event){return _vm.selectItem({
                    placeId: item['place_id'],
                    portId:
                      item.type === 'airport' ? item.airports[0]._id : null,
                  })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(item.type === "airport" ? "mdi-airport" : "mdi-city")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.type === "airport" ? ((item.airports[0].name) + ", " + (item.airports[0].city) + ", " + (item.airports[0].country)) : item.description)+" ")]),(item.type === 'airport')?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(Number.parseFloat(item.airports[0].distance).toFixed(2))+" KM de distancia ")]):_vm._e()],1)],1)]})],2)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }