<template>
  <GmapMap
    ref="mapRef"
    :center="mapCenter"
    map-type-id="terrain"
    id="map"
    :zoom="2"
    :options="options"
  >
    <GmapMarker
        :key="'path' + value.location.lat + value.location.lng"
        ref="markerRef"
        v-for="(value) in path"
        :position="value.location"
        :visible="true"
        :icon="value.type == 'port' ? svgSelectedPortMarker : svgPlaceMarker"
        :draggable="false"
    >

    </GmapMarker>

    <GmapPolyline
        :key="'polyline' + value.lat"
        v-for="(value) in pathPolyLine"
        :path="pathPolyLine"
    ></GmapPolyline>

    <GMapCluster
        :maxZoom="2"
        :zoomOnClick="true"
        :styles="clusterStyles"
    >
      <GmapMarker
        :key="'marker' + marker.location.lat + marker.location.lng"
        ref="markerRef2"
        v-for="(marker) in markers"
        :position="marker.location"
        :visible="true"
        :icon="marker.type == 'port' ? svgUnselectedPortMarker : ''"
        :draggable="false"
      >

      </GmapMarker>
    </GMapCluster>

  </GmapMap>
</template>

<script>
export default {
  name: "Map",
  props: {
    markers: {
      type: Array,
      default: () => []
    },
    path: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return {
      baseUrl: '',
      markerScale: 12,
      svgPlaceMarker: {
        url: `${process.env.VUE_APP_BASE_URL}/icons/pin.svg`,
        width: 100,
        fillColor: "red",
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        options: {
          scaleControl: true
        }

      },
      svgSelectedPortMarker: {
        url: `${process.env.VUE_APP_BASE_URL}/icons/port.svg`,
        fillColor: "blue",
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: this.markerScale,
        options: {
          scaleControl: true
        }
      },
      svgUnselectedPortMarker: {
        url: `${process.env.VUE_APP_BASE_URL}/icons/port_available.svg`,
        fillColor: "gray",
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: this.markerScale,
        options: {
          scaleControl: true
        }
      },
      options: {
        streetViewControl: false,
        mapTypeControl: false,
        maxZoom: 6,
        minZoom: 1,
      },
      pathPolyLine: [],
      bounds: null,
      mapCenter: {lat: 0, lng: 0},
      clusterStyles: [{
          url: process.env.VUE_APP_BASE_URL + '/icons/clusterImage/m1.png',
          width: 35,
          height: 35,
          textColor: '#ff00ff',
          textSize: 10,
        },
        {
          url: process.env.VUE_APP_BASE_URL + '/icons/clusterImage/m1.png',
          width: 45,
          height: 45,
          textColor: '#ff00ff',
          textSize: 10,
        },
        {
          url: process.env.VUE_APP_BASE_URL +'/icons/clusterImage/m1.png',
          width: 55,
          height: 55,
          textColor: '#ff00ff',
          textSize: 10,
        }
      ]
    }
  },
  watch: {
    markers: function(){
      console.log(this.markers)
      this.fitBounds()
    },
    path:function(){
      this.fitBounds()
      this.pathPolyLine = this.path.map(polyline => polyline.location);
    }
  },
  mounted(){
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    this.$refs.mapRef.$mapPromise.then((map)=> {
      console.log(map)
      this.bounds = new window.google.maps.LatLngBounds();
      this.markerScale = new window.google.maps.Size(3, 3)
    })
    // this.initMap();
    // this.addMarker();
  },
  methods: {
    fitBounds: function(){
      this.markers.forEach(marker => {
        this.bounds.extend(marker.location)
        this.$refs.mapRef.fitBounds(this.bounds)
      })
      this.path.forEach(p => {
        this.bounds.extend(p.location)
        this.$refs.mapRef.fitBounds(this.bounds)
      })
    },
    // initMap() {
    //  this.$gmapApiPromiseLazy().then(() => {
    //    this.map = new window.google.maps.Map(document.getElementById("map"),{
    //      center: this.mapCenter,
    //      zoom: 2,
    //      maxZoom: 5,
    //      minZoom: 1,
    //      streetViewControl: false,
    //      mapTypeControl: false,
    //      zoomControl: true,
    //    })
    //

    //    this.addMarker();
    //  })
    // },
    // addMarker: function () {
    //   this.markers.forEach(marker => {
    //     this.bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng))
    //     this.map.fitBounds(this.bounds);
    //     new window.google.maps.Marker({
    //       position: marker,
    //       map: this.map
    //     })
    //   })
    //
    //   const line = new window.google.maps.Polyline({
    //     path: this.markers,
    //     strokeColor: '#024d98',
    //     geodesic: true,
    //     strokeOpacity: 1.0,
    //     strokeWeight: 2,
    //   })
    //
    //   line.setMap(this.map)

    // },
  }
}
</script>

<style scoped>
  #map {
    height: 100%;
  }

</style>
