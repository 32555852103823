<template>
  <div class="fill-height">
    <v-bottom-sheet inset v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue darken-2" dark right bottom fixed v-bind="attrs" v-on="on" fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon> Agregar Empleado </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark left @click="sheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Employees></Employees>
      </v-card>
    </v-bottom-sheet>
    <v-card elevation="0" tile width="2000" class="pt-10">
      <v-row justify="center">
        <v-col md="12">
          <v-container style="margin-left: 0px; margin-right: 20px" tile elevation="0">
            <v-row justify="center">
              <v-col md="7">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    solo
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :headers="HeaderEmployees"
                        :items-per-page="50"
                        :items="Employees"
                        :hide-default-footer="true"
                        item-key="id"
                        :search="search"
                    >
                    <!-- Data validation -->
                       <template v-slot:item.firstName = {item}>
                        {{item.firstName ? item.firstName : 'NA'}}
                      </template>
                       <template v-slot:item.lastName = {item}>
                        {{item.lastName ? item.lastName : 'NA'}}
                      </template>
                       <template v-slot:item.email = {item}>
                        {{item.email ? item.email : 'NA'}}
                      </template>
                       <template v-slot:item.phoneNumber = {item}>
                        {{item.phone ? item.phone : 'NA'}}
                      </template>
                       <template v-slot:item.extensionOffice = {item}>
                        {{item.extensionOffice ? item.extensionOffice : 'NA'}}
                      </template>
                       <template v-slot:item.roleId = {item}>
                        {{item.roleId ? item.roleId : 'NA'}}
                      </template>
                      <!-- Data validation -->
                      <template v-slot:item.edit = {item}>
                        <v-btn text @click="GetDataEmployee(item.id)">
                          <v-icon color="primary">mdi-table-edit</v-icon>
                        </v-btn>
                      </template>

                      <template v-slot:item.status = {item}>
                       <v-icon :color="item.isAvailable ? 'green' : 'red' ">mdi-checkbox-blank-circle</v-icon>
                      </template>

                      <template v-slot:item.changeStatus = {item}>

                        <v-tooltip top icon v-if="!item.isAvailable">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="changeStatus(item.id,true)">
                              <v-icon>mdi-account</v-icon>
                            </v-btn>
                          </template>
                          <span>Habilitar</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.isAvailable">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="changeStatus(item.id,false)">
                              <v-icon>mdi-account-off</v-icon>
                            </v-btn>
                          </template>
                          <span>Inhabilitar</span>
                        </v-tooltip>


                      </template>
                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-col>
      </v-row>
      <v-dialog
          v-model="dialog"
          width="1000"
      >
        <div>
          <v-stepper v-model="e6" vertical>
            <v-stepper-step :complete="e6 > 1" step="1" @click="e6 = 1">
              Area
              <small>Area sera donde estará ubicado en personal</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-row justify="center">

                <v-col md="2">

                  <v-btn @click="e6 = 2,DataEmployee.roleId=1" outlined x-large fab color="primary">
                    <v-icon>las la-user</v-icon>
                  </v-btn>
                  <br>
                  <span class="font-weight-regular black--text offset-1">Ventas</span>
                </v-col>

                <v-col md="2">
                  <v-btn @click="e6 = 2,DataEmployee.roleId=2" outlined x-large fab color="primary">

                    <v-icon>las la-hands-helping</v-icon>

                  </v-btn>
                  <br>
                  <span class="font-weight-regular black--text offset-1">Pricing</span>

                </v-col>

                <v-col md="2">
                  <v-btn @click="e6 = 2,DataEmployee.roleId=3" outlined x-large fab color="primary">
                    <v-icon>las la-list</v-icon>
                  </v-btn>
                  <br>
                  <span class="font-weight-regular black--text">Operacion</span>
                </v-col>

                <v-col md="2">
                  <v-btn @click="e6 = 2,DataEmployee.roleId=4" outlined x-large fab color="primary">
                    <v-icon>las la-hand-holding-usd</v-icon>
                  </v-btn>
                  <br>
                  <span class="font-weight-regular black--text offset-1">Admin</span>

                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2" @click="e6 = 2">
              Datos personales
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.firstName" :rules="nameRules" label="Nombre(s)" required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.lastName" :rules="lastNameRules" label="Apellido" required>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.personalEmail" :rules="emailRules" label="Correo Electronico Personal"
                                    required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.phoneNumber" :rules="cellphoneRules" label="Celular" required>
                      </v-text-field>
                    </v-col>

                  </v-row>
                </v-container>
              </v-form>

              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.homePhone" :rules="HomePhoneRules" label="Tel de Domicilio" required>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.taxNumber" :rules="RfcRules" label="RFC" required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.socialSecurity" :rules="SegurosocialRules"
                                    label="Numero de seguro social" required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.universalId" :rules="CurpRules" label="Curp"></v-text-field>
                    </v-col>

                  </v-row>
                </v-container>
              </v-form>

              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-autocomplete return-object v-model="DataEmployee.countryId" label="Pais" :rules="CountryRules" required
                                      :items="Country" item-value="id" item-text="name"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-autocomplete return-object v-model="NA" label="Estado" :rules="CountryRules" required
                                      :items="States" item-value="id" item-text="name"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-autocomplete v-model="DataEmployee.cityId" label="Ciudad" :rules="CityRules" :items="Cities"
                                      item-value="id" item-text="name" required></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-autocomplete v-model="DataEmployee.languageId" label="Lenguaje" :items="Languages" :rules="LanguajeRules"
                                      item-value="id" item-text="description" required></v-autocomplete>
                    </v-col>



                  </v-row>
                </v-container>
              </v-form>


<!--              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-file-input v-model="DataEmployee.Documentation[0].file" :rules="INERules"
                                    @change="ConvertFile(employee.Documentation[0].file,0)" label="Copia de IFE"></v-file-input>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-file-input v-model="DataEmployee.Documentation[1].file" :rules="ComprobanteRules"
                                    @change="ConvertFile(employee.Documentation[1].file,1)" label="Copia de comporbante de domicilio">
                      </v-file-input>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-file-input v-model="DataEmployee.Documentation[2].file" :rules="CurriculumRules"
                                    @change="ConvertFile(employee.Documentation[2].file,2)" label="Curriculum"></v-file-input>
                    </v-col>

                  </v-row>
                </v-container>
              </v-form>-->




              <v-btn color="primary" @click="e6 = 3">
                Continuar
              </v-btn>
              <v-btn @click="e6 = 1">
                Atras
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 3" step="3" @click="e6 = 3">
              Informacion Empresarial
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.monthlySalary" :rules="SueldoMensualRules" :counter="10" type="number"
                                    label="Sueldo Mensual" required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.extensionOffice" :rules="ExtencionRules" :counter="10"
                                    label="Extencion de oficina tel" required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.email" :rules="emailcompanyRules" label="Correo empresarial" required>
                      </v-text-field>
                    </v-col>

<!--                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.Accounts[0].accountNumber" :rules="CuentaRules"
                                    label="Tarjeta de deposito" required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.Accounts[0].bank" :rules="BankRules" label="Banco" required>
                      </v-text-field>
                    </v-col>-->
                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="primary" @click="e6 = 4">
                Continuar
              </v-btn>
              <v-btn @click="e6 = 2">
                Atras
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 4" step="4" @click="e6 = 4">
              Cuenta
            </v-stepper-step>

            <v-stepper-content step="4">
              <v-form>
                <v-container>
                  <v-row>
<!--                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.configuration.email" :rules="UserEmailRules" label="Cuenta de Email"
                                    required></v-text-field>
                    </v-col>-->

<!--                    <v-col cols="12" md="3">
                      <v-text-field v-model="DataEmployee.configuration.pwd" label="Password">
                      </v-text-field>
                    </v-col>-->

                  </v-row>


                  <v-row>

                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="primary" @click="e6 = 1,PutEmploye()">
                Actualizar
              </v-btn>
              <v-btn @click="e6 = 3">
                Atras
              </v-btn>
            </v-stepper-content>


          </v-stepper>
        </div>
      </v-dialog>

    </v-card>
  </div>
</template>
<script>

import Employees from "../../Accounts_Admin/Employees";
export default {
name: "List-Employees",
  components:{Employees},
  data(){
    return{
      userInfo:[],
      e6: 1,
      search:'',
      EmployeesStatus:null,
      DataEmployee:[],
      id:null,
      dialog:false,
      sheet: false,
      HeaderEmployees:[
        {
          text: 'Nombre',
          value: 'firstName',
        },
        { text: 'Apellido', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Telefono', value: 'phoneNumber' },
        { text: 'Extension', value: 'extensionOffice' },
        { text: 'Puesto', value: 'roleId' },
        {text: 'Editar',value: 'edit'},
        {text: 'Estado',value: 'status'},
        {text: 'Cambiar Status',value: 'changeStatus'},
      ],
      Employees:[],
    }
  },

  methods:{
    getEmployees:function (){
      this.$store.dispatch('working',true);
      this.$http.get('Employees').then(response => {
        console.log(response.data.data);
        this.Employees = response.data.data;
        this.EmployeesStatus = this.Employees.length
        this.$emit('EmployeesQuantity',this.EmployeesStatus);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working',false);
      })
    },

    changeStatus(id,status){
      this.$http.get(`Employees/${id}`).then(response => {
        this.DataEmployee = response.data.data;
        this.updateStatus(this.DataEmployee,status);
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });

    },
    updateStatus(employee,status){
      let dataUpdate = JSON.parse(JSON.stringify(employee));
      dataUpdate.isAvailable = status;
       this.$http.put(`Employees/${dataUpdate.id}`,dataUpdate).then(response=>{
         console.log(response.data.data)
        this.$toast.info('Estatus cambiado')
         this.getEmployees();
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{

      })
    },

    GetDataEmployee:function (id) {
      this.$store.dispatch('working',true)

      this.$http.get(`Employees/${id}`).then(response => {
        console.log(response.data.data)
        this.DataEmployee = response.data.data;
        this.dialog = true;
        console.log(this.DataEmployee)
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },

    getUsers(){
      this.$store.dispatch('working',true)

      this.$http.get(`User`).then(response => {
        console.log(response.data.data)
        this.userInfo = response.data.data;
        console.log(this.userInfo)
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },
    PutEmploye(){
      console.log(this.DataEmployee)

      let doc = {
        id: this.DataEmployee.id,
        firstName: this.DataEmployee.name,
        lastName: this.DataEmployee.lastName,
        personalEmail: this.DataEmployee.personalEmail,
        phoneNumber:this.DataEmployee.phoneNumber,
        homePhone:this.DataEmployee.homePhone,
        taxNumber:this.DataEmployee.taxNumber,
        universalId:this.DataEmployee.universalId,
        countryId:this.DataEmployee.countryId,
        cityId:this.DataEmployee.cityId,
        languageId:this.DataEmployee.languageId,
        monthlySalary:this.DataEmployee.monthlySalary,
        extensionOffice:this.DataEmployee.extensionOffice,
        email:this.DataEmployee.email,


      };
      this.$http.put(`Employees/${this.DataEmployee.id}`, doc)
          .then((response) => {
            if (response.data.code === 200) {
              this.$toast.info("Confirmado con exito!");
            } else {
              this.$toast.error("Ocurrio un error");
            }
            console.log("No paso")
          }).catch((error) => {
        console.log(error)
      }).finally(() => {
      })

    },

  },
  mounted() {
    this.getEmployees();
  }
}
</script>

<style scoped>

>>> .container {
  min-width: 100%;
}

</style>
