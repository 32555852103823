<template>
  <div>
    <v-card class="border-rounded mx-auto" max-width="1000">
      <v-expansion-panels  v-model="panel" multiple>
        <v-expansion-panel
            v-model="panel"
        >
          <v-expansion-panel-header expand-icon="" class="headline primary font-weight-regular white--text"><slot name="title"></slot></v-expansion-panel-header>
          <v-expansion-panel-content class="pb-0">
            <v-container class="pb-0">
                <slot class="pb-0" name="text-card"></slot>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>



  </div>


</template>

<script>
export default {
name: "Card_Base",
  data:()=>{
  return{
    panel:[0]
  }
}
}
</script>

<style scoped>

</style>
