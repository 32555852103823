import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VuetifyToast from './components/Global/Toast/index.js'
import VueSweetalert2 from 'vue-sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'
import GMapCluster from 'vue2-google-maps/src/components/cluster';
import vuetify from './plugins/vuetify'
import {httpPlacesApi,httpAdminApi, httpApi, httpGraphAPi, httpSignal} from './config/axios';
import Hub from './hubs/hub';
import store from './store'
import {createProvider} from './vue-apollo'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import moment from 'moment'
import VueLuxon from "vue-luxon";
import './registerServiceWorker'
import VueTimepicker from 'vue2-timepicker'
import Chat from 'vue-beautiful-chat'
import HubChat from "@/hubs/hubChat";
import VueApexCharts from 'vue-apexcharts'
import 'regenerator-runtime/runtime'
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')



// import {connectToHub} from "@/hubs/hubChat";

Vue.prototype.moment = moment

Vue.prototype.$httpAdminApi = httpAdminApi
Vue.prototype.$http = httpApi
Vue.prototype.$httpGraph = httpGraphAPi
Vue.prototype.$httpPlacesApi = httpPlacesApi
Vue.prototype.$hub = new Hub(`${process.env.VUE_APP_API_NOTIFICATION}`);
Vue.prototype.$signalChat = httpSignal
Vue.prototype.$desktopNotify = require('simple-web-notification')

Vue.config.productionTip = false

Vue.use(VuetifyToast, {
    y: 'top',
    showClose: true,
    queueable: true
})

Vue.component('GMapCluster', GMapCluster)
Vue.use(VueSweetalert2);
Vue.use(VueLuxon);
Vue.use(VueTimepicker);
Vue.use(Chat)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueTour)
Vue.use(VueGoogleMaps, {
    load: {
        types: ['(cities)'],
        key: 'AIzaSyDuGaG0NcwlTP8q8c-eKj9MaB8G7JlJBkc',
        language: 'en',
        region: 'US',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)
        components: true
        //// If you want to set the version, you can do so:
        // v: '3.26',
    },
})

Vue.filter("toCurrency", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
});
Vue.filter("numberFormat", function (value) {
    let number = parseInt(value);

    if (typeof number !== "number") {
        return number;
    }
    let n = 0;
    let re = "\\d(?=(\\d{" + 3 + "})+" + (n > 0 ? "\\." : "$") + ")";
    return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
});


// Global Mixin
Vue.mixin({
    methods: {
        cleanText(val) {
            return val
                .normalize('NFD')
                .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
                .normalize();
        }
    }
})

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
    apolloProvider: createProvider(),

    async created() {
        this.$store.dispatch('working', false);
        this.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
        let token = this.$store.getters.token;

      /*  async function testChat(token) {
            if (token) {
                await this.$router.isReady();
                this.$router.replace({name: "Login"});
            } else {
                this.$hubChat.startConnection(this.$store.getters.token);
            }
        }*/

       // testChat(token)
        if (token) {
            console.log(token);
            this.$http.defaults.headers["Authorization"] = 'Bearer ' + token;

            Vue.prototype.$hubChat = await new HubChat(`${process.env.VUE_APP_API_SIGNAL}`);

            await this.$hubChat.startConnection(this.$store.getters.token);
            this.$signalChat.defaults.headers["Authorization"] = "Bearer " + this.$store.getters.token;
            console.log(this.$hubChat.Connection)
            if (this.$hubChat.Connection.state !== 'Connected') {
                /*this.$router.replace({name: "Login"});*/
            }
            // if (this.$hubChat)
        }

        this.$http.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    this.$toast.error("Tu sesión expiro, inicia sesión nuevamente.");
                    this.$router.replace({name: "Login"});
                }
                 return Promise.reject(error);
            }
        );

    },
}).$mount('#app')
