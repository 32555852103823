<template>
  <div>

    <v-row justify="center">
      <!--      <v-col md="10">-->
      <!--        <v-img :src="require('@/assets/image/SVG/' + Services_.resulImage +'.svg')"></v-img>-->
      <!--        <br>-->
      <!--      </v-col>-->

      <v-col md="4">
        <v-checkbox color="primary" v-model="Services_.Recoleccion" @change="ImageShow()" label="Recoleccion" dense>
        </v-checkbox>

        <v-checkbox color="primary" @change="ImageShow()" dense v-model="Services_.DespachoEnOrigen"
                    label="Despacho en Origen"></v-checkbox>

        <v-checkbox color="primary" @change="ImageShow()" v-model="Services_.GastosPortuariosOrigen"
                    label="Gastos Porturarios en Origen" dense></v-checkbox>

      </v-col>
      <v-col md="4">

        <v-checkbox color="primary" v-model="Services_.GastosPortuariosDestino" @change="ImageShow()"
                    label="Gastos Portuarios en Destino" dense></v-checkbox>

        <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.DespachoDeImportacion"
                    label="Despacho de Importacion" dense></v-checkbox>

        <v-checkbox color="primary" @change="ImageShow()" v-model="Services_.FleteMaritimo" label="Flete" dense>
        </v-checkbox>
      </v-col>

      <v-col md="4">
        <v-checkbox @change="ImageShow()" color="primary" dense v-model="Services_.Entrega"
                    label="Entrega"></v-checkbox>

        <v-checkbox @change="handleSelectAll()" color="primary" dense v-model="SelectAll" label="Seleccionar Todos">
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel
              v-for="(item,i) in 1"
              :key="i"
          >
            <v-expansion-panel-header><h2 class="font-weight-regular primary--text">Servicios Adicionales</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col md="4">
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.AgenteAduanal"
                              label="Agente Aduanal" dense></v-checkbox>
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.SeguroMercancias"
                              label="Seguro de carga" dense></v-checkbox>
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.Embalaje"
                              label="Trincaje/embalaje" dense></v-checkbox>
                </v-col>
                <v-col md="4">
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.Almacenamiento"
                              label="Almacenamiento" dense></v-checkbox>
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.CustodiaArmada"
                              label="Custodia armada" dense></v-checkbox>
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.Paletizadora"
                              label="Paletizadora" dense></v-checkbox>
                </v-col>
                <v-col md="4">
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.Inspeccion" label="Inspección"
                              dense></v-checkbox>
                  <v-checkbox @change="ImageShow()" color="primary" v-model="Services_.Maniobras" label="Maniobras"
                              dense></v-checkbox>
                </v-col>
              </v-row>
              <div>
                <v-row v-if="Services_.SeguroMercancias == true">
                  <v-col>
                    <v-text-field type="number" dense label="Costo de mercancía" v-model="servicesFields.GoodsCost" @change="sendServiceFields" outlined>
                    </v-text-field>
                  </v-col>
                </v-row>

              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>
<script>
const ORDER_TYPES = {
  import: 1,
  export: 2,
  national: 3
};

const INCOTERM_SERVICES_IMPORT = {
  'EXW': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo', 'GastosPortuariosDestino',
    'DespachoDeImportacion', 'Entrega'
  ],
  'FCA': ['GastosPortuariosOrigen', 'FleteMaritimo', 'GastosPortuariosDestino', 'DespachoDeImportacion', 'Entrega'],
  'FAS': ['GastosPortuariosOrigen', 'FleteMaritimo', 'GastosPortuariosDestino', 'DespachoDeImportacion', 'Entrega'],
  'FOB': ['FleteMaritimo', 'GastosPortuariosDestino', 'DespachoDeImportacion', 'Entrega'],
  'CFR': ['GastosPortuariosDestino', 'DespachoDeImportacion', 'Entrega'],
  'CIF': ['GastosPortuariosDestino', 'DespachoDeImportacion', 'Entrega'],
  'CPT': ['GastosPortuariosDestino', 'DespachoDeImportacion', 'Entrega'],
  'CIP': ['GastosPortuariosDestino', 'DespachoDeImportacion', 'Entrega'],
  'DAT': ['DespachoDeImportacion', 'Entrega'],
  'DAP': ['DespachoDeImportacion'],
  'DDP': []
};

const INCOTERM_SERVICES_EXPORT = {
  'EXW': [],
  'FCA': ['Recoleccion', 'DespachoEnOrigen'],
  'FAS': ['Recoleccion', 'DespachoEnOrigen'],
  'FOB': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen'],
  'CFR': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo'],
  'CIF': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo'],
  'CPT': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo'],
  'CIP': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo'],
  'DAT': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo', 'GastosPortuariosDestino'],
  'DAP': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo', 'GastosPortuariosDestino',
    'Entrega'
  ],
  'DDP': ['Recoleccion', 'DespachoEnOrigen', 'GastosPortuariosOrigen', 'FleteMaritimo', 'GastosPortuariosDestino',
    'DespachoDeImportacion', 'Entrega'
  ]
};

export default {
  name: "Services",
  props: {
    incoterm: {
      type: Number,
      default: 0,
    },
    Services_: {
      type: Object,
      default: function () {
        return {
          Almacenaje: 0,
          Seguro: 0,
          Embalaje: 0,
          Financiamiento: 0,
          Recoleccion: 0,
          DespachoEnOrigen: 0,
          GastosPortuariosOrigen: 0,
          FleteMaritimo: 0,
          GastosPortuariosDestino: 0,
          DespachoDeImportacion: 0,
          Entrega: 0,
          AgenteAduanal: 0,
          Almacenamiento: 0,
          CustodiaArmada: 0,
          Paletizadora: 0,
          Inspeccion: 0,
          Maniobras: 0,
          SeguroMercancias: 0,
          GoodsCost: null,
          resulImage: ""
        }
      }
    },
    type: {
      type: Number,
      default: 0,
    }
  },
  watch: {
    type: function () {
      console.log('Type changed ' + this.incoterm)
      this.findServicesByIncoterm(this.type, this.incoterm);
    },
    incoterm: function () {
      console.log('Incoterm changed ' + this.incoterm)
      this.findServicesByIncoterm(this.type, this.incoterm);
    }
  },
  data() {
    return {
      SelectAll: false,
      services: {
        Almacenaje: 0,
        Seguro: 0,
        Embalaje: 0,
        Financiamiento: 0,
        Recoleccion: 0,
        DespachoEnOrigen: 0,
        GastosPortuariosOrigen: 0,
        FleteMaritimo: 0,
        GastosPortuariosDestino: 0,
        DespachoDeImportacion: 0,
        Entrega: 0,
        AgenteAduanal: 0,
        Almacenamiento: 0,
        CustodiaArmada: 0,
        Paletizadora: 0,
        Inspeccion: 0,
        Maniobras: 0,
        SeguroMercancias: 0,
        resulImage: ""
      },
      servicesFields:{},
      servicesData: [
        {
          resulImage: "",
        },
        {
          name: 'Almacenaje',
          id: '',
          value: ''
        },
        {
          name: 'Seguro',
          id: '',
          value: ''
        },
        {
          name: 'Embalaje',
          id: '',
          value: ''
        },
        {
          name: 'Financiamiento',
          id: '',
          value: ''
        },
        {
          name: 'Recoleccion',
          id: '',
          value: ''
        },
        {
          name: 'Despacho en Origen',
          id: '',
          value: ''
        },
        {
          name: 'Gastos Portuarios',
          id: '',
          value: ''
        },
        {
          name: 'Flete Maritimo',
          id: '',
          value: ''
        },
        {
          name: 'Gastos Portuarios',
          id: '',
          value: ''
        },
        {
          name: 'Despacho de Importacion',
          id: '',
          value: ''
        },
        {
          name: 'Entrega',
          id: '',
          value: ''
        },
        {
          name: 'Deff',
          id: '',
          value: ''
        },

      ],
      orderServices: [],
      resultServ: null
    }
  },
  methods: {
    resetServices(){
      this.Services_ = {
        Almacenaje: 0,
        Seguro: 0,
        Embalaje: 0,
        Financiamiento: 0,
        Recoleccion: 0,
        DespachoEnOrigen: 0,
        GastosPortuariosOrigen: 0,
        FleteMaritimo: 0,
        GastosPortuariosDestino: 0,
        DespachoDeImportacion: 0,
        Entrega: 0,
        AgenteAduanal: 0,
        Almacenamiento: 0,
        CustodiaArmada: 0,
        Paletizadora: 0,
        Inspeccion: 0,
        Maniobras: 0,
        SeguroMercancias: 0,
        resulImage: ""
      }
      this.SelectAll = false;
      this.ImageShow();

    },

    sendServiceFields(){
      this.$emit('serviceFields',this.servicesFields)
    },





    ImageShow: function () {
      this.orderServices = [];

      this.Services_.resulImage = "";
      this.Services_.resulImage += this.Services_.Almacenaje ? '1' : '0';
      this.Services_.resulImage += this.Services_.Seguro ? '1' : '0';
      this.Services_.resulImage += this.Services_.Embalaje ? '1' : '0';
      this.Services_.resulImage += this.Services_.Financiamiento ? '1' : '0';
      this.Services_.resulImage += this.Services_.Recoleccion ? '1' : '0';
      this.Services_.resulImage += this.Services_.DespachoEnOrigen ? '1' : '0';

      this.Services_.resulImage += this.Services_.GastosPortuariosOrigen ? '1' : '0';
      this.Services_.resulImage += this.Services_.FleteMaritimo ? '1' : '0';
      this.Services_.resulImage += this.Services_.GastosPortuariosDestino ? '1' : '0';
      this.Services_.resulImage += this.Services_.DespachoDeImportacion ? '1' : '0';
      this.Services_.resulImage += this.Services_.Entrega ? '1' : '0';

      console.log("result: ", this.Services_.resulImage);

      if (this.Services_.Almacenaje) {
        this.orderServices.push({
          serviceId: 16
        },)
      }

      if (this.Services_.Seguro) {
        this.orderServices.push({
          serviceId: 9
        },)
      }

      if (this.Services_.Embalaje) {
        this.orderServices.push({
          serviceId: 1
        },)
      }

      if (this.Services_.Financiamiento) {
        this.orderServices.push({
          serviceId: 14
        },)
      }

      if (this.Services_.resulImage === '10011111111') {
        this.SelectAll = true
      } else {
        this.SelectAll = false;
      }

      if (this.Services_.Recoleccion) {
        this.orderServices.push({
          serviceId: 2
        },)
      }

      if (this.Services_.DespachoEnOrigen) {
        this.orderServices.push({
          serviceId: 3
        },)
      }

      if (this.Services_.GastosPortuariosOrigen) {
        this.orderServices.push({
          serviceId: 4
        },)
      }

      if (this.Services_.FleteMaritimo) {
        this.orderServices.push({
          serviceId: 5
        },)
      }

      if (this.Services_.GastosPortuariosDestino) {
        this.orderServices.push({
          serviceId: 10
        },)
      }

      if (this.Services_.DespachoDeImportacion) {
        this.orderServices.push({
          serviceId: 12
        },)
      }

      if (this.Services_.Entrega) {
        this.orderServices.push({
          serviceId: 13
        },)
      }

      if (this.Services_.AgenteAduanal) {
        this.orderServices.push({
          serviceId: 15
        },)
      }


      if (this.Services_.SeguroMercancias) {
        this.orderServices.push({
          serviceId: 8
        },)
      }


      if (this.Services_.Almacenamiento) {
        this.orderServices.push({
          serviceId: 16
        },)
      }


      if (this.Services_.CustodiaArmada) {
        this.orderServices.push({
          serviceId: 17
        },)
      }


      if (this.Services_.Paletizadora) {
        this.orderServices.push({
          serviceId: 18
        },)
      }


      if (this.Services_.Inspeccion) {
        this.orderServices.push({
          serviceId: 19
        },)
      }


      if (this.Services_.Maniobras) {
        this.orderServices.push({
          serviceId: 20
        },)
      }

      this.$emit('data', this.orderServices);
    },
    handleSelectAll: function () {
      if (this.SelectAll) {
        this.SelectAll = true;
        // this.Services_.Almacenaje = 1;
        // this.Services_.Financiamiento = 1;
        this.Services_.Recoleccion = 1;
        this.Services_.DespachoEnOrigen = 1;

        this.Services_.GastosPortuariosOrigen = 1;
        this.Services_.FleteMaritimo = 1;
        this.Services_.GastosPortuariosDestino = 1;
        this.Services_.DespachoDeImportacion = 1;
        this.Services_.Entrega = 1;
      } else {
        // this.Services_.Almacenaje = 0;
        // this.Services_.Financiamiento = 0;
        this.Services_.Recoleccion = 0;
        this.Services_.DespachoEnOrigen = 0;

        this.Services_.GastosPortuariosOrigen = 0;
        this.Services_.FleteMaritimo = 0;
        this.Services_.GastosPortuariosDestino = 0;
        this.Services_.DespachoDeImportacion = 0;
        this.Services_.Entrega = 0;
      }


      this.ImageShow()
    },
    findServicesByIncoterm: async function (type, id) {
      let services = null;
      if (type && id) {
        console.log('Finding services')
        let incoterm = await this.$http.get(`Incoterms/${id}`)
        incoterm = incoterm.data.data


        if (!incoterm)
          throw new Error('Algo muy raro acaba de pasar')

        if (type == ORDER_TYPES.import)
          services = INCOTERM_SERVICES_IMPORT[incoterm.code];

        if (type == ORDER_TYPES.export)
          services = INCOTERM_SERVICES_EXPORT[incoterm.code];

      }
      this.mapServices(services);
    },
    mapServices(services) {
      console.log("Services", this.Services_)
      // Object.entries(this.Services_).forEach(key => this.resultServ[key[0]] = 0)
      if (services) {
        services.forEach(service => this.Services_[service] = 1)
      }
      // this.ImageShow()
    },
    setServices() {
      console.log(this.Services_);

      let orderService = [];

      // this.Services_.forEach(element => {
      //   orderService.push({
      //     serviceId: element.serviceId
      //   })
      // })

      orderService.forEach(element => {
        if (element.serviceId == 16) {
          this.Services_.Almacenaje = 1;
          this.Services_.resulImage = this.services.Almacenaje ? '1' : '0';

        }

        if (element.serviceId == 8) {
          this.Services_.Seguro = 1;
          this.Services_.resulImage = this.services.Seguro ? '1' : '0';

        }

        if (element.serviceId == 1) {
          this.Services_.Embalaje = 1;
          this.Services_.resulImage += this.services.Embalaje ? '1' : '0';
        }

        if (element.serviceId == 14) {
          this.Services_.Financiamiento = 1;
          this.Services_.resulImage += this.services.Financiamiento ? '1' : '0';
        }

        if (element.serviceId == 2) {
          this.Services_.Recoleccion = 1;
          this.Services_.resulImage += this.services.Recoleccion ? '1' : '0';
        }

        if (element.serviceId == 3) {
          this.Services_.DespachoEnOrigen = 1;
          this.Services_.resulImage += this.services.DespachoEnOrigen ? '1' : '0';
        }

        if (element.serviceId == 4) {
          this.Services_.GastosPortuariosOrigen = 1;
          this.Services_.resulImage += this.services.GastosPortuariosOrigen ? '1' : '0';
        }

        if (element.serviceId == 5) {
          this.Services_.FleteMaritimo = 1;
          this.Services_.resulImage += this.services.FleteMaritimo ? '1' : '0';
        }

        if (element.serviceId == 10) {
          this.Services_.GastosPortuariosDestino = 1;
          this.Services_.resulImage += this.services.GastosPortuariosDestino ? '1' : '0';
        }

        if (element.serviceId == 12) {
          this.Services_.DespachoDeImportacion = 1;
          this.Services_.resulImage += this.services.DespachoDeImportacion ? '1' : '0';
        }

        if (element.serviceId == 13) {
          this.Services_.Entrega = 1;
          this.Services_.resulImage += this.services.Entrega ? '1' : '0';
        }


      })

      console.log(this.Services_.resulImage)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setServices();
      this.ImageShow();
    })

  },
  created() {
    if (this.type && this.incoterm)
      this.findServicesByIncoterm();
  }
}
</script>

<style scoped>

>>> .v-item-group {
  position: static;
}

</style>
