const _GLOBALS = {

    ORDER_TYPE: {
        IMPORT: 1,
        EXPORT: 2,
        NATIONAL: 3
    },
    TRANSPORTATION: {
        MARITIME: 1,
        AIR: 2,
        LAND: 3,
    },
    SUPPLIER_TYPES: {
        AIR: 2,
        LAND: 3,
        MARITIME: 4,
        MARKETING: 5,
        INSPECTION: 6,
        CARGO_SAFE: 7,
        WAREHOUSING: 8,
        PACKAGING: 9,
        FINANCING: 10
    }
}
export default {
    methods: {

        formatFolio(item) {
            let folio = "";
            console.log(_GLOBALS)


            if (1 == item.businessId) {
                folio += "S"
            }
            if (2 == item.businessId) {
                folio += "C"
            }

            folio += item.Id + "-";


            if (_GLOBALS.ORDER_TYPE.IMPORT == item.OrderTypeId) {
                folio += "I"
            }
            if (_GLOBALS.ORDER_TYPE.EXPORT == item.OrderTypeId) {
                folio += "E"
            }
            if (_GLOBALS.ORDER_TYPE.NATIONAL == item.OrderTypeId) {
                folio += "N"
            }
            if (_GLOBALS.TRANSPORTATION.MARITIME == item.TransportationId) {
                folio += "M"
            }
            if (_GLOBALS.TRANSPORTATION.LAND == item.TransportationId) {
                folio += "T"
            }
            if (_GLOBALS.TRANSPORTATION.AIR == item.TransportationId) {
                folio += "A"
            }

            if (item.TransportationCategory.description == 'Servicio Express') {
                item.TransportationCategory.description = 'SE';
            }

            if (item.TransportationCategory.description == 'Hand Carrier') {
                item.TransportationCategory.description = 'HC';
            }

            if (item.TransportationCategory.description == 'Servicio Regular') {
                item.TransportationCategory.description = 'SR';
            }

            if (this.cleanText(item.TransportationCategory.description) == 'Paqueteria Aerea') {
                item.TransportationCategory.description = 'PA';
            }

            if (item.TransportationCategory.description == 'Air Charter') {
                item.TransportationCategory.description = 'ACH';
            }

            if (this.cleanText(item.TransportationCategory.description) == 'Arrastres de contenedores') {
                item.TransportationCategory.description = 'ADC';
            }

            if (this.cleanText(item.TransportationCategory.description) == 'Bulk-RoRo') {
                item.TransportationCategory.description = 'BRR';
            }

            if (this.cleanText(item.TransportationCategory.description) == 'Bulk-BreakBulk') {
                item.TransportationCategory.description = 'BBB';
            }


            folio += item.TransportationCategory.description
            folio += item.Origin[0].country ? item.Origin[0].country.substring(0, 3).toUpperCase() + "-" : ""
            folio += item.Destination[0].country ? item.Destination[0].country.substring(0, 3).toUpperCase() : ""
            item.folio = folio;
        },
        returnFolio(item){
            let folio = "";
            if (1 == item.businessId) {
                folio += "S"
            }
            if (2 == item.businessId) {
                folio += "C"
            }

            folio += item.id + "-";

            if (_GLOBALS.ORDER_TYPE.IMPORT == item.OrderTypeId) {
                folio += "I"
            }
            if (_GLOBALS.ORDER_TYPE.EXPORT == item.OrderTypeId) {
                folio += "E"
            }
            if (_GLOBALS.ORDER_TYPE.NATIONAL == item.OrderTypeId) {
                folio += "N"
            }
            if (_GLOBALS.TRANSPORTATION.MARITIME == item.TransportationId) {
                folio += "M"
            }
            if (_GLOBALS.TRANSPORTATION.LAND == item.TransportationId) {
                folio += "T"
            }
            if (_GLOBALS.TRANSPORTATION.AIR == item.TransportationId) {
                folio += "A"
            }

            if (item.transportationCategory.description == 'Servicio Express') {
                item.transportationCategory.description = 'SE';
            }

            if (item.transportationCategory.description == 'Hand Carrier') {
                item.transportationCategory.description = 'HC';
            }

            if (item.transportationCategory.description == 'Servicio Regular') {
                item.transportationCategory.description = 'SR';
            }

            if (this.cleanText(item.transportationCategory.description) == 'Paqueteria Aerea') {
                item.transportationCategory.description = 'PA';
            }

            if (item.transportationCategory.description == 'Air Charter') {
                item.transportationCategory.description = 'ACH';
            }

            if (this.cleanText(item.transportationCategory.description) == 'Arrastres de contenedores') {
                item.transportationCategory.description = 'ADC';
            }

            if (this.cleanText(item.transportationCategory.description) == 'Bulk-RoRo') {
                item.transportationCategory.description = 'BRR';
            }

            if (this.cleanText(item.transportationCategory.description) == 'Bulk-BreakBulk') {
                item.transportationCategory.description = 'BBB';
            }


            folio += item.transportationCategory.description
            folio += item.origin[0].country ? item.origin[0].country.substring(0, 3).toUpperCase() + "-" : ""
            folio += item.destination[0].country ? item.destination[0].country.substring(0, 3).toUpperCase() : ""

            return folio;
        }
    }
}
