<template>
  <div class="fill-height">
    <v-bottom-sheet inset v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue darken-2" dark right bottom fixed v-bind="attrs" v-on="on" fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon>Crear Grupo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark left @click="sheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="mt-4">
          <v-col cols="3" class="ml-3">
            <v-select
                label="Empresa"
                item-text="text"
                item-value="id"
                v-model="businessId"
                :items="empessaHeaders"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="businessName"
                label="Nombre de Grupo"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="ml-3">
            <v-card-text>
              <v-autocomplete label="Empleados relacionados"
                        chips
                        deletable-chips
                        multiple
                        v-model="EmployesSelected"
                        :items="Employees"
                        :item-text="item => item.firstName + ' ' + item.lastName"
                        item-value="id"
                        return-object
                        dense
                        @click="getEmployees"
                      >
              </v-autocomplete>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="ml-2" cols="3">
            <v-text-field
                v-model="firstname"
                :rules="nameRules"
                :counter="10"
                label="Rol"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="ml-3">
            <v-select
                :items="items"
                label="Usuario"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="PostGrupo">
              Crear grupo
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-bottom-sheet>
    <v-card elevation="0" tile width="2000" class="pt-10">
      <v-row justify="center">
        <v-col md="12">
          <v-container style="margin-left: 0px; margin-right: 20px" tile elevation="0">
            <v-row justify="center">
              <v-col md="7">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    solo
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :headers="HeaderGroups"
                        :items-per-page="50"
                        :items="Grupos"
                        :hide-default-footer="true"
                        item-key="id"
                        :search="search"
                    >
                      <!-- Data validation -->
                      <template v-slot:item.id = {item}>
                        {{item.id ? item.id : 'NA'}}
                      </template>
                      <template v-slot:item.name = {item}>
                        {{item.name ? item.name : 'NA'}}
                      </template>
                      <template v-slot:item.taxNumber = {item}>
                        {{item.taxNumber ? item.taxNumber : 'NA'}}
                      </template>
                      <!-- Data validation -->
                      <template v-slot:item.edit = {item}>
                        <v-btn text @click="getGrupeByID(item.id)">
                          <v-icon color="primary">mdi-table-edit</v-icon>
                        </v-btn>
                      </template>

                      <template v-slot:item.status = {item}>
                        <v-icon :color="item.isAvailable ? 'green' : 'red' ">mdi-checkbox-blank-circle</v-icon>
                      </template>

                      <template v-slot:item.employees = {item}>
                        <v-select label="Operador"

                                  multiple
                                  :rules="requiredSelectRule"
                                  v-model="shipment.users"
                                  :items="item.employees.employeeId"
                                  :item-text="item => item.firstName + ' ' + item.lastName"
                                  item-value="id"
                                  dense
                                  class="pt-2"
                        >
                          <template v-slot:selection="{item,index}">
                          <span v-if="index === 0"
                                class="v-select__selection v-select__selection--comma">
                          </span>
                          </template>
                        </v-select>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-col>
      </v-row>


    </v-card>
    <v-dialog
        v-model="DialgoGrupData"
        activator="parent"
        width="700px"
    >
      <v-card>
        <v-card-title class="text-h5 blue darken-4 white--text">
          <b class="mr-2">{{ Grupos.name }}</b> - {{ Grupos.businessName }}
        </v-card-title>
        <v-row class="ml-5" v-for="(employee, index) in Grupos.employees" :key="index">
          <v-col md="7">
            <v-row>
              <v-icon  class="mt-8" >
                mdi-account-box
              </v-icon>
              <v-card-title class="pb-0 mt-4">
                <b >{{employee.employeeName}}</b>
              </v-card-title>
            </v-row>
          </v-col>
          <v-col>
            <v-card-text class="pb-0 mt-4">
              {{employee.employeeRole}}
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-text>
          <v-autocomplete label="Empleados relacionados"
                          chips
                          deletable-chips
                          multiple
                          v-model="Grupos.employeeIds"
                          :items="Employees"
                          :item-text="item => item.firstName + ' ' + item.lastName"
                          item-value="id"
                          dense
                          @change="setEmployees(Grupos.employeeIds)"
          >
          </v-autocomplete>
        </v-card-text>


        <v-card-actions>
          <v-btn color="primary" block @click="putGrupo(Grupos.id)"> Actualizar grupo</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
    <v-dialog
        v-model="dialog"
        width="800"
    >
      <v-card>

        <v-card-title class="text-h5 blue darken-4 white--text">
          Empleados
        </v-card-title>
        <v-card-text>
          <v-autocomplete label="Empleados"
                    multiple
                    v-model="EmployesSelected"
                    :items="Employees"
                    :item-text="item => item.firstName + ' ' + item.lastName"
                    return-object
                    dense
                    class="mt-4"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="putEmployees()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
name: "EmployeeGroup",
  data(){
    return{

      businessId: null,
      businessName: null,
      employees: [],
      EmployesSelected: [],
      Employees: [],
      DialgoGrupData: false,
      Grupos: [],
      empessaHeaders:[
        { text: 'Senator', id: 1 },
        { text: 'Continente Cargo', id: 2},
      ],
      HeaderGroups:[
        { text: 'id', value: 'id' },
        { text: 'Empresa', value: 'businessName' },
        {text: 'Nombre de Grupo', value: 'name',},
        {text: 'Empleados',value: 'edit'},
      ],


    }
  },

  methods: {
    setEmployees(data){
      let employees = [];
      data.forEach((item) => {
        
        employees.push({
          'employeeId': this.Grupos.employees.filter( x => x.employeeId == item.employeeId).id
        })
      })
    },
    GetDataEmployee(item){
      console.log(item)
      this.nombreEmpresa = item.name
      this.EmployesSelected = []
      this.idBusiness = item.id;
      this.dialog = true
      this.$store.dispatch('working',true);
      this.$http.get(`Business/${item.id}`).then((response) => {
        this.employee = response.data.data
        let aux = response.data.data.employees
        aux.forEach((element) => {
          this.EmployesSelected.push(element.employeeId)
        })
        console.log(response)
      }).catch((error) => {
        console.log(error)
      }).finally(()=> {
        this.$store.dispatch('working',false);
      })
    },

    getEmployees(){
      this.$store.dispatch('working',true);
      this.$http.get('Employees').then(response => {
        console.log(response.data.data);
        this.Employees = response.data.data;
        this.EmployeesStatus = this.Employees.length
        this.$emit('EmployeesQuantity',this.EmployeesStatus);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working',false);
      })
    },

    getGrupeByID:function(id){
      this.$store.dispatch('working', true);
      this.$http
          .get(`Groups/${id}`)
          .then((response) => {
            this.Grupos = response.data.data;
            this.DialgoGrupData = true;
            this.getEmployees();
            let employeeIds = [];
            this.Grupos.employees.forEach((item) => {
              employeeIds.push(item.employeeId);
            })
            this.Grupos.employeeIds = employeeIds;
            console.log(this.Grupos)
          }).catch((error) => {
        console.log(error);
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },
    getGroups:function(){
      this.$store.dispatch('working', true)
      this.$http.get("Groups").then(response => {
            this.Grupos = response.data.data;
            console.log(this.Grupos)
            this.$emit('groupQuantity',this.Grupos.length)
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working',false);
          });
    },
    PostGrupo(){
      console.log(this.EmployesSelected)
      let index = this.empessaHeaders.findIndex(x => x.id == this.businessId)
      let obj = {
        id: 0,
        businessId: this.businessId,
        businessName: this.empessaHeaders[index].text,
        name:this.businessName,
        employees: [],

      };
      obj.employees = this.EmployesSelected.map((element) => {
        /*let employees = {
          employeeId: element,
          employeeName: this.firstName,
          employeeRole:this.roleId,
          obj.employees.push(employees)
        }*/
        return{
          employeeId: element.id,
          employeeName: element.firstName,
          employeeRole:element.roleId
        }
      })

      this.$http
          .post("Groups",obj)
          .then((response) => {
            this.Grupos = response.data.data;
            console.log(this.Grupos)
          })
          .catch((error) => {
            console.log(error);
          });
    },

    putGrupo:function(id){
      let obj = {
        id: this.Grupos.id,
        businessId: this.Grupos.businessId,
        businessName: this.Grupos.businessName,
        name:this.Grupos.name,
        employees: [],

      };  
      obj.employees = this.Grupos.employeeIds.map(x => {
        let user = this.Grupos.employees.find(e => e.employeeId == x)
        console.log("usuario", user)
        return {
          employeeId: x,
          employeeRole: user != null ? user.employeeRole : "USUARIO"
        }
      })

      this.$http
         .put(`Groups/${id}`,obj)
         .then((response) => {
           this.Grupos = response.data.data;
           this.Grupos.employeeIds = this.Grupos.employees.map(x => x.employeeId)
         })
         .catch((error) => {
           console.log(error);
         });
    },
    updateGrupe() {
      this.shipmentWorking = true;
      this.$http
          .put(
              `Employee/Groups/${this.groupId}`
          )
          .then((response) => {
            this.$toast.info("Se a actualizado el grupo");

            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Algo salio mal, intente mas tarde");
          })
          .finally(() => {
            this.shipmentWorking = false;
          });
    },
  },
  mounted() {
    this.getGroups();
  },
}
</script>

<style scoped>

</style>
