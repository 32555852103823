import { LogLevel } from '@aspnet/signalr'
let signalR = require("@microsoft/signalr")

class Hub {

    constructor(url){
        console.log('Hub created')
        this.Connection = null;
        this.Url = url;
    }
    //https://localhost:5001
    //http://3.140.230.151:5001
    startConnection (token){
        this.Connection = new signalR.HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl(`${process.env.VUE_APP_API_NOTIFICATION}/hub?access_token=${token}`, {
                skipNegotiation: false,
                //accessTokenFactory: () => token
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this.Connection.start()
            .then(() => console.log('Connection started'))
            .catch(err => {
                console.error('Error while starting connection: '+ err)
                this.$store.dispatch('working', false)
            })
    }

}

export default Hub;

