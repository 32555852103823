var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-row',{attrs:{"justify":"center"}}),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.Data,"hide-default-footer":true,"pagination":_vm.options,"items-per-page":25,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"expanded-item",fn:function(){return undefined},proxy:true},{key:"item.urgent",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.urgent?'red':''}},[_vm._v("mdi-alert")])]}},{key:"item.dangerous",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dangerous ?'Si':'No')+" ")]}},{key:"item.pricerData",fn:function(ref){
var item = ref.item;
return [(item.pricer != null)?_c('div',[_vm._v(" "+_vm._s(item.pricer && item.pricer.firstName ? item.pricer && item.pricer.firstName : "")+" "+_vm._s(item.pricer && item.pricer.lastName ? item.pricer && item.pricer.lastName : "")+" ")]):_c('div',[_vm._v(" "+_vm._s(item.salesManager && item.salesManager.firstName ? item.salesManager && item.salesManager.firstName : "")+" "+_vm._s(item.salesManager && item.salesManager.lastName ? item.salesManager && item.salesManager.lastName : "")+" ")])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","ripple":""},on:{"click":function($event){return _vm.Proceed(item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-notebook-outline")])],1)]}}])}),_c('v-pagination',{attrs:{"length":_vm.MetaData.totalPages},on:{"input":function($event){return _vm.GetData()},"next":function($event){return _vm.GetData()},"previous":function($event){return _vm.GetData()}},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1)],1)]],2)],1)],1),_c('v-dialog',{staticClass:"pa-0",attrs:{"width":"1200"},model:{value:(_vm.proceed),callback:function ($$v) {_vm.proceed=$$v},expression:"proceed"}},[_c('v-card',[_c('h1',[_vm._v("h")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }