<template>
      <TableMenu></TableMenu>
</template>
<script>

import TableMenu from "@/components/Sales/List/Table-Menu";
export default {
  name: "pricing2",
  components: {TableMenu},
}
</script>




