<template>
  <v-card class="pa-6 grey lighten-4"
          outlined
          @dragover="dragOver"
          @dragleave="dragLeave"
          @drop="drop"
          style="cursor: pointer">
    <input type="file"
           name="fields[assetsFieldHandle][]"
           id="assetsFieldHandle"
           @change="onChange"
           multiple
           :accept="accept"
           style="width: 100%; height: 100%; position: absolute; opacity: 0; top: 0; left: 0;"
           class="overflow-hidden" ref="file">
    <label for="assetsFieldHandle" class="text-center d-block" style="cursor: pointer">
      Puedes arrastrar tus archivos aqui o dar <span class="text-decoration-underline">click aqui</span>
    </label>
    <v-sheet class="mt-3">
      <v-list v-if="fileList.length > 0">
        <v-list-item v-for="(item,index) in fileList" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="red" @click="remove(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-sheet>
    <v-progress-linear
        color="cyan"
        :value="loadingProgress"
        v-if="showLoading"
        height="5"
    ></v-progress-linear>
  </v-card>
</template>

<script>
export default {
  name: "FileDrag",
  props: {
    value: Array,
    accept: String,
    showLoading: Boolean,
    loadingProgress: Number
  },
  data: (vm) => ({
    fileList: vm.value
  }),
  watch: {
    fileList(value) {
      this.$emit('input', value)
      this.$emit('change');
    },
    value(value) {
      this.fileList = value;
    }
  },
  methods: {
    handdleInput() {
      this.$emit("input", this.fileList);
    },
    openFileSelection(){
      this.$refs.file.click();
    },
    onChange() {
      this.fileList = [...this.fileList, ...this.$refs.file.files];
      this.$refs.file.value = null;
      // console.log(this.fileList);
      // this.handdleInput();
    },
    remove(i) {
      this.fileList.splice(i, 1);
    },
    dragOver(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('lighten-4')) {
        event.currentTarget.classList.remove('lighten-4');
        event.currentTarget.classList.add('lighten-1');
      }
    },
    dragLeave(event) {
      event.currentTarget.classList.add('lighten-4');
      event.currentTarget.classList.remove('lighten-1');
    },
    drop(event) {
      event.preventDefault();
      this.fileList = [...this.fileList, ...event.dataTransfer.files];
      // this.handdleInput();
      //this.onChange();
      event.currentTarget.classList.add('lighten-4');
      event.currentTarget.classList.remove('lighten-1');
    }
  }
}
</script>

<style scoped>

</style>
