<template>
  <div>
    <v-row justify="center">
      <v-tabs
          v-model="tab"
          background-color="white"
          icons-and-text
          centered
          color="grey"
          height="150"
      >

        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>
          <CardStatus>
            <template v-slot:card-icon>
              mdi-account-tie
            </template>
            <template v-slot:card-title>
              Empresas
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{Count.businessCount}}</h1>
            </template>
          </CardStatus>
        </v-tab>

        <v-tab>
          <CardStatus>
            <template v-slot:card-icon>
              mdi-account-tie
            </template>
            <template v-slot:card-title>
              Grupos
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{Count.groupCount}}</h1>
            </template>
          </CardStatus>
        </v-tab>

        <v-tab>
          <CardStatus>
            <template v-slot:card-icon>
              mdi-account-hard-hat
            </template>
            <template v-slot:card-title >
              Empleados
            </template>
            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{Count.employeeCount}}</h1>
            </template>
          </CardStatus>
        </v-tab>

        <v-tab>
          <CardStatus>
            <template v-slot:card-icon>
              mdi-account
            </template>

            <template v-slot:card-title>
              Clientes
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{Count.clientCount}}</h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab>
          <CardStatus>
            <template v-slot:card-icon>
              mdi-account-tie
            </template>
            <template v-slot:card-title>
              Proveedores
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{Count.supplierCount}}</h1>
            </template>
          </CardStatus>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item>
          <businessList v-on="$event" ></businessList>

        </v-tab-item>
        <v-tab-item>
          <EmployeeGroup v-on="$event"></EmployeeGroup>
        </v-tab-item>

        <v-tab-item>
          <ListEmployees v-on="$event"></ListEmployees>
        </v-tab-item>

        <v-tab-item>
          <ListCustomers v-on="$event"></ListCustomers>
        </v-tab-item>

        <v-tab-item>
          <ListSuppliers v-on="$event"></ListSuppliers>
        </v-tab-item>

      </v-tabs-items>
    </v-row>
  </div>
</template>

<script>
import CardStatus from "@/components/GlobalComponents/Card-Status";
import ListEmployees from "@/components/Users/Employees/List-Employees";
import ListCustomers from "@/components/Users/List-Customers";
import ListSuppliers from "@/components/Users/List-Suppliers";
import businessList from "@/components/Users/business/businessList";
import EmployeeGroup from "@/components/Users/business/EmployeeGroup";


export default {
  name: "Table-Menu-Users",
  components:{ListSuppliers, ListCustomers, ListEmployees, CardStatus, businessList, EmployeeGroup},
  data(){
    return{
      Count: {},
      items3: [
        {
          text: 'Nature',
          icon: 'mdi-nature',
        },
        {
          text: 'Nightlife',
          icon: 'mdi-glass-wine',
        },
        {
          text: 'November',
          icon: 'mdi-calendar-range',
        },
        {
          text: 'Portland',
          icon: 'mdi-map-marker',
        },
        {
          text: 'Biking',
          icon: 'mdi-bike',
        },
      ],
      loading: false,
      search: '',
      selected: [],
      tab:0,
      items2: [
        {
          text: 'CMA',
          icon: 'mdi-nature',
        },
        {
          text: 'Maersk',
          icon: 'mdi-nature',
        },
        {
          text: 'Happag Lloyd',
          icon: 'mdi-nature',
        },
        {
          text: 'One',
          icon: 'mdi-nature',
        },
        {
          text: 'WSL',
          icon: 'mdi-nature',
        },
      ],
    }
  },
  mounted(){
    this.getCount();
  },
  methods: {
    getCount:function(){
      this.$store.dispatch('working', true)
      this.$http.get(`Utils/count `).then(response => {
            this.Count = response.data.data;
            console.log(this.Count)
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working',false);
          });
    }
  },
  computed: {
    allSelected () {
      return this.selected.length === this.items.length
    },
    categories () {
      const search = this.search.toLowerCase()

      if (!search) return this.items

      return this.items.filter(item => {
        const text = item.text.toLowerCase()

        return text.indexOf(search) > -1
      })
    },
    selections () {
      const selections = []

      for (const selection of this.selected) {
        selections.push(selection)
      }
      return selections
    },
  },
}
</script>

<style scoped>
.v-tab /deep/ {
  text-transform: none !important;
}

>>> .v-data-table td {
  padding: 0 20px;
}
</style>

