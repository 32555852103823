<template>
  <div>
    <Account_AdminCard class="pt-12">
      <template v-slot:employees>
        <Employees></Employees>
      </template>

      <template v-slot:customers>
        <Customers></Customers>
      </template>

      <template v-slot:suppliers>
        <Suppliers></Suppliers>
      </template>
    </Account_AdminCard>
  </div>
</template>

<script>
import Account_AdminCard from "@/components/Accounts_Admin/Account_Admin-Card";
import Employees from "@/components/Accounts_Admin/Employees";
import Customers from "@/components/Accounts_Admin/Customers";
import Suppliers from "@/components/Accounts_Admin/Suppliers";
export default {
  name: "Register",
  components: {Suppliers, Customers, Employees, Account_AdminCard},
  data () {
    return {
    }
  },
  methods:{

    LogOut:function(){


      // this.$store.dispatch('session/reset');
      this.$store.dispatch("token", null);
      this.$store.dispatch("name", null);
      this.$store.dispatch("lastname", null);

      console.log("Token logOut: ",this.$store.getters.token);
      console.log("Name logOut: ",this.$store.getters.name);
      console.log("lastName logOut: ",this.$store.getters.lastname);



    },
  },

}
</script>

<style scoped>


</style>


