<template>
  <div>
    <v-navigation-drawer width="23rem" absolute permanent style="overflow-y: hidden">
      <v-item-group v-model="cardStatus">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="4">
              <v-item v-slot="{active,toggle}" :value="2">
                <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                  <v-card-title class="justify-center align-content-center pa-1 pb-0">
                    <h5 class="text-center text-caption">Pendientes</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ AssignedQty }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>

            <v-col cols="4">
              <v-item v-slot="{active,toggle}" :value="3">
                    <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                      <v-card-title class="justify-center align-content-center pa-1 pb-0">
                        <h5 class="text-center text-caption">Solicitadas</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ AnsweredQty }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>

            <v-col cols="4">
              <v-item v-slot="{active,toggle}" :value="4">
                <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                  <v-card-title class="justify-center align-content-center pa-1 pb-0">
                    <h5 class="text-center text-caption">Enviadas</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ SentQty }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="6">
              <v-item v-slot="{active,toggle}" :value="8">
                <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                  <v-card-title class="justify-center align-content-center pa-1 pb-0">
                    <h5 class="text-center text-caption">Por Confirmar</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ shippingConfirmation }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
            <v-col cols="6">
              <v-item v-slot="{active, toggle}" :value="7">
                <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                  <v-card-title class="justify-center align-content-center pa-1 pb-0">
                    <h5 class="text-center text-caption">Confirmadas</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ shippingConfirmed }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <v-toolbar tile elevation="0">
        <v-text-field
            v-model="search"
            class="my-2"
            @input="filterByData"
            dense label="Buscar"
            single-line
            hide-details
        >
          <template v-slot:append>
            <v-menu
                offset-y
                content-class="included"
                :close-on-content-click="false"
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        icon
                        small
                        class="mx-1"
                        v-bind="attrs"
                        v-on="{...menu, ...tooltip}"
                    >
                      <v-icon>mdi-tune</v-icon>
                    </v-btn>
                  </template>
                  Buscar por...
                </v-tooltip>

              </template>
              <v-card
                  width="300"
              >
                <v-card-text>
                  <h2 class="font-weight-regular">Filtrar</h2>
                  <v-row>
                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-menu
                              v-model="customerFilterMenu"
                              open-on-click
                              offset-y
                          >
                            <template v-slot:activator="{on: menu}">
                              <v-text-field
                                  v-model="filterCustomer"
                                  label="Cliente"
                                  dense
                                  v-on="menu"
                                  :append-icon="customerFilterMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
                              >
                              </v-text-field>
                              <!--                              :items="Customers"-->
                            </template>
                            <v-card
                                min-width="200"
                                max-height="150"
                            >
                              <v-list>
                                <v-list-item
                                    v-for="(customer, index) in Customers.filter(x => filterCustomer ? x.name.toLowerCase().includes(filterCustomer.toLowerCase()) : true)"
                                    :key="`'customer'${index}`" @click="filterCustomer = customer.name"
                                >{{ customer.name }}
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-menu
                              v-model="salesManagerFilterMenu"
                              open-on-click
                              offset-y
                          >
                            <template v-slot:activator="{on: menu}">
                              <v-text-field
                                  v-model="filterSalesman"
                                  label="Vendedor"
                                  dense
                                  v-on="menu"
                                  :append-icon="salesManagerFilterMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
                              >
                              </v-text-field>
                            </template>
                            <v-card
                                min-width="200"
                                max-height="150"
                            >
                              <v-list>
                                <v-list-item
                                    v-for="(salesManager, index) in SalesManagers.filter(x => filterSalesman ? (x.firstName.toLowerCase().includes(filterSalesman.toLowerCase()) || x.lastName.toLowerCase().includes(filterSalesman.toLowerCase())) : true)"
                                    :key="`'sales_manager_'${index}`"
                                    @click="filterSalesman = salesManager.firstName + ' ' + salesManager.lastName"
                                >{{ salesManager.firstName + ' ' + salesManager.lastName }}
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-menu
                              v-model="pricerFilterMenu"
                              open-on-click
                              offset-y
                          >
                            <template v-slot:activator="{on: menu}">
                              <v-text-field
                                  v-model="filterPricer"
                                  label="Pricer"
                                  dense
                                  v-on="menu"
                                  :append-icon="pricerFilterMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
                              >
                              </v-text-field>
                            </template>
                            <v-card
                                min-width="200"
                                max-height="150"
                            >
                              <v-list>
                                <v-list-item
                                    v-for="(pricer, index) in Employees.filter(x => filterPricer ? (x.firstName.toLowerCase().includes(filterPricer.toLowerCase()) || x.lastName.toLowerCase().includes(filterPricer.toLowerCase())) : true)"
                                    :key="`'pricer_'${index}`"
                                    @click="filterPricer = pricer.firstName + ' ' + pricer.lastName"
                                >{{ pricer.firstName + ' ' + pricer.lastName }}
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-autocomplete
                              :items="OrderStatus"
                              item-text="name"
                              item-value="id"
                              label="Status"
                              dense
                              v-model="filterStatus"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="GetData()" color="primary">Buscar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>

        </v-text-field>
      </v-toolbar>
      <v-divider></v-divider>

      <v-list dense style="height: 77vh; overflow-y: auto">

        <InfiniteScroll v-bind="infiniteScrollProps" @loadMoreItems="GetData">
          <template v-slot="{ item,index }">
            <v-list-item-group
                :value="selectedItemOrder"
                active-class="grey lighten-2"
            >


              <v-list-item @click="RedirectPrices(item)" class="pa-0">


                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <v-card
                        class="pa-0"
                        :color="index == selectedItemOrder ? 'grey lighten-2' : ''"
                    >
                      <v-card-title>
                        <v-row no-gutters class="mx-2">
                          <v-col md="7">
                            <v-row
                                no-gutters
                                class="text-right"
                            >

                            </v-row>
                            <v-row
                                no-gutters
                            >
                              <span class="font-weight-regular text-caption">{{ item.RefCode ? item.RefCode : 'Sin Folio' }}</span>
                            </v-row>
                            <v-row
                                no-gutters
                                style="min-height: 15px"
                            >
                              <div v-if="item.SalesManager != null && item.Client!=null">
                                <span class="font-weight-regular text-subtitle-2">{{item.SalesManager ? `${item.SalesManager.firstName}`: '' }} - {{ item.Client.name ? item.Client.name : ''  }}</span>
                              </div>
                            </v-row>
                          </v-col>
                          <v-col md="4">
                            <v-row
                                no-gutters
                                class="text-right"
                            >
                          <span v-if="item.createdAt"
                              class="font-weight-regular text--grey text-caption" style="font-size: 0.55rem !important">
                            {{
                              item.CreatedAt | luxon({
                                output: {
                                  zone: "local",
                                  format: "short"
                                }
                              })
                            }}
                          </span>
                            </v-row>
                            <v-row
                                no-gutters
                                style="min-height: 15px"
                            >
                            </v-row>
                            <v-row
                                no-gutters
                                class="text-right"
                            >
                              <span class="text-caption text-truncate">{{ item.StatusOrder ? item.StatusOrder : 'Sin Status' }}</span>
                            </v-row>
                          </v-col>
                          <v-col md="1">

                            <v-row
                                no-gutters
                                style="min-height: 15px"
                            >
                              <v-avatar
                                  v-if="item.Notifications>0"
                                  color="primary"
                                  size="25"
                              >
                                <small class="white--text">{{ item.Notifications }}</small>
                              </v-avatar>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pr-2 pb-2">
                        <v-row class="d-flex justify-end">
                          <v-chip
                              class="ma-2"
                              x-small
                              color="red"
                              v-if="item.IsExpired == true"

                          >
                            vencida
                          </v-chip>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-list-item-title>
                  <v-divider color="grey"></v-divider>
                </v-list-item-content>
              </v-list-item>


            </v-list-item-group>
          </template>
        </InfiniteScroll>


      </v-list>

    </v-navigation-drawer>
    <div style="margin-left: 23rem; overflow-y: auto; height: 90vh">
      <v-container fluid :key="id">
        <v-row>
          <v-col>
            <!--          <div v-show="expandOrder" class="mx-auto white">-->
            <DetailOrder
                :key="detailKey"
                v-if="DataOrder.transportationId === 1"
                ref="detail"
                v-on:ShowDialog="ShowEditQuote()"
                v-on:ShowComment="ShowNewComment()"
                v-on:OpenCancelationModal="ShowCancelationDialog"
                v-on:statusOrder="statusOrder = $event"
                v-bind:id="id"
            >
            </DetailOrder>


            <DetailOrderAereo
                :key="detailKey"
                v-else-if="DataOrder.transportationId === 2"
                ref="detail"
                v-on:ShowDialog="ShowEditQuote()"
                v-on:ShowComment="ShowNewComment()"
                v-on:OpenCancelationModal="ShowCancelationDialog"
                v-on:statusOrder="statusOrder = $event"
                v-bind:id="id"
            >
            </DetailOrderAereo>


            <DetailOrderTerrestre
                :key="detailKey"
                v-else-if="DataOrder.transportationId === 3"
                ref="detail"
                v-on:ShowDialog="ShowEditQuote()"
                v-on:ShowComment="ShowNewComment()"
                v-on:OpenCancelationModal="ShowCancelationDialog"
                v-on:statusOrder="statusOrder = $event"
                v-bind:id="id"
            >
            </DetailOrderTerrestre>
            <br>
            <v-card elevation="0" outlined class="pl-0">
              <v-row no-gutters class="pl-0" justify="center">


                <v-col md="9" class="pl-0 d-flex">
                  <v-col md="4" class="pl-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="ShowNotes" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-comment-text-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Notas</span>
                    </v-tooltip>
                  </v-col>
                  <v-col md="4" v-if="successPrice">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="dialog=true,dialogslot=6" v-bind="attrs" v-on="on">
                          <v-icon>mdi-send</v-icon>
                        </v-btn>
                      </template>
                      <span>Enviar a Ventas</span>
                    </v-tooltip>
                  </v-col>

                  <v-col md="4" v-if="statusOrder == 'Vendida'">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="dialog=true,dialogslot=6" v-bind="attrs" v-on="on">
                          <v-icon>mdi-file-document</v-icon>
                        </v-btn>
                      </template>
                      <span>Carta de instruciones</span>
                    </v-tooltip>
                  </v-col>

                </v-col>
                <v-col md="3">
                  <div v-if="statusOrder == 'Renegociación'">
                    <v-menu
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{on: menu}">
                        <v-alert type="error" dense v-on="menu">
                          <template v-slot:append>
                            <v-icon>mdi-menu-down</v-icon>
                          </template>
                          {{ statusOrder }}
                        </v-alert>
                      </template>
                      <v-card>
                        <v-list-item @click="requestSupplier">
                          <v-list-item-content>
                            Solicitado a proveedor
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-menu>
                  </div>
                  <div v-else-if="statusOrder == 'Pendiente Pricing'">
                    <v-menu
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{on: menu}">
                        <v-alert type="info" dense v-on="menu">
                          <template v-slot:append>
                            <v-icon>mdi-menu-down</v-icon>
                          </template>
                          {{ statusOrder }}
                        </v-alert>
                      </template>
                      <v-card>
                        <v-list-item @click="requestSupplier">
                          <v-list-item-content>
                            Solicitado a proveedor
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-menu>
                  </div>
                  <div v-else-if="elEstatus === 'Por Confirmar'">
                    <v-alert type="info" dense>{{ elEstatus }}</v-alert>
                  </div>
                  <div v-else-if="elEstatus === 'Confirmadas'">
                    <v-alert type="info" dense>{{ elEstatus }}</v-alert>
                  </div>
                  <div v-else>
                    <v-alert type="info" dense>{{ statusOrder }}</v-alert>
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters class="py-3" style="min-height: 200px">
                <FormatFees2
                    ref="fees"
                    v-bind:TransportationId="TransportationId"
                    v-bind:idOrder="id"
                    v-bind:origin="Origin"
                    v-bind:destination="Destination"
                    v-on:priceLength="priceLength = $event"
                    v-on:successPrices="successPrice = $event"
                    class="w-100"
                >
                </FormatFees2>
              </v-row>
              <!--          <v-tabs v-model="tab" >-->
              <!--            <v-tab-->
              <!--              v-for="item in Quotations"-->
              <!--              :key="item"-->
              <!--            >Orden # {{id}}</v-tab>-->
              <!--          </v-tabs>-->
              <!--          <v-tabs-items v-model="tab">-->
              <!--            <v-tab-item-->
              <!--              v-for="item in Quotations"-->
              <!--              :key="item"-->
              <!--            >-->
              <!--              -->
              <!--            </v-tab-item>-->
              <!--          </v-tabs-items>-->
            </v-card>
            <!--          </div>-->

          </v-col>

        </v-row>
        <v-dialog v-model="dialog" width="1000">
          <v-toolbar
              dark
              class="primary white--text"
          >
            <slot name="dialog-title">
              <h2 class="font-weight-regular" v-if="dialogslot==1">
                <v-icon left size="25">mdi-pencil-box-outline</v-icon>
                Editar Cotizacion
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==2">
                <v-icon left size="25"></v-icon>
                Devolver a ventas
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==3">
                <v-icon left size="25">mdi-comment-text-outline</v-icon>
                Comentarios al pricer
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==4">
                <v-icon left size="25">mdi-comment-text-outline</v-icon>
                Razon de cancelación
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==5">
                <v-icon left size="25">mdi-comment-text-outline</v-icon>
                Notas
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==6">
                <v-icon left size="25"></v-icon>
                ¿Desea enviar los precios a ventas?
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==7">
                <v-icon left size="25"></v-icon>
                La cotización no ha sido enviada a ventas ¿Desea continuar?
              </h2>


            </slot>

            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="CloseDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <EditQuote :key="editKey" @UpdateOrder="OrderUpdated" v-bind:id=id v-if="dialogslot==1"></EditQuote>
          <NewOrderComments v-bind:id="id" v-on:closeDialog="updateAndCloseDialog"
                            v-if="dialogslot==2"></NewOrderComments>
          <PricerComments v-if="dialogslot==3" v-bind:Id="id"></PricerComments>
          <CancelationComments v-if="dialogslot==4" v-bind:id="id" @success="GetDataOrder"
                               v-on:closeDialog="dialog = $event"></CancelationComments>
          <PricingNotes
              v-if="dialogslot === 5"
              ref="pricingNotes"
              v-bind:id="id"
              v-on:closeDialog="dialog = $event"
              @success="closeDialog"
              v-model="DataOrder.terms"
          ></PricingNotes>
          <v-card v-if="dialogslot==6">
            <v-card-text>
              <br>
              <v-row>
                <v-col md="3" class="offset-4">
                  <v-btn color="primary" @click="SendPrices">Si</v-btn>
                </v-col>

                <v-col md="3">
                  <v-btn color="red" class="white--text" @click="dialog=false">No</v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="dialogslot==7">
            <v-card-text>
              <br>
              <v-row>
                <v-col md="3" class="offset-4">
                  <v-btn color="primary" @click="Redirect">Si</v-btn>
                </v-col>

                <v-col md="3">
                  <v-btn color="red" class="white--text" @click="dialog=false">No</v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
    <confetti ref="confeti"/>

  </div>

</template>

<script>
import debouncePromise from "debounce-promise";
import DetailOrder from "@/components/Pricing/DetailOrder";
import {mapGetters} from "vuex"
import NewOrderComments from "@/components/Pricing/NewOrderComments";
import EditQuote from "@/components/Pricing/EditQuote";
import FormatFees2 from "@/components/Pricing/FormatFees2";
import PricerComments from "@/components/Pricing/PricerComments";
import CancelationComments from "@/components/Pricing/CancelationComments";
import PricingNotes from "@/components/Pricing/PricingNotes"
import DetailOrderAereo from "./DetailOrderAereo";
import DetailOrderTerrestre from "./DetailOrderTerrestre";
import folio_mixin from "@/mixins/folio_mixin";
import InfiniteScroll from "../InfiniteScroll";
import confetti from "@/components/confetti";


export default {
  name: "ProceedQuote",
  mixins: [folio_mixin],
  components: {
    InfiniteScroll,
    DetailOrderTerrestre,
    DetailOrderAereo,
    CancelationComments,
    PricerComments,
    FormatFees2,
    EditQuote,
    NewOrderComments,
    DetailOrder,
    PricingNotes,
    confetti
  },

  computed: {
    ...mapGetters([
      'idOrder'
    ]),
    selectedItemOrder: {
      get: function () {
        return this.Data.findIndex(x => x.Id == this.orderId)
      }
    },
    orderId: {
      get: function () {
        return this.$route.params.id
      }
    }
  },
  watch: {

    cardStatus() {
      this.Data = [];
      if (this.cardStatus == undefined) {
        this.GetData(1);
      } else {
        this.GetData();
      }
    },

    orderId(val) {
      this.id = val
      this.GetDataOrder();
    },


    length(val) {
      this.tab = val - 1
    },
    dialogslot() {
      if (this.dialogslot === 5) {
        console.log(this.$refs.detail)
        this.orderTerms = this.$refs.detail.DataOrder.terms
      }
    }

  },
  data() {
    return {
      elEstatus: null,
      AssignedQty: 0,
      dialog2: false,
      counts: {},
      sold:0,
      cardStatus: null,
      infinitePage: null,
      infiniteScrollProps: {
        page: 1,
        pageSize: 5,
        items: [],
        lastPage: null,
      },
      AnsweredQty: 0,
      shippingConfirmation: 0,
      shippingConfirmed: 0,
      SentQty: 0,
      editKey: 0,
      detailKey: 0,
      customerFilterMenu: false,
      salesManagerFilterMenu: false,
      cont: 1,
      pricerFilterMenu: false,
      e1: 1,
      colorStatus: [
        {
          status: 'Enviado a Ventas',
          color: 'green darken-2'
        },
        {
          status: 'Pendiente Pricing',
          color: 'yellow darken-2'
        },
        {
          status: 'Solicitado a Proveedor',
          color: 'red darken-2'
        }
      ],
      filterBy: {},
      filter: false,
      aux: [],
      Customers: [],
      SalesManagers: [],
      Employees: [],
      filterCustomer: null,
      filterSalesman: null,
      filterPricer: null,
      filterStatus: null,
      transportation: null,
      priceLength: 0,
      TransportationId: 1,
      originItems: [],
      sendSaleDialog: false,
      originSelections: {},
      destinationItems: [],
      destinationSelections: {},
      statusOrder: "",
      orderTerms: "",
      totalPages: 2,
      OriginData: null,
      Origin: {
        id: 0,
        orderPriceId: 0,
        address: null,
        city: null,
        state: null,
        country: null,
        coordinates: null,
        port: {}
      },
      Destination: {
        id: 0,
        orderPriceId: 0,
        address: null,
        city: null,
        state: null,
        country: null,
        coordinates: null,
        port: {}
      },
      notes: null,
      Quotations: [],
      NewElement: null,
      statedialog: null,
      isLoading: false,
      test: null,
      tabs: 1,
      dialogslot: 0,
      dialogActive: false,
      dialogComments: false,
      selectedItem: 0,
      route: null,
      Itemid: null,
      active: false,
      editQuote: false,
      OrderStatus: [
        {
          id: 1,
          name: 'Cotización Pendiente'
        },
        {
          id: 2,
          name: 'Pendiente Pricing'
        },
        {
          id: 3,
          name: 'Solicitado a Proveedor'
        },
        {
          id: 4,
          name: 'Enviado a Ventas'
        },
        {
          id: 5,
          name: 'Cotizacion Incompleta'
        },
        {
          id: 6,
          name: 'Enviada a cliente'
        },
        {
          id: 7,
          name: 'Renegociación'
        },
        {
          id: 8,
          name: 'Vendida'
        },
        {
          id: 9,
          name: 'Cancelada'
        },
        {
          id: 10,
          name: 'Rechazada'
        }
      ],
      pricerComment: false,
      counter: 0,
      successPrice: false,
      ResumeTotal: 0,
      options: {
        page: 1,
        itemsPerPage: 20,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
      },
      dense: true,
      counterElement: 0,
      Resume: [],
      newElement: [],
      inputs: [{
        name: '',
        rate: '',
        quantity: '',
        total: ''
      }],
      tab: 1,
      originAddress: "",
      DataOrder: [],
      destinationAddress: "",
      dialog: false,
      id: null,
      QuantityStatus: 0,
      proceedItemData: [],
      headers: [
        {
          text: 'Codigo',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {text: 'Fecha', value: 'departureDate'},
        {text: 'Cliente', value: 'client.name'},
        {text: 'Responsable', value: 'salesManager'},
        {text: 'Recibida', value: 'createdAt'},
        {text: 'Material Peligroso', value: 'urgent'},
        {text: 'Urgente', value: 'urgent'},
        {text: 'PDF Cotizacion', value: 'document'},
        {text: 'Proceder', value: 'action'},
        {text: '', value: 'data-table-expand'},
      ],
      Data: [],
      Email: {
        suppliers: [
          {
            id: 7,
            contact: [2]
          }
        ],
        serviceId: 1,
        subject: null,
        message: null
      },
      testdialog: false,
      search: '',
      expanded: [],
      singleExpand: false,
      expandOrder: true,
    }
  },
  methods: {


    updateOrderCount() {


      this.$http.get('Orders/count/pricer').then(response => {
        if (response.data.code == 200) {
          this.SentQty = response.data.data.answered
          this.AssignedQty = response.data.data.assigned
          this.AnsweredQty = response.data.data.requested
          this.sold = response.data.data.sold
          this.shippingConfirmation = response.data.data.shippingConfirmation
          this.shippingConfirmed = response.data.data.shippingConfirmed
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    updateAndCloseDialog(order) {
      this.UpdateOrderToList(order)
      this.dialog = false
      this.updateOrderCount()
      this.GetDataOrder();
    },

    SendNotes: function () {
      this.$http.put(`Orders/${this.id}/terms`, `"${this.DataOrder.terms}\n"`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(data => {
        if (data.data.code === 200) {
          console.log('ok');
        } else {
          console.error(data)
          this.$toast.error('Ocurrio un error al generar las notas');
        }
      }).catch(error => {
        console.error(error)
        //allert('Error')
      })
    },


    OrderUpdated() {
      this.detailKey += 1;
      this.editKey += 1;
      this.dialog = false;
    },


    requestSupplier() {
      this.setOrderRequestedSupplier().then(({data}) => {
        if (data.code === 200) {
          this.$toast.info('Actualizado correctamente')
          this.$refs.detail.GetDataOrder();
          this.UpdateOrderToList(data.data)
          this.updateOrderBy(this.id)
          this.updateOrderCount()
        } else {
          console.error(data)
          this.$toast.error('Hubo un error, comunicese con el administrador del sistema')
        }
      }).catch((error) => {
        console.error(error)
        this.$toast.error('Hubo un error, comunicese con el administrador del sistema')
      })
    },

    infiniteScrolling(intersect) {
      if (intersect[0].isIntersecting && this.Data.length > 15) {
        if (!this.isLoading) {
          this.isLoading = true;
          this.options.page += 1;
          this.GetData(this.options.page);
        }
      }
    },
    filter2() {
      console.log('filter')
    },
    filterOrders() {

      this.filterBy = {}

      if (this.filterCustomer) {
        this.filterBy.Client = this.filterCustomer;
      }

      if (this.filterSalesman) {
        this.filterBy.SalesManager = this.filterSalesman;
      }

      if (this.filterPricer) {
        // //allert(this.filterPricer)
        this.filterBy.Pricing = this.filterPricer;
      }

      if (this.filterStatus) {
        this.filterBy.Status = this.filterStatus;
      }

      this.$store.dispatch('working', true);

      this.$http.get('Orders/Find', {params: this.filterBy}).then(response => {
        console.log(response.data.data)
        this.Data = [];

        response.data.data.forEach((item) => {
          this.formatFolio(item);
          this.Data.push(item)
        });

      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })


    },
    getCount() {
      this.$http.get("Shipping/count").then((response) => {
        console.log(response);
        this.counts = response.data.data;
      });
    },

    filterByStatus(statusId) {

      this.filterBy = {}
      this.filterBy.Status = statusId;
      this.$store.dispatch('working', true);
      this.$http.get('Orders/Find', {params: this.filterBy}).then(response => {
        console.log(response.data.data)
        this.Data = [];
        response.data.data.forEach((item) => {
          this.formatFolio(item);
          this.Data.push(item)
        });

        this.Data.forEach((element, index) => {
          if (element.Notifications > 0) {
            this.Data.splice(index, 1);
            this.Data.splice(0, 0, element);
          }
        })


      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })


    },


    setOrderRequestedSupplier() {
      return this.$http.put(`Orders/${this.id}/requested`)
    },
    RedirectPrices(item) {
      this.Itemid = item.Id;
      let index = this.Data.findIndex(element => element.Id == item.Id);
      this.Data[index].Notifications = 0;
      if (this.priceLength > 0 && this.statusOrder == "Pendiente Pricing") {
        this.dialog = true;
        this.dialogslot = 7;

      } else {
        this.Redirect();
      }
    },

    getCustomers: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Companies')
          .then(response => {
            console.log(response)
            this.Customers = response.data.data;
          })
          .catch(error => {
            console.log(error)


          }).finally(() => this.$store.dispatch('working', false))
    },

    getSalesManager: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Salesmanager')
          .then(response => {

            this.SalesManagers = response.data.data;

          })
          .catch(error => {

            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },

    getPricer: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Pricing')
          .then(response => {
            this.Employees = response.data.data;
            console.log(this.Employees)
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },

    Redirect() {
      console.log('ok')
      this.dialog = false;
      this.id = this.Itemid
      this.$router.push(`/ProceedQuote/${this.id}`)


    },
    startConfeti() {
      this.$refs.confeti.start({
        particles: [
          {
            type: 'heart',
          }
        ]
      });
    },


    AddQuotation: function () {
      this.counter++;
      this.Quotations.push(
          {
            id: this.counter,
            orderId: this.id,
            profit: 0,
            suggested: true,
            discount: 0,
            discountPercentage: 0,
            profitPercentage: 0,
            quotationDetails: [
              {
                quotationId: 0,
                orderPriceId: null,
                isVisible: true,
                profit: 0,
                discount: 0,
                discountPercentage: 0,
                profitPercentage: 0
              },
            ]
          }
      )
    },
    closeDialog: function () {
      this.dialog = false
    },

    ShowNewComment: function () {
      this.dialog = true;
      this.dialogslot = 2;
    },
    updateOriginItems(items) {
      this.originItems = items;
    },
    updateDestinationItems(items) {
      this.destinationItems = items;
    },
    ShowCancelationDialog: function () {
      this.dialog = true;
      this.dialogslot = 4;
    },
    emitFees: function () {
      this.$refs.fees[0].addElement();
    },
    ShowEditQuote: function () {
      this.dialog = true;
      this.dialogslot = 1;
    },
    ShowNotes: function () {
      this.dialog = true;
      this.dialogslot = 5;
    },
    ShowPricerComent: function () {
      this.dialog = true;
      this.dialogslot = 3;
    },
    ShowOrder: function () {
      this.expandOrder = true
    },
    HideOrder: function (value) {
      this.expandOrder = value
    },
    updateOriginAddress(text) {
      this.originAddress = text
    },
    updateDestinationAddress(text) {
      this.destinationAddress = text
    },
    originSelected(selectedItem) {
      console.log('Change')
      this.originAddress = selectedItem.address

      this.originSelections = selectedItem;
      console.log(this.originSelections)

      this.$nextTick(() => {
        this.Origin.city = this.originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
        this.Origin.state = this.originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.Origin.country = this.originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.Origin.coordinates = `${this.originSelections.details.location.lat},${this.originSelections.details.location.lng}`;
        let index = this.originSelections.ports.findIndex(e => e.selected);
        console.log(index)

        this.Origin.address = this.originSelections.description;
        if (index !== -1) {
          this.Origin.port.name = this.originSelections.ports[index].name;
          this.Origin.port.city = this.originSelections.ports[index].city;
          this.originSelections.ports[index].coordinates[0] = parseInt(this.originSelections.ports[index].coordinates[0]);
          this.originSelections.ports[index].coordinates[1] = parseInt(this.originSelections.ports[index].coordinates[1]);
          this.Origin.port.coordinates = `${this.originSelections.ports[index].coordinates[0]},${this.originSelections.ports[index].coordinates[1]}`;
          this.Origin.port.country = this.originSelections.ports[index].country;
        }

      })


    },
    destinationSelected: function (selections) {
      this.destinationSelections = selections;

      this.$nextTick(() => {
        this.Destination.city = this.destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
        this.Destination.state = this.destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.Destination.country = this.destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.Origin.coordinates = `${this.originSelections.details.location.lat},${this.originSelections.details.location.lng}`;
        this.Destination.address = this.destinationSelections.description;
        let index = this.destinationSelections.ports.findIndex(e => e.selected);

        if (index !== -1) {
          this.Destination.port.name = this.destinationSelections.ports[index].name;
          this.Destination.port.city = this.destinationSelections.ports[index].city;
          this.destinationSelections.ports[index].coordinates[0] = parseInt(this.destinationSelections.ports[index].coordinates[0]);
          this.destinationSelections.ports[index].coordinates[1] = parseInt(this.destinationSelections.ports[index].coordinates[1]);
          this.Destination.port.coordinates = `${this.destinationSelections.ports[index].coordinates[0]},${this.destinationSelections.ports[index].coordinates[1]}`;
          this.Destination.port.country = this.destinationSelections.ports[index].country
        }

        // //allert(index)
      })
    },


    filterByData: debouncePromise(function () {
      this.Data = [];
      // this.options.page = 1;
      this.GetData();
    }, 300),

    GetData: function (page = 1) {
      if (this.cardStatus === 7) {
        this.$http.get('Orders/confirmed').then((response) => {
          this.infiniteScrollProps.page = page;
          this.infiniteScrollProps.lastPage = response.data.meta.totalPages;
          let res = response.data.data
          res = res.map((element) => {
            return {
              ...element,
              TransportationCategory: element.transportationCategory,
              TransportationCategoryId: element.transportationCategoryId,
              Origin: element.origin,
              Destination: element.destination,
              RefCode: element.refCode,
              SalesManager: element.salesManager,
              StatusOrder: 'Confirmadas',
              Notification: element.notification,
              CreatedAt: element.createdAt,
              Client: element.client,
              Id: element.id,
            }
          })
         // this.statusOrder = 'Confirmadas'
          this.Data = []
          this.infiniteScrollProps.items = []

          this.elEstatus = 'Confirmadas'
          for (let item of res) {
            this.formatFolio(item);

            this.Data.push(item)
          }
         this.infiniteScrollProps.items = this.Data;

        })
        return
      }
      if (this.cardStatus === 8) {
       this.$http.get('Orders/confirmation').then((response) => {
         this.infiniteScrollProps.page = page;
         this.infiniteScrollProps.lastPage = response.data.meta.totalPages;
         let res = response.data.data
         res = res.map((element) => {
           return {
             ...element,
             TransportationCategory: element.transportationCategory,
             TransportationCategoryId: element.transportationCategoryId,
             Origin: element.origin,
             Destination: element.destination,
             RefCode: element.refCode,
             SalesManager: element.salesManager,
             StatusOrder: 'Por Confirmar',
             Notification: element.notification,
             CreatedAt: element.createdAt,
             Client: element.client,
             Id: element.id,
           }
         })
         this.elEstatus = 'Por Confirmar'
         this.Data = []
         this.infiniteScrollProps.items = []
         // this.statusOrder = 'Por Confirmar'
         for (let item of res) {
           this.formatFolio(item);

           this.Data.push(item)
         }
         this.infiniteScrollProps.items = this.Data;

       })
        return
      }
      if (page <= this.totalPages){
        // F ya estaba :(
        // let requestUrl = `Orders/Find?PageNumber=${page}&PageSize=${this.options.itemsPerPage}&Refcode=${this.search}&Pricing=${this.filterPricer ?? ''}&SalesManager=${this.filterSalesman ?? ''}&Client=${this.filterCustomer ?? ''}`
        // if (this.cardStatus === 2) {
        //   requestUrl += `&Status=2&Status=7`
        // }
        // if (this.cardStatus === 3) {
        //   requestUrl += `&Status=3`
        // }
        // if (this.cardStatus === 6) {
        //   requestUrl += `&Status=6&Status=8&Status=4&Status=9`
        // }
        let status = '';
        if (this.cardStatus === 2) {
          status = `2,7`
        }
        if (this.cardStatus === 3) {
          status = `3`
        }
        if (this.cardStatus === 4) {
          status = `6,8,4,9`
        }
/*        if (this.cardStatus === 8) {
          status = `8`
        }*/

        this.$http.get('Orders/Find',{
          params: {
            PageSize: this.options.itemsPerPage,
            PageNumber: page,
            Refcode:this.search,
            Status: status,
            Pricing:this.filterPricer,
            SalesManager:this.filterSalesman,
            Client:this.filterCustomer
          }
        }).then(response => {
          this.elEstatus =  null
          if (response.data.code == 200) {
            let res = response.data.data;
            this.totalPages = response.data.meta.totalPages

            if (this.$store.getters.roles[0] == 'Ventas' && !res.assignToPricer)
              res = res.filter(element => element.Id == this.id);

            this.Data = []
            this.infiniteScrollProps.items = []

            for (let item of res) {
              this.formatFolio(item);

              this.Data.push(item)
            }

            this.$nextTick(() => {
              this.isLoading = false;
            })





            this.infiniteScrollProps.page = page;

            this.infiniteScrollProps.lastPage = response.data.meta.totalPages;

            this.infiniteScrollProps.items = this.Data;




          }


          // this.orderByNotifications();


          if (response.data.code == 400 || response.data.code == 500)
            this.$toast.error("Algo salio mal, intente mas tarde")

        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.$store.dispatch('working', false);
        })
        this.active = 0;
      }


    },

    orderByNotifications() {
      this.Data.sort((a, b) => {
        const diff = b.Notifications - a.Notifications;
        switch (diff) {
          case 0:
            return -1;
          default:
            return diff;
        }
      });


      //console.log(this.Data.sort((a, b) => (b.Notifications) - (a.Notifications)))
    },
    selectListItem: function () {
      let index = this.Data.findIndex(e => e.id == this.id);
      this.selectedItem = index;
    },
    GetDataOrder: function () {
      this.$emit('idOrder', this.id);

      console.log('Hello')

      this.$store.dispatch('working', true)

      this.$http.put(`Notification/${this.id}/readAll`).then(() => {
        this.$store.dispatch("updateNotifications", true);
      })

      this.$http.get(`Orders/${this.id}`).then(response => {
        // console.log(response)
        if (response.data.code === 400) {
          this.$router.replace({
            name: "Pricing"
          })
        }
        this.DataOrder = response.data.data;

        if (this.DataOrder.terms == null || this.DataOrder.terms == "undefined") {
          this.DataOrder.terms =
              "• No incluye despacho aduanal/impuestos (impo).\n" +
              "• Excluye maniobras en caso de rojo, inspección y/o previo físico. \n" +
              "• No incluye seguro de mercancía, en caso de requerirlo favor de solicitarlo al ejecutivo de su cuenta. \n" +
              "• El seguro de transporte será cubierto únicamente por indicaciones expresas del cliente. \n" +
              "• Tarifas sujetas a cambio con o sin previo aviso de acuerdo con mercado internacional. \n" +
              "• Los tiempos de tránsito y frecuencias corresponden a un servicio de línea regular, sin embargo, este puede cambiar por causas de fuerza mayor sin previo aviso. \n" +
              "• El servicio está sujeto a disponibilidad de espacio y al cumplimiento de todas las restricciones de peso aplicables. \n" +
              "• El costo de la cotización puede variar, en base a la verificación de medidas y peso. \n" +
              "• No incluye cobros por almacenajes ni demoras. \n" +
              "• Las maniobras de carga y descarga en punto de origen y punto final son va por la parte del cliente y/o proveedor \n" +
              "• Tarifa válida para dimensiones y pesos proporcionados por el cliente \n" +
              "• No incluye impuesto TAX aeroportuario del 4%/kg sobre peso real o cargable el que sea mayor \n"
        }
        if (this.elEstatus === 'Confirmadas') {
          this.statusOrder = 'Confirmada'
          this.DataOrder.statusOrder = 'Confirmada'
        } else if(this.elEstatus === 'Por Confirmar') {
          this.statusOrder = 'Por Confirmar'
          this.DataOrder.statusOrder = 'Por Confirmar'
        } else if(this.elEstatus === null){
          this.statusOrder = this.DataOrder.statusOrder
        }
        console.log('aqui', this.DataOrder.statusOrder)
        this.MapOrderToList(this.DataOrder);

      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    CloseDialog: function () {
      this.dialog = false;
      this.dialogActive = this.dialog;
    },
    RecibeData: function (data) {

      console.log(data);


      this.Email.suppliers.push(
          {
            id: data[0].id,
            contact: data[0].idContact
          }
      )

      console.log(this.Email);

    },
    SendSupplierEmail: function () {

      this.$store.dispatch('working', true)
      this.$http.post(`Orders/${this.id}/notify/suppliers`, this.Email).then(response => {
        if (response.data.code == 200) {
          console.log(response);
          this.$toast.success("Correo Enviado");
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false)
      })
    },
    addElement() {

      this.counterElement = this.counterElement + 1;

      this.tabs.push({
        id: this.counterElement,
      })

    },
    GetQuotations() {
      this.$http.get(`Quotation/Order/${this.id}`).then(response => {
        if (response.data.code == 200) {
          if (response.data.data.length > 0) {
            this.Quotations = response.data.data
          }
          if (response.data.data.length <= 0)
            this.AddQuotation();


          console.log(this.Data.length)
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    removeElement(id) {
      //this.counterElement--;
      let index = this.Quotations.findIndex(e => e.id === id);
      this.Quotations.splice(index, 1);
    },
    UpdateFees() {
      console.log(this.$refs)
      this.$refs.fees.UpdateFees();
    },
    updateOrderBy(id) {
      let index = this.Data.findIndex(element => element.Id == id);
      var element = this.Data[index];
      this.Data.splice(index, 1);
      this.Data.splice(0, 0, element);
      console.log(this.Data);
    },
    SendPrices() {
      this.startConfeti();
      this.SendNotes();

      setTimeout(() => {
        this.$store.dispatch("working", true);
        this.$http.post(`Orders/${this.id}/send/sales`).then(({data}) => {
          if (data.code === 200) {
            this.$toast.info('Enviado a ventas éxitosamente')
            this.updateOrderBy(this.id);
            this.dialog = false;
            this.GetDataOrder();
            this.updateOrderCount()

            if (!this.DataOrder.assignToPricer)
              this.$router.push(`/ProceedQuoteSales/${this.id}`);
            else
              this.$router.push(`/ProceedQuote/${this.Data[0].Id}`);

            this.updateOrderStatus(this.id);


          } else {
            this.$toast.error('Hubo un error')
          }
        }).finally(() => {
          this.$store.dispatch("working", true);
        })

        // if(this.expandOrder === false){
        //   let redirectIndexAndId = 0
        //
        //   this.Data.length - 1 !== this.selectedItem ? redirectIndexAndId = this.selectedItem + 1 : redirectIndexAndId = 0
        //   this.id = this.Data[redirectIndexAndId].id
        //   this.$route.params.id = this.Data[redirectIndexAndId].id
        //   this.selectedItem = redirectIndexAndId
        // }
      }, 500)


    },
    sendToRequest() {
      this.$http.post(`Orders/${this.id}/send/sales`).then(({data}) => {
        if (data.code === 200) {
          this.$toast.info('Se envio a solicitar')
          this.dialog = false;
          this.GetDataOrder();
        } else {
          this.$toast.error('Hubo un error')
        }
      })

    },
    updateOrderStatus(orderId) {
      let index = this.Data.findIndex(element => element.Id == orderId);
      this.Data[index].StatusOrder = 'Enviado a Ventas';
      this.Data.forEach(function (item, i) {
        if (item.Id == this.Data[index].Id) {
          this.Data.splice(i, 1);
          this.Data.unshift(item);
        }
        console.log(this.Data)
      });
    },
    GetSignalData(data) {
      console.log(data)
    },
    AssignOrderToList(obj) {
      console.log("AssignToList", obj)
      let index = this.Data.findIndex(x => x.Id == obj.id)
      obj.notifications = 1;
      let order = this.MapOrderToList(obj)

      if (index !== -1) {
        this.Data.splice(index, 1)
      }
      this.updateOrderCount();
      this.Data.splice(0, 0, order)
    },
    UpdateOrderToList(obj) {
      console.log(obj)
      let index = this.Data.findIndex(x => x.Id == obj.id)
      console.log(index)

      if (index !== -1) {
        let order = this.MapOrderToList(obj)

        console.log(this.Data[index])

        this.Data[index] = order;

      }
    },
    mapOrder(order) {
      return {
        Id: order.id,
        TransportationId: order.transportationId,
        CommentsToPricer: order.commentsToPricer,
        TransportationCategoryId: order.transportationCategoryId,
        IncotermId: order.incotermId,
        OrderTypeId: order.orderTypeId,
        LandTransfer: order.landTransfer,
        LandTransportation: order.landTransportation,
        LandDedicated: order.landDedicated,
        LandFull: order.landFull,
        Terms: order.terms,
        Origin: order.origin,
        Destination: order.destination,
        StatusOrder: order.statusOrder,
        Pol: order.pol,
        Pod: order.pod,
        DepartureDate: order.departureDate,
        IndustryId: order.industryId,
        Budget: order.budget,
        Commodity: order.commodity,
        InsideBillOfLanding: order.insideBillOfLanding,
        Cargo: order.cargo,
        Services: order.services,
        ClientId: order.clientId,
        PricerId: order.pricerId,
        SalesManagerId: order.salesManagerId,
        Pricer: order.pricer,
        SalesManager: order.salesManager,
        Client: order.client,
        OrderType: order.orderType,
        Currency: order.currency,
        Incoterm: order.incoterm,
        Transportation: order.transportation,
        TransportationCategory: order.transportationCategory,
        Industry: order.industry,
        Frequency: order.frequency,
        Urgent: order.urgent,
        Comments: order.comments,
        CreatedAt: order.createdAt,
        Notifications: order.notifications
      }
    },

    MapOrderToList(obj) {
      let order = this.mapOrder(obj)

      this.formatFolio(order);

      this.DataOrder.folio = order.folio;

      this.DataOrder.cargo.forEach(element => {
        if (element.type == "Container") {
          if (element.weight <= element.container.maximumWeight) {
            element.legal = true;
          } else
            element.legal = false;
        }
      })

      return order
    },

    testHub() {
    }

  },
  created() {
    console.log(this.$hub)
  },
  mounted() {
    this.getCount();
    this.detailKey += 1;
    this.editKey += 1;
    this.id = this.$route.params.id;
    this.id = parseInt(this.id)
    this.GetDataOrder();
    this.$emit('id', this.id);
   // this.GetData();
    this.getPricer();
    this.getCustomers();
    this.getSalesManager();
    this.GetQuotations();
    this.updateOrderCount();

    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('html').style.overflow = 'hidden';

    this.$hub.Connection.on("OrderCreated", (obj) => {
      this.AssignOrderToList(obj)
    })
    this.$hub.Connection.on("OrderRenegotiationStatus", (obj) => {
      this.AssignOrderToList(obj)
    })
    this.$hub.Connection.on("OrderSold", (obj) => {
      this.AssignOrderToList(obj)
    })


  },
  beforeDestroy() {
    document.querySelector('body').style.overflow = 'auto';
    document.querySelector('html').style.overflow = 'auto';

    this.$hub.Connection.off("OrderCreated", this.AssignOrderToList)
    this.$hub.Connection.off("OrderRenegotiationStatus", this.AssignOrderToList)
    this.$hub.Connection.off("OrderSold", this.AssignOrderToList)
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.id = parseInt(this.id)
  },
  destroyed() {
    console.log(this.$el + ' is destroyed')
  },
}
</script>

<style scoped>
.fixed-col-size {
  min-width: 250px;
}

.position-relative {
  position: relative;
}

.order-search-section {
  max-height: 90vh;
  overflow-y: scroll;
}

.prices-section {
  max-height: 50vh;
  overflow-y: scroll;
}

>>> .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
</style>
