<template>
  <!-- TODO
Falta consumir servicios de land service y mapear al select
Mapear landTransfer al checbox que se activa con terrestre/ftl-->
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- // ruta -->
      <v-card tile elevation="1" class="mt-5 ml-3" max-width="1200">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
                expand-icon=""
                class="headline primary font-weight-regular white--text"
            >
              <v-row>
                <v-col md="4" class="pa-0">
                  <v-icon color="white" size="30" left
                  >las la-paper-plane
                  </v-icon
                  >
                  Ruta
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row justify="center" class="mt-3">
                  <v-col md="3">
                    <!-- // ruta_transportation -->
                    <v-select
                        tile
                        dense
                        outlined
                        :items="itemsRuta"
                        item-text="name"
                        item-value="id"
                        v-model="orderCreation.transportationId"
                        label="Transporte"
                        @change="changeServices()"
                        :rules="selectRule"
                        required
                    ></v-select>
                  </v-col>
                  <!-- // ruta_services -->
                  <v-col md="3">
                    <v-select
                        tile
                        dense
                        outlined
                        :disabled="servideDisable"
                        :items="itemsServices"
                        item-text="description"
                        item-value="id"
                        v-model="orderCreation.transportationCategoryId"
                        label="Servicio"
                        @change="showHide()"
                        :rules="selectRule"
                        required
                    ></v-select>
                  </v-col>
                  <!-- // ruta_type -->
                  <v-col md="3">
                    <v-select
                        dense
                        outlined
                        :items="itemsType"
                        @change="changeIncoterm()"
                        item-text="description"
                        item-value="id"
                        v-model="orderCreation.orderTypeId"
                        label="Tipo"
                        :rules="selectRule"
                        required
                    ></v-select>
                  </v-col>
                  <!-- // ruta_incoterm -->
                  <v-col md="3" v-if="this.orderCreation.transportationId == 2 || this.orderCreation.transportationId == 1 || this.orderCreation.transportationId == null">
                    <v-select
                        dense
                        :disabled="incotermDisable"
                        outlined
                        :items="itemsIncoterm2"
                        item-text="code"
                        item-value="id"
                        v-model="orderCreation.incotermId"
                        label="Incoterm"
                        :rules="selectRule"
                        required
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row class="mt-3">
                  <!-- // ruta_fecha -->
                  <v-col md="3">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="orderCreation.departureDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="orderCreation.departureDate"
                            label="Fecha de salida"
                            v-bind="attrs"
                            dense
                            outlined
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="orderCreation.departureDate"
                          no-title
                          scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(orderCreation.departureDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <!--// ruta_tipo2 -->
                  <v-col md="3">
                    <v-select
                        v-model="orderCreation.deliveryTypeId"
                        :items="searchFilter"
                        item-text="description"
                        item-value="id"
                        v-if="orderCreation.transportationId == 1"
                        tile
                        dense
                        outlined
                        label="Tipo"
                        :rules="selectRule"
                        required
                    ></v-select>

                    <v-select
                        v-model="orderCreation.deliveryTypeId"
                        :items="searchFilterAereo"
                        item-text="description"
                        item-value="id"
                        v-if="orderCreation.transportationId == 2"
                        tile
                        dense
                        outlined
                        label="Tipo"
                        :rules="selectRule"
                        required
                    ></v-select>
                  </v-col>
                </v-row>

                <div v-if="orderCreation.transportationId == 1">
                  <v-row
                      v-if="orderCreation.deliveryTypeId == 4"
                      class="row no-gutters position-relative"
                  >
                    <v-col cols="6">
                      <google_port
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="1"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_port>
                    </v-col>
                    <v-col cols="6">
                      <google_port
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="2"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </google_port>
                    </v-col>
                  </v-row>
                  <v-row
                      class="row no-gutters position-relative"
                      v-if="orderCreation.deliveryTypeId == 2"
                  >
                    <v-col cols="6">
                      <google_port
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="orderCreation.deliveryTypeId"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_port>
                    </v-col>
                    <v-col cols="6">
                      <google_port
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="orderCreation.deliveryTypeId"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </google_port>
                    </v-col>
                  </v-row>
                  <v-row
                      v-if="orderCreation.deliveryTypeId == 3"
                      class="row no-gutters position-relative"
                  >
                    <v-col cols="6">
                      <google_port
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="2"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_port>
                    </v-col>
                    <v-col cols="6">
                      <google_port
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="1"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </google_port>
                    </v-col>
                  </v-row>
                  <v-row
                      class="row no-gutters position-relative"
                      v-if="orderCreation.deliveryTypeId == 1"
                  >
                    <v-col cols="6">
                      <google_port
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="1"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_port>
                    </v-col>
                    <v-col cols="6">
                      <google_port
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="1"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </google_port>
                    </v-col>
                  </v-row>
                </div>

                <div v-else-if="orderCreation.transportationId == 2">
                  <v-row
                      class="row no-gutters position-relative"
                      v-if="orderCreation.deliveryTypeId == 4"
                  >
                    <v-col cols="6">
                      <google_airport
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="1"
                          transportation="AIR"
                          placeholder="Ingrese un origen"
                          @change="originSelectedAereo"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_airport>
                    </v-col>
                      <v-col cols="6">
                        <google_airport
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="2"
                            transportation="AIR"
                            placeholder="Ingrese un destino"
                            @change="destinationSelectedAereo"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            :id="4"
                            dense
                        >
                        </google_airport>
                      </v-col>
                  </v-row>
                  <v-row
                      v-if="orderCreation.deliveryTypeId == 2"
                      class="row no-gutters position-relative"
                  >
                    <v-col cols="6">
                      <google_airport
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="2"
                          transportation="AIR"
                          placeholder="Ingrese un origen"
                          @change="originSelectedAereo"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_airport>
                    </v-col>
                    <v-col cols="6">
                      <google_airport
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="2"
                          transportation="AIR"
                          placeholder="Ingrese un destino"
                          @change="destinationSelectedAereo"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </google_airport>
                    </v-col>
                  </v-row>
                  <v-row
                      v-if="orderCreation.deliveryTypeId == 3"
                      class="row no-gutters position-relative"
                  >
                    <v-col cols="6">
                      <google_airport
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="2"
                          transportation="AIR"
                          placeholder="Ingrese un origen"
                          @change="originSelectedAereo"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_airport>
                    </v-col>
                    <v-col cols="6">
                      <google_airport
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="1"
                          transportation="AIR"
                          placeholder="Ingrese un destino"
                          @change="destinationSelectedAereo"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </google_airport>
                    </v-col>
                  </v-row>
                  <v-row
                      v-if="orderCreation.deliveryTypeId == 1"
                      class="row no-gutters position-relative"
                  >
                    <v-col cols="6">
                      <google_airport
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          :filterRoute="1"
                          transportation="AIR"
                          placeholder="Ingrese un origen"
                          @change="originSelectedAereo"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                          dense
                      >
                      </google_airport>
                    </v-col>
                    <v-col cols="6">
                      <google_airport
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="1"
                          transportation="AIR"
                          placeholder="Ingrese un destino"
                          @change="destinationSelectedAereo"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </google_airport>
                    </v-col>
                  </v-row>
                </div>

                <div v-else-if="orderCreation.transportationId == 3">
                  <v-row
                      v-if="orderCreation.deliveryTypeId == null"
                      class="row no-gutters position-relative"
                  >
                    <v-col cols="6">
                      <SLandAutocomplete
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          @change="originSelectedTerrestre"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          placeholder="Ingrese un Origen"
                          transportation="SEA"
                          :id="1"
                          dense
                      >
                      </SLandAutocomplete>
                    </v-col>
                    <v-col cols="6">
                      <SLandAutocomplete
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          :filterRoute="4"
                          transportation="AIR"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                          dense
                      >
                      </SLandAutocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <!-- // envio -->
      <v-card tile elevation="1" class="mt-5 ml-3" max-width="1200">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
                expand-icon=""
                class="headline primary font-weight-regular white--text"
            >
              <v-row>
                <v-col md="4" class="pa-0">
                  <v-icon color="white" size="30" left>las la-box</v-icon>
                  Envio
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col md="6">
                    <!-- // envio_industria -->
                    <v-select
                        dense
                        outlined
                        :items="itemsIndustria"
                        item-text="description"
                        item-value="id"
                        v-model="orderCreation.industryId"
                        label="Industria"
                        :rules="selectRule"
                        required
                    ></v-select>
                    <v-text-field
                        dense
                        outlined
                        label="Commodity"
                        :rules="filRule"
                        required
                        v-model="orderCreation.commodity"
                    ></v-text-field>
                    <v-btn-toggle
                        primary
                        v-model="orderCreation.LandTransportation"
                        v-if="orderCreation.transportationId === 1"
                    >
                      <v-btn value="T">
                        <span class="hidden-sm-and-down">ALL TRUCK</span>
                      </v-btn>

                      <v-btn value="TR">
                        <span class="hidden-sm-and-down">RAIL + TRUCK</span>
                      </v-btn>

                      <v-btn value="R">
                        <span class="hidden-sm-and-down">RAIL</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>

                  <v-col md="6">
                    <!-- // envio_target -->
                    <v-text-field
                        v-if="
                        orderCreation.transportationCategoryId === 1 ||
                          orderCreation.transportationCategoryId === 2 ||
                          orderCreation.transportationCategoryId === 3 ||
                          orderCreation.transportationId === 2 ||
                          orderCreation.transportationCategoryId === 9 ||
                          orderCreation.transportationId === 1 ||
                          orderCreation.transportationId === 3
                      "
                        dense
                        outlined
                        label="Target"
                        v-model="orderCreation.budget"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-if="orderCreation.transportationId === 2"
                        dense
                        outlined
                        label="Costo de mercancia"
                        v-model="orderCreation.GoodsCost"
                    ></v-text-field>
                    <v-select
                        dense
                        outlined
                        v-model="orderCreation.landServiceId"
                        :items="LandService"
                        item-text="Description"
                        item-value="Id"
                        label="Land Service"
                        v-if="
                        orderCreation.transportationId === 3 &&
                          orderCreation.transportationCategoryId !== 2
                      "
                    ></v-select>
                    <v-checkbox
                        label="Transbordo"
                        v-model="orderCreation.LandTransference"
                        v-if="orderCreation.transportationCategoryId === 2"
                    ></v-checkbox>
                    <!-- // envio_dentroFuera -->
                    <v-btn-toggle
                        primary
                        v-model="orderCreation.insideBillOfLanding"
                        v-if="
                        orderCreation.transportationId === 1 &&
                          orderCreation.transportationCategoryId !== 9
                      "
                    >
                      <v-btn :value="true">
                        <span class="hidden-sm-and-down">DENTRO DE BL</span>
                      </v-btn>

                      <v-btn :value="false">
                        <span class="hidden-sm-and-down">FUERA DE BL</span>
                      </v-btn>
                    </v-btn-toggle>
                    <v-checkbox
                        class="pt-4"
                        label="Dedicado"
                        v-if="
                        (orderCreation.transportationId === 1 &&
                          orderCreation.transportationCategoryId === 9) ||
                          (orderCreation.transportationId === 1 &&
                            orderCreation.transportationCategoryId === 12) ||
                          (orderCreation.transportationId === 1 &&
                            orderCreation.transportationCategoryId === 13)
                      "
                        v-model="orderCreation.landDedicated"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>

      <v-card tile elevation="1" class="mt-5 ml-3" max-width="1200">
        <shipmentDetail
            v-on:input="updateData($event)"
            :value="orderCreation"
        />
      </v-card>

      <v-card tile elevation="1" class="mt-5 ml-3" max-width="1200">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
                expand-icon=""
                class="headline primary font-weight-regular white--text"
            >
              <v-row>
                <v-col md="4" class="pa-0">
                  <v-icon color="white" size="30" left>las la-toolbox</v-icon>
                  Servicios
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row class="mt-0">
                  <v-col>
                    <v-row>
                      <v-col
                          md="4"
                          v-for="(servicio, index) in itemServicios"
                          :key="index"
                      >
                        <v-checkbox
                            color="primary"
                            :label="servicio.name"
                            dense
                            @click="addService(servicio.id)"
                        ></v-checkbox>
                      </v-col>
                      <v-col md="4">
                        <v-checkbox
                            dense
                            label="Seguro de carga"
                            @click="seguroCarga = !seguroCarga"
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <div>
                      <v-row>
                        <v-col>
                          <v-text-field
                              type="number"
                              dense
                              label="Costo de mercancía"
                              outlined
                              v-model="orderCreation.GoodCost"
                              v-if="seguroCarga"
                              :rules="filRule"
                              required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <!-- // infoCliente -->
      <v-card tile elevation="1" class="mt-5 ml-3" max-width="1200">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
                expand-icon=""
                class="headline primary font-weight-regular white--text"
            >
              <v-row>
                <v-col md="7" class="pa-0">
                  <v-icon color="white" size="30" left>las la-user</v-icon>
                  Información de Cliente
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-form ref="cliente" lazy-validation>
                  <v-row>
                    <v-col cols="3">
                      <!-- // infoCliente_cliente -->
                      <v-autocomplete
                          label="Cliente"
                          :items="itemsClientes"
                          v-model="orderCreation.clientId"
                          :search-input.sync="clientSearch"
                          item-value="id"
                          item-text="name"
                          :rules="selectRule"
                          required
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="1">
                      <v-dialog width="1000">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon class="mt-1" v-on="on" v-bind="attrs">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="primary"
                                    icon
                                    fab
                                    size="34"
                                    v-bind="attrs"
                                    v-on="on"
                                >mdi-plus-circle
                                </v-icon>
                              </template>
                              <span>Agregar</span>
                            </v-tooltip>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="primary white--text">
                            <v-icon color="white" left
                            >mdi-table-edit
                            </v-icon
                            >
                            Añadir Cliente
                          </v-card-title>
                          <v-card-text>
                            <Customers v-on:update="getClientes()"/>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                          :items="business"
                          label="Empresa"
                          v-model="orderCreation.businessId"
                          item-text="name"
                          item-value="id"
                          :rules="selectRule"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                          :items="itemsSuppliers"
                          item-text="companyName"
                          item-value="id"
                          v-model="orderCreation.purchase.details[0].supplierId"
                          label="Provedor"
                          :rules="selectRule"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                        label="Vendedor"
                        :items="itemsVendedores"
                        :item-text="item => item.firstName +' '+ item.lastName"
                        item-value="id"
                        v-model="orderCreation.salesManagerId"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                    label="Pricer"
                    :items="itemsPricer"
                    :item-text="item => item.firstName +' '+ item.lastName"
                    item-value="id"
                    v-model="orderCreation.pricerId"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-row class="mt-2 mb-3" style="justify-content: center">
        <v-col cols="3">
          <v-btn color="primary" @click="sendData">Crear Embarque</v-btn>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import shipmentDetail from "./shipmentDetail_orderCreation.vue";
import google_port from "@/components/google-maps-autocomplete-vuetify/index_new";
import google_airport from "@/components/google-maps-autocomplete-vuetify/SAirportAutocomplete";
import SLandAutocomplete from "@/components/google-maps-autocomplete-vuetify/SLandAutocomplete";
import Customers from "@/components/Accounts_Admin/Customers";

import dataBinding from "./mixins/dataBinding";
import debouncePromise from "debounce-promise";

export default {
  name: "OrderCreation",
  components: {shipmentDetail, google_port, google_airport, SLandAutocomplete, Customers},
  mixins: [dataBinding],
  data() {
    return {
      itemsVendedores: [],
      clientSearch: null,
      LandService: [
        {
          Id: 1,
          Description: 'Consolidado',
          Status: 1
        },

        {
          Id: 2,
          Description: 'Dedicado',
          Status: 1
        },

        {
          Id: 3,
          Description: 'Hot Shot',
          Status: 1
        },
      ],
      itemsSuppliers: [],
      business: [],
      filRule: [(v) => (v && v.length > 0) || "Porfavor llene el campo"],
      selectRule: [v => !!v || 'Porfavor selecciona una opcion'],
      seguroCarga: false,
      itemServicios: {},
      valueMixin: 9,
      incoterm1: [1, 2, 5, 6, 7, 10, 11],
      truckRail: true,
      mercancia: false,
      dialog: false,
      icon: null,
      originSelections: {},
      origin: {},
      originItems: [],
      destinationSelections: {},
      destination: {},
      destinationAddress: "",
      destinationItems: [],
      deliveryTypeId: null,
      originAddress: "",
      searchFilter: [
        {
          id: 4,
          description: "Puerta a puerto",
        },
        {
          id: 2,
          description: "Puerto a puerto",
        },
        {
          id: 3,
          description: "Puerto a puerta",
        },
        {
          id: 1,
          description: "Puerta a puerta",
        },
      ],
      searchFilterAereo: [
        {
          id: 4,
          description: "Puerta a aeropuerto",
        },
        {
          id: 2,
          description: "Aeropuerto a aeropuerto",
        },
        {
          id: 3,
          description: "Aeropuerto a puerta",
        },
        {
          id: 1,
          description: "Puerta a puerta",
        },
      ],
      notifications: false,
      sound: true,
      widgets: false,
      // ruta_transportation
      itemsRuta: null,
      // ruta_services
      itemsServices: null,
      // ruta_type
      itemsType: null,
      // ruta_incoterm
      itemsIncoterm: null,
      itemsIncoterm2: [],
      // ruta_fecha
      menu: false,
      // envio_industria
      itemsIndustria: null,
      // envio_dentroFuera
      dentroFuera: true,
      dedicado: true,
      // infoCliente_cliente
      itemsClientes: null,
      // infoCliente_pricer
      itemsPricer: null,
      itemsAux: null,
      servideDisable: true,
      incotermDisable: true,
      orderCreation: {
        purchase: {
          id: 0,
          shippingId:0,
          totalPurchase:0,
          details: [{
            supplierId: null,
            freightForwarderId: null,
            feesConfirmation: false,
            isEditable: true,
            fees:[{
              id: 0,
              name:"NA",
              status: true,
              purchaseDetailId: 0,
              price: 0,
              total: 0,
              ratableWeight: 1,
              currencyId: 1,
              containerId:null,
              quantity: 1,
              hasRet: false,
              hasVat: false,
            }],
          }]
        },
        sale:{
          id:0,
          shippingId:0,
          totalSale:0,
          details:[{
            id:0,
            status: true,
            shippingSaleId:0,
            name: "NA",
            position:0,
            profit:0,
            profitPercentage:0,
            discount: 0,
            discountPercentage: 0,
            fees:[{
              id:0,
              status: true,
              name: "NA",
              salesDetailId: 0,
              profit: 0,
              price: 0,
              total: 0,
              ratableWeight: 0,
              currencyId: 1,
              containerId: 1,
              quantity: 1,
              discount: 0,
              profitPercentage: 0,
              discountPercentage:0,
              hasVat: false,
              hasRet: false,
              purchaseFeeId: 0,
              purchaseFee:{
                id: 0,
                status: true,
                name: "NA",
                purchaseDetailId: 0,
                price: 0,
                total: 0,
                ratableWeight: 1,
                currencyId: 1,
                containerId: 1,
                quantity: 1,
                hasRet: false,
                hasVat: false,
              }
            }]
          }]

        },
        //Vendedor
        salesManagerId: null,
        // ruta_services
        transportationId: null,
        // ruta_transportation
        transportationCategoryId: null,
        // ruta_type
        orderTypeId: null,
        // ruta_fecha
        departureDate: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substr(0, 10),
        // envio_industria
        industryId: 0,
        // envio_target
        budget: "",
        GoodCost: null,
        pricerId: null,
        businessId: null,
        master: {
          shipper: {
            supplierId: null,
            forwarderId: null
          },

          consignee: {
            supplierId: null,
            forwarderId: null,
          },
          houseBls:[

          ]
        },
        LandTransportation: "T",
        landDedicated: false,
        LandTransference: false,
        incotermId: null,
        origin: [
          {
            state: null,
            coordinates: null,
            country: null,
            city: null,
            address: null,
          },
        ],
        destination: [
          {
            state: null,
            coordinates: null,
            country: null,
            city: null,
            address: null,
          },
        ],
        pol: {
          name: null,
          city: null,
          coordinates: null,
          country: null,
        },
        pod: {
          name: null,
          city: null,
          coordinates: null,
          country: null,
        },
        commodity: null,
        insideBillOfLanding: true,
        cargo: [
          {
            stackable: false,
            tiltable: false,
            totalWeight: true,
            containerId: null,
            cargoType: "",
            documents: [
              {
                documentTypeId: 16,
                file: null,
                fileExtension: "PDF",
                fileName: "IMO",
              },

              {
                documentTypeId: 16,
                file: null,
                fileExtension: "PDF",
                fileName: "UN",
              },
            ],
            quantity: 1,
            landFull: false,
            weight: 21000,
            dangerous: false,
            large: 0,
            width: 0,
            legal: true,
            height: 0,
            unitMeasure: "Mts",
            unit: "Kg",
            weightUnitMeasure: "Kg",
            modified: true,
            loadingTypeId: null,
          },
        ],
        Services: [],
        // infoCliente_cliente
        clientId: null,
        // infoCliente_frecuenciaMensual
        Frequency: 1,
        urgent: false,
        comments: null,
        documents: [],
      },
    };
  },
  mounted() {
    this.getBuissnes()
    // ruta_transportation
    this.getTransportation();
    // ruta_services
    this.getServices();
    // ruta_type
    this.getTypes();
    // ruta_incoterm
    this.getIncoterms();
    // envio_industria
    this.getIndustrias();
    // infoCliente_cliente
    this.getClientes();
    // infoCliente_pricer
    this.getPricer();
    this.getServices2();
    this.getSuppliers();
    this.getVendedor();
  },
  watch: {
    clientSearch: {
      handler(val){
        const selectedClient = this.itemsClientes.find(x => x.name == val)
        if(selectedClient?.name == val) return
        if(val) this.searchClients(this.clientSearch)
      }
    },
  },
  methods: {
    searchClients: debouncePromise(function (search){
      if (search === '') {
        return
      }
      this.getClients(search)
    }, 300),
    getVendedor() {
      this.$http.get('Employees/Salesmanager').then((response) => {
        console.log(response)
        this.itemsVendedores = response.data.data
      }).catch((error) => {
        console.log(error)
      })
    },
    //Ruta de terrestre
    originSelectedTerrestre(selections) {
      this.origin = selections;
      this.originAddress = selections.description
      this.orderCreation.origin[0].country = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
      this.orderCreation.origin[0].state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
      this.orderCreation.origin[0].city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
      this.origin.cordinates = String(this.origin.details.location.lat);
      this.origin.cordinates2 = String(this.origin.details.location.lng);
      this.orderCreation.origin[0].coordinates = this.origin.cordinates + "," + this.origin.cordinates2
      this.orderCreation.origin[0].address = this.origin.description;



      this.$nextTick(() => {
        try {
          this.QuotData.origin.address = this.origin.description
          this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
          this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.QuotData.AutocompleteOrigin = true;
        } catch (e) {
          console.log(e);
          this.QuotData.origin = {}
        }
      })
    },
    getSuppliers() {
      this.$http.get("Suppliers").then((response) => {
        this.itemsSuppliers = response.data.data;
      });
    },




    // ruta_transportation
    getTransportation() {
      this.$http.get("Transportation").then((response) => {
        this.itemsRuta = response.data.data;
        console.log(this.itemsRuta);
      });
    },
    addService(id) {
      let service = {
        serviceId: id,
      };
      this.orderCreation.Services.push(service);
    },
    getBuissnes() {
      this.$store.dispatch("working", true);
      this.$http("Business").then((response) => {
        this.business = response.data.data;
        console.log(this.business)
      }).catch((error) => {
        console.log(error);
        this.$toast.error("Algo salio mal, contacte al administrador!");
      }).finally(() => {
        this.$store.dispatch("working", false);
      })

    },
    getServices2() {
      this.$http.get("Services").then((response) => {
        this.itemServicios = response.data.data;
        this.itemServicios.forEach((element, index) => {
          if (
              element.id !== 15 ||
              element.id !== 1 ||
              element.id !== 16 ||
              element.id !== 17 ||
              element.id !== 18 ||
              element.id !== 19 ||
              element.id !== 20
          ) {
            this.itemServicios.splice(index, 1);
          }
        });
      });
    },


    destinationSelectedAereo(selections) {
      try {
        console.log(selections)
        this.destination = selections
        this.destinationAddress = selections.description
        this.orderCreation.destination[0].coordinates= `${this.destination.details.location.lat},${this.destination.details.location.lng}`;
        this.orderCreation.destination[0].address = this.destination.description;
        this.orderCreation.destination[0].city = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
        this.orderCreation.destination[0].country = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.orderCreation.destination[0].state = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name



        console.log(selections)
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.destination)) {
              this.orderCreation.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.orderCreation.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.orderCreation.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
              this.orderCreation.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.orderCreation.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.orderCreation.destination["airports"] = this.destination.airports;
              this.orderCreation.destination["address"] = this.destination.description;
              this.orderCreation.AutocompleteOrigin = true;
              let index = this.destination.airports.findIndex(e => e.selected);
              if (index !== -1) {

                /* if (this.QuotData.filterRoute==2){
                   this.QuotData.destination["airports"].name = this.destination.airports[index].name;
                   this.QuotData.destination["airports"].city = this.QuotData.destination.city;
                   this.QuotData.destination["airports"].coordinates = `${this.QuotData.destination.coordinates.lat},${this.QuotData.destination.coordinates.lng}`;
                   this.QuotData.destination["airports"].country = this.QuotData.destination.country;
                   this.QuotData.DestinationPort = this.destination.airports[index];
                   if (this.destination.type === 'airport') {
                     this.destinationAddress = `${this.QuotData.destination["airports"].name}, ${this.QuotData.destination["airports"].city}, ${this.QuotData.destination["airports"].country}`
                   }

                 }*/

                this.orderCreation.pod.name = this.destination.airports[index].name;
                this.orderCreation.pod.city = this.destination.airports[index].city;
                this.orderCreation.pod.coordinates = `${this.destination.airports[index].lat},${this.destination.airports[index].lon}`;
                this.orderCreation.pod.country = this.destination.airports[index].country;
                if (this.destination.type === 'airport') {
                  this.destinationAddress = `${this.orderCreation.pod.name}, ${this.orderCreation.pod.city}, ${this.orderCreation.pod.country}`
                }



              }
            }
          } catch (e) {
            this.originAddress.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.originAddress.origin = {}
        console.log(error)
      }
    },

    originSelectedAereo(selections) {
      try {
        this.origin = selections
        this.originAddress = selections.description
        this.orderCreation.origin[0].coordinates= `${this.origin.details.location.lat},${this.origin.details.location.lng}`;
        this.orderCreation.origin[0].address = this.origin.description;
        this.orderCreation.origin[0].city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
        this.orderCreation.origin[0].country = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.orderCreation.origin[0].state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name

        this.$nextTick(() => {
          try {
            if (this.origin) {
              this.orderCreation.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.orderCreation.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.orderCreation.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
              this.orderCreation.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.orderCreation.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.orderCreation.origin["airports"] = this.origin.airports;
              this.orderCreation.origin["address"] = this.origin.description;
              this.orderCreation.AutocompleteOrigin = true;
              let index = this.origin.airports.findIndex(e => e.selected);
              if (index !== -1) {


                this.orderCreation.pol.name = this.origin.airports[index].name;
                this.orderCreation.pol.city = this.origin.airports[index].city;
                this.orderCreation.pol.coordinates = `${this.origin.airports[index].lat},${this.origin.airports[index].lon}`;
                this.orderCreation.pol.country = this.origin.airports[index].country;

                if (this.origin.type === 'airport') {
                  this.originAddress = `${this.orderCreation.pol.name}, ${this.orderCreation.pol.city}, ${this.orderCreation.pol.country}`
                }

              }
            } else {
              this.orderCreation.origin = {}
            }
          } catch (e) {
            this.orderCreation.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.orderCreation.origin = {}
        console.log(error)
      }
    },

    originSelectedAir(selections) {
      try {
        console.log(selections)
        this.destination = selections
        this.destinationAddress = selections.description
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.destination)) {
              this.orderCreation.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.orderCreation.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name??this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.orderCreation.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
              this.orderCreation.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.orderCreation.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.orderCreation.destination["coordinates"] = this.destination.details.location;
              this.orderCreation.destination["airports"] = this.destination.airports;
              this.orderCreation.destination["address"] = this.destination.description;
              this.orderCreation.AutocompleteOrigin = true;
              let index = this.destination.airports.findIndex(e => e.selected);
              if (index !== -1) {

                /* if (this.QuotData.filterRoute==2){
                   this.QuotData.destination["airports"].name = this.destination.airports[index].name;
                   this.QuotData.destination["airports"].city = this.QuotData.destination.city;
                   this.QuotData.destination["airports"].coordinates = `${this.QuotData.destination.coordinates.lat},${this.QuotData.destination.coordinates.lng}`;
                   this.QuotData.destination["airports"].country = this.QuotData.destination.country;
                   this.QuotData.DestinationPort = this.destination.airports[index];
                   if (this.destination.type === 'airport') {
                     this.destinationAddress = `${this.QuotData.destination["airports"].name}, ${this.QuotData.destination["airports"].city}, ${this.QuotData.destination["airports"].country}`
                   }

                 }*/

                this.orderCreation.destination["airports"].name = this.destination.airports[index].name;
                this.orderCreation.destination["airports"].city = this.destination.airports[index].city;
                this.orderCreation.destination["airports"].coordinates = `${this.destination.airports[index].lat},${this.destination.airports[index].lon}`;
                this.orderCreation.destination["airports"].country = this.destination.airports[index].country;
                this.orderCreation.DestinationPort = this.destination.airports[index];
                if (this.destination.type === 'airport') {
                  this.destinationAddress = `${this.orderCreation.destination["airports"].name}, ${this.orderCreation.destination["airports"].city}, ${this.orderCreation.destination["airports"].country}`
                }



              }
            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.QuotData.origin = {}
        console.log(error)
      }
    },


    originSelected(selections) {
      try {
        // console.log("Selection result",selections)
        this.origin = selections;
        this.originAddress = selections.description;
        this.$nextTick(() => {
          try {
            if (this.origin.details) {
              this.orderCreation.origin[0].country = this.origin.details.address_components.filter(
                  (value) => value.types.findIndex((a) => a === "country") !== -1
              )[0].short_name;
              this.orderCreation.origin[0].state =
                  this.origin.details.address_components.filter(
                      (value) =>
                          value.types.findIndex(
                              (a) => a === "administrative_area_level_1"
                          ) !== -1
                  )[0]?.long_name ??
                  this.origin.details.address_components.filter(
                      (value) =>
                          value.types.findIndex((a) => a === "country") !== -1
                  )[0].long_name;
              this.orderCreation.origin[0].city = this.origin.details.address_components.filter(
                  (value) =>
                      value.types.findIndex(
                          (a) =>
                              a === "locality" ||
                              a === "sublocality" ||
                              a === "administrative_area_level_3" ||
                              a === "neighborhood" ||
                              a === "administrative_area_level_2"
                      ) !== -1
              )[0]?.long_name;
              this.origin.cordinates = String(this.origin.details.location.lat);
              this.origin.cordinates2 = String(this.origin.details.location.lng);
              this.orderCreation.origin[0].coordinates = this.origin.cordinates + "," + this.origin.cordinates2
              this.orderCreation.origin[0].address = this.origin.description;
              let index = this.origin.ports.findIndex((e) => e.selected);

              this.origin.address = this.originSelections.description;
              if (index !== -1) {
                this.orderCreation.pol.name = this.origin.ports[index].name;
                this.orderCreation.pol.city = this.origin.ports[index].city;
                this.origin.ports[index].coordinates[0] = Number(
                    this.origin.ports[index].coordinates[0]
                );
                this.origin.ports[index].coordinates[1] = Number(
                    this.origin.ports[index].coordinates[1]
                );
                this.orderCreation.pol.coordinates = `${this.origin.ports[index].coordinates[0]},${this.origin.ports[index].coordinates[1]}`;
                this.orderCreation.pol.country = this.origin.ports[
                    index
                    ].country;

                if (this.origin.type === "port") {
                  this.originAddress = `${this.origin.ports[index].name}, ${this.origin.ports[index].city}, ${this.origin.ports[index].country}`;
                }


                console.log(this.orderCreation);
              }
            }
          } catch (e) {
            this.orderCreation.origin = {};
            throw new Error(
                `There was an error while selecting the item ${JSON.stringify(
                    selections
                )} \n` + e
            );
          }
        });
      } catch (error) {
        this.QuotData.origin = {};
        console.log(error);
        throw new Error(
            `There was an error while selecting a new origin ${JSON.stringify(
                selections
            )} \n` + error
        );
      }
      console.log(this.orderCreation)
    },

    destinationSelected: function (selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;
      this.$nextTick(() => {
        try {
          if (!Array.isArray(this.destination)) {
            this.orderCreation.destination[0].country = this.destination.details.address_components.filter(
                (value) => value.types.findIndex((a) => a === "country") !== -1
            )[0].short_name;
            this.destination.cordinates = String(this.destination.details.location.lat);
            this.destination.cordinates2 = String(this.destination.details.location.lng);
            this.orderCreation.destination[0].coordinates = this.destination.cordinates + "," + this.destination.cordinates2
            this.orderCreation.destination[0].state =
                this.destination.details.address_components.filter(
                    (value) =>
                        value.types.findIndex(
                            (a) => a === "administrative_area_level_1"
                        ) !== -1
                )[0]?.long_name ??
                this.destination.details.address_components.filter(
                    (value) => value.types.findIndex((a) => a === "country") !== -1
                )[0].long_name;
            this.orderCreation.destination[0].city = this.destination.details.address_components.filter(
                (value) =>
                    value.types.findIndex(
                        (a) =>
                            a === "locality" ||
                            a === "sublocality" ||
                            a === "administrative_area_level_3" ||
                            a === "neighborhood" ||
                            a === "administrative_area_level_2"
                    ) !== -1
            )[0]?.long_name;
            this.orderCreation.destination[0].address = this.destination.description;

            let index = this.destination.ports.findIndex((e) => e.selected);

            // this.Origin.address = this.originSelections.description;
            if (index !== -1) {
              this.orderCreation.pod.name = this.destination.ports[index].name;
              this.orderCreation.pod.city = this.destination.ports[index].city;
              this.destination.ports[index].coordinates[0] = parseFloat(
                  this.destination.ports[index].coordinates[0]
              );
              this.destination.ports[index].coordinates[1] = parseFloat(
                  this.destination.ports[index].coordinates[1]
              );
              this.orderCreation.pod.coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
              this.orderCreation.pod.country = this.destination.ports[
                  index
                  ].country;

              if (this.destination.type === "port") {
                this.destinationAddress = `${this.orderCreation.pod.name}, ${this.orderCreation.pod.city}, ${this.orderCreation.pod.country}`;
              }
            }

            // this.setPath()
          }
        } catch (e) {
          console.log(e);
        }
      });
    },

    updateOriginItems(items) {
      console.log(items);
      this.originItems = items;
    },

    updateOriginAddress(text) {
      this.originAddress = text;
    },

    updateDestinationAddress(text) {
      this.destinationAddress = text;
    },

    updateDestinationItems(items) {
      this.destinationItems = items;
    },

    changeServices() {
      this.itemsServices = [];
      if (this.orderCreation.transportationId === 1) {
        for (let i = 0; i < this.itemsAux.length; i++) {
          if (this.itemsAux[i].transportationId === 1) {
            this.itemsServices.push(this.itemsAux[i]);
          }
        }
        this.servideDisable = false;
        this.showHide();
      }
      if (this.orderCreation.transportationId === 2) {
        this.changeIncoterm();
        for (let i = 0; i < this.itemsAux.length; i++) {
          if (this.itemsAux[i].transportationId === 2) {
            this.itemsServices.push(this.itemsAux[i]);
          }
        }
        this.servideDisable = false;
        this.showHideAereo();
      }
      if (this.orderCreation.transportationId === 3) {
        for (let i = 0; i < this.itemsAux.length; i++) {
          if (this.itemsAux[i].transportationId === 3) {
            this.itemsServices.push(this.itemsAux[i]);
          }
        }
        this.servideDisable = false;
      }
      if (this.orderCreation.transportationId === 11) {
        this.orderCreation.cargo[0].cargoType = "Container";
        this.orderCreation.cargo[0].loadingTypeId = null;
      }
      if (this.orderCreation.transportationId === 9) {
        this.orderCreation.cargo[0].cargoType = "Pallets";
        this.orderCreation.cargo[0].loadingTypeId = 2;
        this.orderCreation.cargo[0].containerId = null;
      }
    },
    showHide() {
      this.showAll();
      this.mercancia = false;
      if (this.orderCreation.transportationId === 9) {
        this.dentroFuera = false;
        this.dedicado = true;
      }
      if (this.orderCreation.transportationId === 11) {
        this.dentroFuera = true;
        this.dedicado = false;
      }
      if (
          this.orderCreation.transportationId === 12 ||
          this.orderCreation.transportationId === 13
      ) {
        this.dedicado = true;
        this.dentroFuera = true;
      }
    },
    showHideAereo() {
      this.showAll();
      this.dedicado = false;
      this.dentroFuera = false;
      this.truckRail = false;
    },
    showAll() {
      this.dentroFuera = true;
      this.dedicado = true;
      this.truckRail = true;
      this.mercancia = true;
    },
    // ruta_services
    getServices() {
      this.$http.get("TransportationCategories").then((response) => {
        this.itemsAux = response.data.data;
        this.itemsServices = response.data.data;
        console.log(this.itemsServices);
      });
    },
    // ruta_type
    getTypes() {
      this.$http.get("OrderTypes").then((response) => {
        this.itemsType = response.data.data;
        this.itemsType.forEach(function (item, index, object) {
          if (item.description === "Nacional") {
            object.splice(index, 1);
          }
        });
      });
    },
    // ruta_incoterm
    getIncoterms() {
      this.$http.get("Incoterms").then((response) => {
        this.itemsIncoterm = response.data.data;
        this.itemsIncoterm2 = response.data.data;
      });
    },
    changeIncoterm() {
      console.log("change");
      this.itemsIncoterm2 = [];
      if (this.orderCreation.transportationCategoryId === 1 || this.orderCreation.orderTypeId === 1) {
        this.incotermDisable = false;
        this.itemsIncoterm2 = [];
        for (let i = 0; i < this.itemsIncoterm.length; i++) {
          if (
              this.itemsIncoterm[i].id === 1 ||
              this.itemsIncoterm[i].id === 2 ||
              this.itemsIncoterm[i].id === 4 ||
              this.itemsIncoterm[i].id === 5 ||
              this.itemsIncoterm[i].id === 6 ||
              this.itemsIncoterm[i].id === 7 ||
              this.itemsIncoterm[i].id === 10 ||
              this.itemsIncoterm[i].id === 11
          ) {
            this.itemsIncoterm2.push(this.itemsIncoterm[i]);
          }
        }
      }
      if (this.orderCreation.transportationCategoryId === 2 || this.orderCreation.orderTypeId === 2) {
        this.incotermDisable = false;
        this.itemsIncoterm2 = [];
        for (let i = 0; i < this.itemsIncoterm.length; i++) {
          if (
              this.itemsIncoterm[i].id === 1 ||
              this.itemsIncoterm[i].id === 5 ||
              this.itemsIncoterm[i].id === 7 ||
              this.itemsIncoterm[i].id === 10 ||
              this.itemsIncoterm[i].id === 11
          ) {
            this.itemsIncoterm2.push(this.itemsIncoterm[i]);
          }
        }
      }
      if (this.orderCreation.transportationCategoryId === 3 || this.orderCreation.orderTypeId === 2) {
        this.incotermDisable = false;
        this.itemsIncoterm2 = [];
        for (let i = 0; i < this.itemsIncoterm.length; i++) {
          if (
              this.itemsIncoterm[i].id === 5 ||
              this.itemsIncoterm[i].id === 7 ||
              this.itemsIncoterm[i].id === 10 ||
              this.itemsIncoterm[i].id === 11
          ) {
            this.itemsIncoterm2.push(this.itemsIncoterm[i]);
          }
        }
      }
    },
    // envio_industria
    getIndustrias() {
      this.$http.get("Industries").then((response) => {
        this.itemsIndustria = response.data.data;
      });
    },
    getClients(search) {
      this.itemsClientes = []
      this.$http.get(`Companies/find`,{
            params: {
              name: search,
            }
          }
      ).then((response) => {
        console.log(response)
        let aux = response.data.data
        if (aux === null){
          console.log(aux)
        } else {
          aux.forEach((element) =>{
            this.itemsClientes.push(element)
          })
        }
      })
    },
    // infoCliente_cliente
    getClientes() {
      this.$http("Companies")
          .then((response) => {
            this.$store.dispatch("working", true);
            this.itemsClientes = response.data.data;
          })
          .finally(() => this.$store.dispatch("working", false));
    },
    // infoCliente_pricer
    getPricer() {
      this.$http("Employees").then((response) => {
        this.itemsPricer = response.data.data;
      });
    },
    // sendData
    lipiarData() {

      this.orderCreation.supplierId = null,
          this.orderCreation.freightForwarderId = null,
          this.orderCreation.transportationId = null,
          this.orderCreation.transportationCategoryId = null,
          this.orderCreation.orderTypeId = null,
          this.orderCreation.deliveryData = null,
          this.orderCreation.industryId = null,
          this.orderCreation.budget = null,
          this.orderCreation.GoodCost = null,
          this.orderCreation.pricerId = null,
          this.orderCreation.LandTransportation = null,
          this.orderCreation.landDedicated = null,
          this.orderCreation.LandTransference = null,
          this.orderCreation.incotermId = null,
          this.orderCreation.origin = null,
          this.orderCreation.destination = null,
          this.orderCreation.pol = null,
          this.orderCreation.pod = null,
          this.orderCreation.commodity = null,
          this.orderCreation.cargo = null,
          this.orderCreation.clientId = null,
          this.orderCreation.Frequency = null
          this.orderCreation.purchase.details[0].supplierId = null
          this.orderCreation.departureDate = null
          this.orderCreation.serviceId.id = null

    },

    sendData: function () {
      //let UserRole = this.$store.getters.roles;
      if (this.orderCreation.deliveryTypeId == null) {
        this.orderCreation.deliveryTypeId = 1
      }

      if (this.orderCreation.transportationId == 3 && this.orderCreation.pod.city == null) {
        delete this.orderCreation.pod
        delete this.orderCreation.pol
        this.orderCreation.incotermId = 1
      }
      if (this.$refs.form.validate()) {
        this.$store.dispatch("working", true);
        this.orderCreation.cargo.forEach((element) => {
          element.containerId = Number(element.containerId, 10);
          element.height = Number(element.height, 10);
          element.large = Number(element.large);
          element.loadingTypeId = Number(element.loadingTypeId, 10);
          element.quantity = Number(element.quantity, 10);
          element.weight = Number(element.weight, 10);
          element.width = Number(element.width, 10);
        });
        this.orderCreation.operatorid = this.$store.getters.UserId;
        this.orderCreation.Frequency = Number(this.orderCreation.Frequency, 10);
        this.orderCreation.GoodCost = Number(this.orderCreation.GoodCost, 10);
        this.orderCreation.budget = Number(this.orderCreation.budget, 10);
        this.orderCreation.clientId = Number(this.orderCreation.clientId, 10);
        this.orderCreation.incotermId = Number(
            this.orderCreation.incotermId,
            10
        );
        this.orderCreation.industryId = Number(
            this.orderCreation.industryId,
            10
        );
        this.orderCreation.orderTypeId = Number(
            this.orderCreation.orderTypeId,
            10
        );
        this.orderCreation.transportationCategoryId = Number(
            this.orderCreation.transportationCategoryId,
            10
        );
        this.orderCreation.transportationId = Number(
            this.orderCreation.transportationId
        );
        // En un futuro puede haber mas de 1 origen, hay que sustituir el [0] por el index y pos meterlo en un foreach vrdd!
        // -Quintero

       
        this.orderCreation.cargo.forEach((element) => {
          if (element.cargoType === "Pallets") {
            element.loadingTypeId = 2;
            element.containerId = null;
          }
          if (!element.dangerous) {
            delete element.documents;
          }
          if (element.cargoType === "Container") {
            element.loadingTypeId = null;
          }


        });
        this.orderCreation.purchase.supplierId;
        if (this.orderCreation.origin[0].address === null) {
          this.$toast.error("Porfavor llena el Origen!");
          this.$store.dispatch("working", false);
          return
        }
        if (this.orderCreation.destination[0].address === null) {
          this.$toast.error("Porfavor llena el Destino!");
          this.$store.dispatch("working", false);
          return;
        }
        if (this.orderCreation.clientId === null || this.orderCreation.clientId === 0) {
          this.$toast.error("Porfavor llena el Cliente!");
          this.$store.dispatch("working", false);
          return;
        }
        if (this.orderCreation.businessId === null) {
          this.$toast.error("Porfavor llena una Empreza!");
          this.$store.dispatch("working", false);
          return;
        }
        if (this.orderCreation.purchase.details[0].supplierId === null || this.orderCreation.purchase.details[0].supplierId === 0) {
          this.$toast.error("Porfavor llena el Provedor!");
          this.$store.dispatch("working", false);
          return;
        }
        this.$http
            .post("shipping/create/unified", this.orderCreation)
            .then((response) => {
              console.log(response);
              this.$toast.info("Se a creado un embarque");
              this.$emit("coloseModal")
              this.lipiarData();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.$store.dispatch("working", false);
            });
        /* console.log(this.itemsIncoterm2);
      console.log(this.itemsIncoterm);
      this.valueMixin = dato
      console.log(this.localValue)*/
        // console.log(this.orderCreation.transportationId);
        // console.log(this.orderCreation);
      }
    },


  },
};
</script>

<style>
.position-relative {
  position: relative;
}
</style>
