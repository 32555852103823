<template>
  <div>

    <v-navigation-drawer width="23rem" absolute permanent>

      <v-item-group v-model="cardStatus">
        <v-container>
          <v-row justify="center" align="center">

            <v-col cols="4">
              <v-item v-slot="{active,toggle}" :value="2">
                <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                  <v-card-title class="justify-center align-content-center pa-1 pb-0">
                    <h5 class="text-center text-caption">Solicitadas</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ RequestQuantity }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>

            <v-col cols="4">
              <v-item v-slot="{active,toggle}" :value="4">
                <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                  <v-card-title class="justify-center align-content-center pa-1 pb-0">
                    <h5 class="text-center text-caption">Recibidas</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ ReceivedQuantity }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>

            <v-col cols="4">
              <v-item v-slot="{active,toggle}" :value="6">
                <v-card outlined flat @click="toggle" :dark="active" :color="active? 'primary' : '' ">
                  <v-card-title class="justify-center align-content-center pa-1 pb-0">
                    <h5 class="text-center text-caption">Enviadas</h5>
                  </v-card-title>
                  <v-card-text class="justify-center align-content-center pa-1 pt-0">
                    <h2 class="text-center">
                      {{ SendQuantity }}
                    </h2>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>


          </v-row>
        </v-container>
      </v-item-group>
      <v-toolbar tile elevation="0">
        <v-text-field
            v-model="search"
            class="my-2"
            dense label="Buscar"
            @input="filterByData"
            single-line
            hide-details
        >
          <template v-slot:append>
            <v-menu
                offset-y
                content-class="included"
                :close-on-content-click="false"
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        icon
                        small
                        class="mx-1"
                        v-bind="attrs"
                        v-on="{...menu, ...tooltip}"
                    >
                      <v-icon>mdi-tune</v-icon>
                    </v-btn>
                  </template>
                  Buscar por...
                </v-tooltip>

              </template>
              <v-card
                  width="300"
              >
                <v-card-text>
                  <h2 class="font-weight-regular">Filtrar</h2>
                  <v-row>
                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-menu
                              v-model="customerFilterMenu"
                              open-on-click
                              offset-y
                          >
                            <template v-slot:activator="{on: menu}">
                              <v-text-field
                                  v-model="filterCustomer"
                                  label="Cliente"
                                  dense
                                  v-on="menu"
                                  :append-icon="customerFilterMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
                              >
                              </v-text-field>
                              <!--                              :items="Customers"-->
                            </template>
                            <v-card
                                min-width="200"
                                max-height="150"
                            >
                              <v-list>
                                <v-list-item
                                    v-for="(customer, index) in Customers.filter(x => filterCustomer ? x.name.toLowerCase().includes(filterCustomer.toLowerCase()) : true)"
                                    :key="`'customer'${index}`" @click="filterCustomer = customer.name"
                                >{{ customer.name }}
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-menu
                              v-model="salesManagerFilterMenu"
                              open-on-click
                              offset-y
                          >
                            <template v-slot:activator="{on: menu}">
                              <v-text-field
                                  v-model="filterSalesman"
                                  label="Vendedor"
                                  dense
                                  v-on="menu"
                                  :append-icon="salesManagerFilterMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
                              >
                              </v-text-field>
                            </template>
                            <v-card
                                min-width="200"
                                max-height="150"
                            >
                              <v-list>
                                <v-list-item
                                    v-for="(salesManager, index) in SalesManagers.filter(x => filterSalesman ? (x.firstName.toLowerCase().includes(filterSalesman.toLowerCase()) || x.lastName.toLowerCase().includes(filterSalesman.toLowerCase())) : true)"
                                    :key="`'sales_manager_'${index}`"
                                    @click="filterSalesman = salesManager.firstName + ' ' + salesManager.lastName"
                                >{{ salesManager.firstName + ' ' + salesManager.lastName }}
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-menu
                              v-model="pricerFilterMenu"
                              open-on-click
                              offset-y
                          >
                            <template v-slot:activator="{on: menu}">
                              <v-text-field
                                  v-model="filterPricer"
                                  label="Pricer"
                                  dense
                                  v-on="menu"
                                  :append-icon="pricerFilterMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
                              >
                              </v-text-field>
                            </template>
                            <v-card
                                min-width="200"
                                max-height="150"
                            >
                              <v-list>
                                <v-list-item
                                    v-for="(pricer, index) in Employees.filter(x => filterPricer ? (x.firstName.toLowerCase().includes(filterPricer.toLowerCase()) || x.lastName.toLowerCase().includes(filterPricer.toLowerCase())) : true)"
                                    :key="`'pricer_'${index}`"
                                    @click="filterPricer = pricer.firstName + ' ' + pricer.lastName"
                                >{{ pricer.firstName + ' ' + pricer.lastName }}
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="12">
                      <v-row justify="center">
                        <v-col md="12">
                          <v-autocomplete
                              :items="OrderStatus"
                              item-text="name"
                              item-value="id"
                              label="Status"
                              dense
                              v-model="cardStatus"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="filterByData" color="primary">Buscar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>

        </v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense style="height: 77vh; overflow-y: auto">

        <infinite-scroll v-bind="infiniteScrollProps" @loadMoreItems="GetData">
          <template v-slot="{ item,index }">
            <v-list-item-group
                :value="selectedItemOrder"
                active-class="grey lighten-2"
            >
              <v-list-item @click="RedirectPrices(item)" class="pa-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <v-card
                        class="pa-0"
                        :color="index == selectedItemOrder ? 'grey lighten-2' : ''"
                    >
                      <v-card-title>
                        <v-row no-gutters class="mx-2">
                          <v-col md="7">
                            <v-row
                                no-gutters
                                class="text-right"
                            >
                            </v-row>
                            <v-row
                                no-gutters
                            >
                              <span class="font-weight-regular text-caption">{{ item.RefCode ? item.RefCode : 'Sin Folio' }}</span>
                            </v-row>
                            <v-row
                                no-gutters
                                style="min-height: 15px"
                            >
                              <div v-if="item.Pricer !=null">
                            <span class="font-weight-regular text-subtitle-2">{{
                                item.Pricer.firstName
                              }} - {{ item.Client.name }}</span>
                              </div>
                            </v-row>
                          </v-col>
                          <v-col md="4">
                            <v-row
                                no-gutters
                                class="text-right"
                            >
                          <span
                              class="font-weight-regular text--grey text-caption" style="font-size: 0.55rem !important">
                            {{
                              item.UpdatedAt | luxon({
                                output: {
                                  zone: "local",
                                  format: "short"
                                }
                              })
                            }}
                          </span>
                            </v-row>
                            <v-row
                                no-gutters
                                style="min-height: 15px"
                            >

                            </v-row>
                            <v-row
                                no-gutters
                                class="text-right"
                            >
                              <span class="text-caption text-truncate">{{ item.StatusOrder }}</span>
                            </v-row>
                          </v-col>
                          <v-col md="1">
                            <v-row
                                no-gutters
                                style="min-height: 15px"
                            >
                              <v-avatar
                                  v-if="item.Notifications>0"
                                  color="primary"
                                  size="25"
                              >
                                <small class="white--text">{{ item.Notifications }}</small>
                              </v-avatar>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pr-2 pb-2">
                        <v-row class="d-flex justify-end">
                            <v-chip
                                class="ma-2"
                                x-small
                                color="red"
                                v-if="item.IsExpired == true"

                            >
                              vencida
                            </v-chip>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-list-item-title>
                  <v-divider color="grey"></v-divider>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </template>
        </infinite-scroll>


      </v-list>


    </v-navigation-drawer>
    <div style="margin-left: 23rem; overflow-y: auto; height: 90vh">
      <v-container fluid :key="id">
        <v-row>
          <v-col>
            <DetailOrderSales
                :key="detailKey"
                v-if="DataOrder.transportationId == 1"
                ref="detail"
                v-on:ShowDialog="ShowEditQuote()"
                v-on:ShowComment="ShowNewComment()"
                v-on:OpenCancelationModal="ShowCancelationDialog()"
                v-on:ShowDialogDuplicated="ShowEditQuoteDuplicated()"
                v-on:ShowRenegotiaton="ShowRenegotiationDialog()"
                v-on:SendToClient="SendToClient()"

                v-bind:id="id"
                v-bind:quotationId="currenQuotationId"
            >
            </DetailOrderSales>
            <DetailOrderSalesAereo
                :key="detailKey"
                v-else-if="DataOrder.transportationId == 2"
                ref="detail"
                v-bind:quotationId="currenQuotationId"
                v-on:ShowDialog="ShowEditQuote()"
                v-on:ShowDialogDuplicated="ShowEditQuoteDuplicated()"
                v-on:ShowComment="ShowNewComment()"
                v-on:OpenCancelationModal="ShowCancelationDialog()"
                v-on:ShowRenegotiaton="ShowRenegotiationDialog()"
                v-on:SendToClient="SendToClient()"
                v-on:statusOrder="statusOrder = $event"
                v-bind:id="id"
            >
            </DetailOrderSalesAereo>
            <DetailOrderTerrestre
                :key="detailKey"
                v-if="DataOrder.transportationId === 3"
                ref="detail"
                v-bind:quotationId="currenQuotationId"
                v-on:ShowDialog="ShowEditQuote()"
                v-on:ShowComment="ShowNewComment()"
                v-on:OpenCancelationModal="ShowCancelationDialog()"
                v-on:ShowDialogDuplicated="ShowEditQuoteDuplicated()"
                v-on:ShowRenegotiaton="ShowRenegotiationDialog()"
                v-on:SendToClient="SendToClient()"
                v-on:statusOrder="statusOrder = $event"
                v-bind:id="id"
            >
            </DetailOrderTerrestre>

            <v-col md="3" class="offset-9">


              <div>

                <div v-if="statusOrder == 'Renegociación' ||  statusOrder == 'Renegociacion' ">
                  <v-alert type="error" dense>{{ statusOrder }}</v-alert>
                </div>

                <div v-else-if="statusOrder == 'Pendiente Pricing' && DataOrder.assignToPricer == false">
                  <v-menu
                      bottom
                      offset-y
                  >
                    <template v-slot:activator="{on: menu}">
                      <v-alert type="info" dense v-on="menu">
                        <template v-slot:append>
                          <v-icon>mdi-menu-down</v-icon>
                        </template>
                        {{ statusOrder }}
                      </v-alert>
                    </template>
                    <v-card>
                      <v-list-item @click="requestSupplier">
                        <v-list-item-content>
                          Solicitado a proveedor
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-menu>
                </div>

                <div v-else>
                  <v-alert type="info" dense>{{ statusOrder }}</v-alert>
                </div>

              </div>


            </v-col>
            <v-row>
              <v-col md="4" class="pl-0" v-if="showFormat === 1 && !DataOrder.assignToPricer">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="ShowPricerComment" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-comment-text-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Notas</span>
                </v-tooltip>
              </v-col>
              <v-col md="3" v-if="showFormat === 1 && !DataOrder.assignToPricer && successPrice">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="dialog=true,dialogslot=6" v-bind="attrs" v-on="on">
                      <v-icon>mdi-send</v-icon>
                    </v-btn>
                  </template>
                  <span>Enviar a Ventas</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <FormatFees2
                v-if="showFormat === 1 && !DataOrder.assignToPricer"
                ref="fees"
                v-bind:idOrder="id"
                v-bind:origin="origin"
                v-bind:destination="destination"
                v-on:orderExpand="HideOrder"
                v-on:priceLength="priceLength = $event"
                v-on:successPrices="successPrice = $event"
            >
            </FormatFees2>

            <v-card elevation="0" outlined v-if="showFormat==2 && (DataOrder.statusOrder !== 'Solicitado a Proveedor' && DataOrder.statusOrder !== 'Pendiente Pricing')" >
              <v-tabs v-model="tabs" @change="getQuotationId(tabs)">
                <v-tab v-for="(item, index) in Quotations" :key="`QuotationTabs_${index}`">
                  <span>
                    {{ DataOrder.refCode }} - #{{ index + 1 }}
                  </span>
                  <v-chip class="mx-2" outlined x-small v-if="item.suggested">
                    Sugerida
                  </v-chip>
                  <v-btn icon class="ml-1" @click="deleteQuotation(item,index)">
                    <v-icon size="15" color="red">mdi-close</v-icon>
                  </v-btn>
                </v-tab>
                <v-btn icon @click="AddQuotation()">
                  <v-icon color="primary">mdi-plus-circle</v-icon>
                </v-btn>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item v-for="(item, index) in Quotations" :key="`QuotationList_${index}}`">
                  <v-row no-gutters>
                    <v-col md="12" class="px-0">
                      <v-row no-gutters>
                        <v-col md="3">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="ShowPricerComment" icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-comment-text-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Comentarios</span>
                          </v-tooltip>
                        </v-col>


                        <v-col md="3" v-if="showFormat==2">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="ShowRenegotiationDialog" icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-arrow-left-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Renegociar</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>

                      <FormatFeesSales v-if="showFormat==2" ref="feesSales"
                                       v-bind:Id="id"
                                       v-bind:IdQuotation="item.id"
                                       v-bind:="UpdateDataOrder"
                                       v-bind:origin="origin"
                                       v-bind:destination="destination"
                                       v-on:previewSaved="GetDataOrder"
                                       @sellOrder="sellOrder()"
                      >
                      </FormatFeesSales>


                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card>

            <v-card elevation="0" v-if="ShowQuotes == false && DataOrder.assignToPricer"
                    class="mt-3">

                  <div class="d-flex justify-center">
                    <img src="/ezgif.com-gif-maker.gif" alt=”gif”>
                  </div>
              <div class="d-flex justify-center">
                <h2 class="font-weight-regular">Trabajando en tu cotizacion!</h2>
              </div>
<!--                  <v-img
                      src="ezgif.com-gif-maker.gif"
                      max-height="150"
                      max-width="250"
                  ></v-img>-->
            </v-card>
            <br>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog" width="1000">
          <v-toolbar dark class="primary white--text">
            <slot name="dialog-title">
              <h2 class="font-weight-regular" v-if="dialogslot==1">
                <v-icon left size="25"></v-icon>
                Editar cotización
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==2">
                <v-icon left size="25"></v-icon>
                Comentarios
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==3">
                <v-icon left size="25"></v-icon>
                Notas
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==4">
                <v-icon left size="25"></v-icon>
                Razon de cancelación
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==5">
                <v-icon left size="25"></v-icon>
                Razon de renegociación
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==6">
                <v-icon left size="25"></v-icon>
                ¿Desea enviar los precios a ventas?
              </h2>

            </slot>

            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <EditQuote :key="editKey" v-bind:id=id @UpdateOrder="OrderUpdated" v-if="dialogslot==1"></EditQuote>
          <NewOrderComments v-bind:id="id" v-on:closeDialog="dialog = $event" v-if="dialogslot==2"></NewOrderComments>
          <PricingNotes
              :key="notesKey"
              v-bind:Id="id"
              v-if="dialogslot==3"
              v-on:closeDialog="dialog = $event"
              @success="closeDialog"
              v-model="DataOrder.terms"
          ></PricingNotes>
          <CancelationComments v-if="dialogslot==4" @success="successCancelation" v-bind:id="id"
                               v-on:closeDialog="closeCancelationDialog"></CancelationComments>
          <RenegotiationComments v-if="dialogslot==5" v-bind:id="id" v-on:closeDialog="renegotationOrder()">
          </RenegotiationComments>
          <v-card v-if="dialogslot==6">
            <v-card-text>
              <br>
              <v-row>
                <v-col md="3" class="offset-4">
                  <v-btn color="primary" @click="SendPrices">Si</v-btn>
                </v-col>

                <v-col md="3">
                  <v-btn color="red" class="white--text" @click="dialog=false">No</v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>

        </v-dialog>
        <v-dialog v-model="dialogDuplicated" width="1000">
          <v-toolbar dark class="primary white--text">
            <slot name="dialog-title">
              <h2 class="font-weight-regular" v-if="dialogslot==1">
                <v-icon left size="25"></v-icon>
                Duplicar Cotización
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==2">
                <v-icon left size="25"></v-icon>
                Comentarios
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==3">
                <v-icon left size="25"></v-icon>
                Notas
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==4">
                <v-icon left size="25"></v-icon>
                Razon de cancelación
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==5">
                <v-icon left size="25"></v-icon>
                Razon de renegociación
              </h2>
              <h2 class="font-weight-regular" v-if="dialogslot==6">
                <v-icon left size="25"></v-icon>
                ¿Desea enviar los precios a ventas?
              </h2>

            </slot>

            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <DuplicateShipment :key="editKey" v-bind:id=id @UpdateOrder="OrderUpdated" v-if="dialogslot==1"></DuplicateShipment>
          <NewOrderComments v-bind:id="id" v-on:closeDialog="dialog = $event" v-if="dialogslot==2"></NewOrderComments>
          <PricingNotes
              :key="notesKey"
              v-bind:Id="id"
              v-if="dialogslot==3"
              v-on:closeDialog="dialog = $event"
              @success="closeDialog"
              v-model="DataOrder.terms"
          ></PricingNotes>
          <CancelationComments v-if="dialogslot==4" @success="successCancelation" v-bind:id="id"
                               v-on:closeDialog="closeCancelationDialog"></CancelationComments>
          <RenegotiationComments v-if="dialogslot==5" v-bind:id="id" v-on:closeDialog="renegotationOrder()">
          </RenegotiationComments>
          <v-card v-if="dialogslot==6">
            <v-card-text>
              <br>
              <v-row>
                <v-col md="3" class="offset-4">
                  <v-btn color="primary" @click="SendPrices">Si</v-btn>
                </v-col>

                <v-col md="3">
                  <v-btn color="red" class="white--text" @click="dialog=false">No</v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>

        </v-dialog>
      </v-container>
    </div>
  </div>
</template>

<script>

import DetailOrderSales from "@/components/Sales/DetailOrderSales";
import {mapGetters} from "vuex"
import NewOrderComments from "@/components/Pricing/NewOrderComments";
import EditQuote from "@/components/Pricing/EditQuote";
import PricingNotes from "@/components/Pricing/PricingNotes";
import RenegotiationComments from "@/components/Pricing/RenegotiationComments";
import CancelationComments from "@/components/Pricing/CancelationComments";
import FormatFeesSales from "./FormatFeesSales";
import DetailOrderTerrestre from "./DetailOrderTerrestre";
import DetailOrderSalesAereo from "./DetailOrderSalesAereo";
import FormatFees2 from "../Pricing/FormatFees2";
import folio_mixin from "@/mixins/folio_mixin";
import debouncePromise from "debounce-promise";
import InfiniteScroll from "../InfiniteScroll";
import DuplicateShipment from "@/components/Pricing/DuplicateShipment";

const _GLOBALS = {
  ORDER_TYPE: {
    IMPORT: 1,
    EXPORT: 2,
    NATIONAL: 3
  },
  TRANSPORTATION: {
    MARITIME: 1,
    AIR: 2,
    LAND: 3,
  },
  SUPPLIER_TYPES: {
    AIR: 2,
    LAND: 3,
    MARITIME: 4,
    MARKETING: 5,
    INSPECTION: 6,
    CARGO_SAFE: 7,
    WAREHOUSING: 8,
    PACKAGING: 9,
    FINANCING: 10
  }
}

export default {
  name: "ProceedQuoteSales",
  mixins: [folio_mixin],
  components: {
    InfiniteScroll,
    FormatFees2,
    DetailOrderSalesAereo,
    DetailOrderTerrestre,
    FormatFeesSales,
    CancelationComments,
    RenegotiationComments,
    PricingNotes,
    EditQuote,
    NewOrderComments,
    DetailOrderSales,
    DuplicateShipment
  },
  computed: {
    ...mapGetters([
      'idOrder'
    ]),
    selectedItemOrder: {
      get: function () {
        return this.Data.findIndex(x => x.Id == this.orderId)
      }
    },
    orderId: {
      get: function () {
        return this.$route.params.id
      }
    }
  },
  watch: {

    cardStatus() {
      this.filterByData()
    },


    orderId() {
      this.GetDataOrder();

    },

    Quotations: {
      handler() {
        this.tab = this.Quotations.length - 1;
        this.tabs = this.Quotations.length - 1;
        this.getQuotationId();

      },
      deep: true
    },

    tabCounter(val) {
      this.tab = val - 1;
    },


    dialog() {
      if (this.dialogslot === 3) {
        console.log(this.$refs.detail)
        this.orderTerms = this.$refs.detail.DataOrder.terms
      }
    }
  },
  data() {
    return {
      notesKey: 0,
      RequestQuantity: 0,
      dialogDuplicated:false,
      ReceivedQuantity: 0,
      SendQuantity: 0,
      cardStatus: null,
      counterSuccess: null,
      infiniteScrollProps: {
        page: undefined,
        pageSize: 5,
        items: [],
        lastPage: null,
      },
      editKey: 0,
      detailKey: 0,
      showFormat: 0,
      filterBy: {},
      Customers: [],
      DataOrder: [],
      SalesManagers: [],
      UpdateDataOrder: false,
      options: {
        page: 1,
        itemsPerPage: 20,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
      },
      Employees: [],
      filterCustomer: null,
      expandOrder: true,
      filterSalesman: null,
      filterPricer: null,
      filterStatus: null,
      customerFilterMenu: false,
      salesManagerFilterMenu: false,
      pricerFilterMenu: false,
      originSelections: {},
      ShowQuotes: false,
      filter: false,
      destinationItems: [],
      destinationSelections: {},
      origin: {
        address: null,
        city: null,
        state: null,
        country: null,
        coordinates: null,
        port: {
          name: null,
          city: null,
          coordinates: null,
          country: null
        }
      },
      destination: {
        address: null,
        state: null,
        city: null,
        country: null,
        coordinates: null,
        port: {
          name: null,
          city: null,
          coordinates: null,
          country: null
        }
      },
      e1: 0,
      tabs: 0,
      priceLength: null,
      successPrice: false,
      Quotations: [],
      originItems: [],
      dialogslot: 0,
      orderTerms: "",
      statusOrder: "",
      dialogComments: false,
      selectedItem: 0,
      route: null,
      active: false,
      editQuote: false,
      OrderStatus: [
        {
          id: 1,
          name: 'Cotización Pendiente'
        },
        {
          id: 2,
          name: 'Pendiente Pricing'
        },
        {
          id: 3,
          name: 'Solicitado a Proveedor'
        },
        {
          id: 4,
          name: 'Enviado a Ventas'
        },
        {
          id: 5,
          name: 'Cotizacion Incompleta'
        },
        {
          id: 6,
          name: 'Enviada a cliente'
        },
        {
          id: 7,
          name: 'Renegociacion'
        },
        {
          id: 8,
          name: 'Vendida'
        },
        {
          id: 9,
          name: 'Cancelada'
        },
        {
          id: 10,
          name: 'Rechazada'
        }
      ],
      pricerComment: false,
      counter: 0,
      ResumeTotal: 0,
      items: [
        {
          action: 'mdi-ticket',
          items: [{
            title: 'List Item'
          }],
          title: 'Attractions',
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [{
            title: 'Breakfast & brunch'
          },
            {
              title: 'New American'
            },
            {
              title: 'Sushi'
            },
          ],
          title: 'Dining',
        },
        {
          action: 'mdi-school',
          items: [{
            title: 'List Item'
          }],
          title: 'Education',
        },
        {
          action: 'mdi-run',
          items: [{
            title: 'List Item'
          }],
          title: 'Family',
        },
        {
          action: 'mdi-bottle-tonic-plus',
          items: [{
            title: 'List Item'
          }],
          title: 'Health',
        },
        {
          action: 'mdi-content-cut',
          items: [{
            title: 'List Item'
          }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{
            title: 'List Item'
          }],
          title: 'Promotions',
        },
      ],
      dense: true,
      counterElement: 0,
      Resume: [],
      newElement: [],
      OriginsData: [{
        id: 0,
        address: null,
        city: null,
        state: null,
        country: null,
        coordinates: null,
        port: {
          name: null,
          city: null,
          coordinates: null,
          country: null
        }
      }],
      Refuse: {
        orderId: null,
        comments: null,
        orderReasonId: 1
      },
      RefuseReasons: [
        {
          Id: 1,
          Reasons: 'No hay disponibilidad',
          Status: true
        },
        {
          Id: 2,
          Reasons: 'No hay informacion',
          Status: true
        },
        {
          Id: 3,
          Reasons: 'No hay Ruta',
          Status: true
        }
      ],
      inputs: [{
        name: '',
        rate: '',
        quantity: '',
        total: ''
      }],
      tab: 2,
      tabCounter: 0,
      desserts: [{
        name: 'Frozen Yogurt',
        calories: 159,
      },],
      originAddress: "",
      destinationAddress: "",
      currenQuotationId: null,
      dialog: false,
      id: null,
      isLoading: false,
      QuantityStatus: 0,
      proceedItemData: [],
      colorStatus: [
        {
          status: 'Enviado a Ventas',
          color: 'green darken-2'
        },
        {
          status: 'Pendiente Pricing',
          color: 'yellow darken-2'
        },
        {
          status: 'Solicitado a Proveedor',
          color: 'red darken-2'
        }
      ],
      notes: null,
      headers: [{
        text: 'Codigo',
        align: 'start',
        sortable: false,
        value: 'id',
      },
        {
          text: 'Fecha',
          value: 'departureDate'
        },
        {
          text: 'Cliente',
          value: 'client.name'
        },
        {
          text: 'Responsable',
          value: 'salesManager'
        },
        {
          text: 'Recibida',
          value: 'createdAt'
        },
        {
          text: 'Material Peligroso',
          value: 'urgent'
        },
        {
          text: 'Urgente',
          value: 'urgent'
        },
        {
          text: 'PDF Cotizacion',
          value: 'document'
        },
        {
          text: 'Proceder',
          value: 'action'
        },
        {
          text: '',
          value: 'data-table-expand'
        },
      ],
      Data: [],
      Email: {
        suppliers: [],
        serviceId: 1,
        subject: "Cotizacion Maritima",
        message: "Detalle de cotizacion"
      },

      testdialog: false,
      search: "",
      expanded: [],
      singleExpand: false,
    }
  },
  methods: {

    closeCancelationDialog() {
      this.dialog = false;
      this.updateOrderCount()
    },

    sellOrder() {
      this.updateOrderCount();
      this.GetDataOrder();
    },

    successCancelation() {
      this.dialog = false;
      this.updateOrderBy(this.id);
      this.GetDataOrder();
    },

    renegotationOrder() {
      this.dialog = false;
      this.updateOrderBy(this.id)
      this.GetDataOrder();
      this.updateOrderCount();
    },


    updateOrderCount() {
      this.$http.get('Orders/count/sales').then(response => {
        if (response.data.code == 200) {
          this.RequestQuantity = response.data.data.open
          this.ReceivedQuantity = response.data.data.received
          this.SendQuantity = response.data.data.send
        }
      }).catch(error => {
        if (error.response.state === 401) {
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    requestSupplier() {
      this.setOrderRequestedSupplier().then(({data}) => {
        if (data.code === 200) {
          this.$toast.info('Actualizado correctamente')
          this.$refs.detail.GetDataOrder();
          this.UpdateOrderToList(data.data)
        } else {
          console.error(data)
          this.$toast.error('Hubo un error, comunicese con el administrador del sistema')
        }
      }).catch((error) => {
        console.error(error)
        this.$toast.error('Hubo un error, comunicese con el administrador del sistema')
      })
    },
    setOrderRequestedSupplier() {
      return this.$http.put(`Orders/${this.id}/requested`)
    },
    OrderUpdated() {
      this.detailKey += 1;
      this.editKey += 1;
      this.dialog = false;
      this.updateOrderBy(this.id);
    },
    getCustomers: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Companies')
          .then(response => {
            console.log(response)
            this.Customers = response.data.data;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }


          }).finally(() => this.$store.dispatch('working', false))
    },

    getQuotationId() {
      let quotationId = this.Quotations[this.tabs].id
      this.currenQuotationId = quotationId;
    },

    GetDataOrder: function () {

      this.$emit('idOrder', this.id);


      this.$store.dispatch('working', true)
      this.$http.put(`Notification/${this.id}/readAll`).then(() => {
        this.$store.dispatch("updateNotifications", true);
      })
      this.$http.get(`Orders/${this.id}`).then(response => {
        this.notesKey += 1;
        // console.log(response)
        this.DataOrder = response.data.data;

        //  this.DataOrder.folio = this.formatFolioById(this.response.data.data);

        this.statusOrder = this.cleanText(this.DataOrder.statusOrder)

        if (this.DataOrder.assignToPricer) {

          this.$nextTick(() => {
            let status = this.statusOrder.toLowerCase();
            if (status == 'enviado a ventas' ||
                status == 'enviada a cliente' ||
                status == 'vendida' ||
                status == 'cancelada' ||
                status == 'cotizacion incompleta'
                || status == 'renegociacion') {
              this.showFormat = 2;
              this.ShowQuotes = true;
            } else if (status == 'pendiente pricing' ) {
              this.ShowQuotes = false;
            }
          })

        }

        if (this.DataOrder.terms == null) {
          this.DataOrder.terms =
              'No incluye despacho aduanal/impuestos (impo).\n' +
              '• Excluye maniobras en caso de rojo, inspección y/o previo físico.\n' +
              '• No incluye seguro de mercancía, en caso de requerirlo favor de solicitarlo al ejecutivo de su cuenta.\n' +
              '• El seguro de transporte será cubierto únicamente por indicaciones expresas del cliente.\n' +
              '• Tarifas sujetas a cambio con o sin previo aviso de acuerdo con mercado internacional.\n' +
              '• Los tiempos de tránsito y frecuencias corresponden a un servicio de línea regular, sin embargo, este puede cambiar por causas de fuerza mayor sin previo aviso.\n' +
              '• El servicio está sujeto a disponibilidad de espacio y al cumplimiento de todas las restricciones de peso aplicables.\n' +
              '• El costo de la cotización puede variar, en base a la verificación de medidas y peso.\n' +
              '• No incluye cobros por almacenajes ni demoras.\n' +
              '• Las maniobras de carga y descarga en punto de origen y punto final son va por la parte del cliente y/o proveedor.';
        }

        if (!this.DataOrder.assignToPricer) {


          let status = this.statusOrder.toLowerCase();
          if (status == 'enviado a ventas' ||
              status == 'enviada a cliente' ||
              status == 'vendida' ||
              status == 'cancelada' ||
              status == 'cotizacion incompleta') {
            this.showFormat = 2;
            this.ShowQuotes = true;
          } else if (status == 'pendiente pricing' ||  status == 'renegociacion' ) {

            this.showFormat = 1;
            this.ShowQuotes = false;
          }
        }

        this.DataOrder.folio = this.formatFolioById(response.data.data)


      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },

    formatFolioById(item) {
      let folio = "S";
      console.log(item)
      folio += item.id + "-";

      if (_GLOBALS.ORDER_TYPE.IMPORT == item.orderTypeId) {
        folio += "I"
      }
      if (_GLOBALS.ORDER_TYPE.EXPORT == item.orderTypeId) {
        folio += "E"
      }
      if (_GLOBALS.ORDER_TYPE.NATIONAL == item.orderTypeId) {
        folio += "N"
      }
      if (_GLOBALS.TRANSPORTATION.MARITIME == item.transportationId) {
        folio += "M"
      }
      if (_GLOBALS.TRANSPORTATION.LAND == item.transportationId) {
        folio += "T"
      }
      if (_GLOBALS.TRANSPORTATION.AIR == item.transportationId) {
        folio += "A"
      }

      if (item.transportationCategory.description == 'Servicio Express') {
        item.transportationCategory.description = 'SE';
      }

      if (item.transportationCategory.description == 'Hand Carrier') {
        item.transportationCategory.description = 'HC';
      }

      if (item.transportationCategory.description == 'Servicio Regular') {
        item.transportationCategory.description = 'SR';
      }

      if (this.cleanText(item.transportationCategory.description) == 'Paqueteria Aerea') {
        item.transportationCategory.description = 'PA';
      }

      if (item.transportationCategory.description == 'Air Charter') {
        item.transportationCategory.description = 'ACH';
      }

      if (this.cleanText(item.transportationCategory.description) == 'Arrastres de contenedores') {
        item.transportationCategory.description = 'ADC';
      }

      if (this.cleanText(item.transportationCategory.description) == 'Bulk-RoRo') {
        item.transportationCategory.description = 'BRR';
      }

      if (this.cleanText(item.transportationCategory.description) == 'Bulk-BreakBulk') {
        item.transportationCategory.description = 'BBB';
      }


      folio += item.transportationCategory.description
      folio += item.origin[0].country ? item.origin[0].country.substring(0, 3).toUpperCase() + "-" : ""
      folio += item.destination[0].country ? item.destination[0].country.substring(0, 3).toUpperCase() : ""
      item.folio = folio;
      return item.folio
    },

    SendNotes: function () {
      this.$http.put(`Orders/${this.id}/terms`, `"${this.DataOrder.terms}"`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(data => {
        if (data.data.code === 200) {
          console.log('ok');
        } else {
          console.error(data)
          this.$toast.error('Ocurrio un error al generar las notas');
        }
      }).catch(error => {
        console.error(error)
        //allert('Error')
      })
    },

    updateOrderBy(id) {
      let index = this.Data.findIndex(element => element.Id == id);
      var element = this.Data[index];
      this.Data.splice(index, 1);
      this.Data.splice(0, 0, element);
      console.log(this.Data);
    },


    SendPrices() {

      this.SendNotes();


      setTimeout(() => {
        this.$store.dispatch("working", true);
        this.$http.post(`Orders/${this.id}/send/sales`).then(({data}) => {
          if (data.code === 200) {
            this.$toast.info('Enviado a ventas éxitosamente')
            this.dialog = false;
            this.updateOrderBy(this.id)
            this.GetDataOrder();
            this.GetQuotations();
          } else {
            this.$toast.error('Hubo un error')
          }
        }).finally(() => {
          this.$store.dispatch("working", true);
        })

        // if(this.expandOrder === false){
        //   let redirectIndexAndId = 0
        //
        //   this.Data.length - 1 !== this.selectedItem ? redirectIndexAndId = this.selectedItem + 1 : redirectIndexAndId = 0
        //   this.id = this.Data[redirectIndexAndId].id
        //   this.$route.params.id = this.Data[redirectIndexAndId].id
        //   this.selectedItem = redirectIndexAndId
        // }
      }, 500)


    },


    SendToClient() {
      this.$store.dispatch('working', true);
      this.$http.post(`Orders/${this.id}/send/client`).then(response => {
        if (response.data.code == 200) {
          this.$toast.info('Enviado a cliente');
          this.$router.push('/Sales')
          this.updateOrderBy(this.id)
          this.updateOrderCount();
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },


    getSalesManager: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Salesmanager')
          .then(response => {

            this.SalesManagers = response.data.data;
          }).catch(error => {

        console.log(error)

      }).finally(() => this.$store.dispatch('working', false))
    },

    HideOrder: function (value) {
      this.expandOrder = value
    },

    getPricer: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Pricing')
          .then(response => {
            this.Employees = response.data.data;
            console.log(this.Employees)
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },

    infiniteScrolling(intersect) {
      if (intersect[0].isIntersecting && this.Data.length > 15) {
        if (!this.isLoading) {
          this.isLoading = true;
          this.options.page += 1;
        }
      }
    },


    filterOrders() {
      this.filterBy = {}
      if (this.filterCustomer) {
        this.filterBy.Client = this.filterCustomer;
      }

      if (this.filterSalesman) {
        this.filterBy.SalesManager = this.filterSalesman;
      }

      if (this.filterPricer) {
        ////allert(this.filterPricer)
        this.filterBy.Pricing = this.filterPricer;
      }

      if (this.filterStatus) {
        this.filterBy.Status = this.filterStatus;
      }
      this.$store.dispatch('working', true);

      this.$http.get('Orders/Find', {params: this.filterBy}).then(response => {
        // console.log(response.data.data)
        // this.Data = [];
        response.data.data.forEach((item) => {

          this.formatFolio(item);


          // this.Data.push(item)
        });


      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })


    },

    filterByStatus(statusId) {

      this.filterBy = {}
      this.filterBy.Status = statusId;
      this.$store.dispatch('working', true);
      this.$http.get('Orders/Find', {params: this.filterBy}).then(response => {
        console.log(response.data.data)
        // this.Data = [];
        response.data.data.forEach((item) => {
          this.formatFolio(item);
          this.Data.push(item)
        });

      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })


    },

    closeDialog: function () {
      this.dialog = false
      this.$refs.feesSales[0].setTerms(this.DataOrder.terms)
    },
    SearchQuote(searchValue) {
      if (searchValue == '')
        this.filter = false
      else if (searchValue != '') {
        let find = this.Data.find(f => f.folio.includes(searchValue));

        this.aux = [];
        this.aux.push(find)
        this.filter = true;
      }

    },

    RedirectPrices(item) {

      let index = this.Data.findIndex(element => element.Id == item.Id);
      this.Data[index].Notifications = 0;
      let status = this.statusOrder.toLowerCase();
      if (status == 'enviado a ventas' ||
          status == 'enviada a cliente' ||
          status == 'vendida' ||
          status == 'renegociacion' ||
          status == 'cancelada' ||
          status == 'cotizacion incompleta') {
        this.ShowQuotes = true;
      } else {
        this.ShowQuotes = false;
      }
      this.id = parseInt(this.id);
      this.id = item.Id;
      this.GetQuotations();
      this.$router.push(`/ProceedQuoteSales/${this.id}`)
      //this.UpdateDataOrder = true;
    },

    AddQuotation: function () {
      this.counter++;
      this.Quotation = {
        id: 0,
        orderId: this.id,
        profit: 1,
        suggested: false,
        discount: 1,
        discountPercentage: 1,
        profitPercentage: 1,
        quotationDetails: []
      }
      this.NewQuotation();
    },

    ShowNewComment: function () {
      this.dialog = true;
      this.dialogslot = 2;
    },

    ShowCancelationDialog: function () {
      this.dialog = true;
      this.dialogslot = 4;
    },

    ShowRenegotiationDialog: function () {
      this.dialog = true;
      this.dialogslot = 5;
    },

    emitFees: function () {
      this.$refs.feesSales[0].addElement();
    },

    ShowEditQuote: function () {
      this.dialog = true;
      this.dialogslot = 1;
    },

    ShowEditQuoteDuplicated: function () {
      this.dialogDuplicated = true;
      this.dialogslot = 1;
    },

    ShowPricerComment: function () {
      this.dialog = true;
      this.dialogslot = 3;
    },

    updateOriginAddress(text) {
      this.originAddress = text
    },

    updateDestinationAddress(text) {
      this.destinationAddress = text
    },

    updateDestinationItems(items) {
      this.destinationItems = items;
    },

    originSelected(selectedItem) {
      console.log('Change')
      this.originAddress = selectedItem.address

      this.originSelections = selectedItem;
      console.log(this.originSelections)

      this.$nextTick(() => {
        this.origin.address = this.originSelections.description
        this.origin.city = this.originSelections.details.address_components.filter((value) => value.types
            .findIndex(a => a === 'locality') !== -1)[0].long_name
        this.origin.state = this.originSelections.details.address_components.filter((value) => value.types
            .findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.origin.country = this.originSelections.details.address_components.filter((value) => value.types
            .findIndex(a => a === 'country') !== -1)[0].short_name
        this.origin.coordinates =
            `${this.originSelections.details.location.lat},${this.originSelections.details.location.lng}`;
        let index = this.originSelections.ports.findIndex(e => e.selected);
        console.log(index)


        this.origin.port.name = this.originSelections.ports[index].name;
        this.origin.port.city = this.originSelections.ports[index].city;
        this.originSelections.ports[index].coordinates[0] = parseInt(this.originSelections.ports[index]
            .coordinates[0]);
        this.originSelections.ports[index].coordinates[1] = parseInt(this.originSelections.ports[index]
            .coordinates[1]);
        this.origin.port.coordinates =
            `${this.originSelections.ports[index].coordinates[0]},${this.originSelections.ports[index].coordinates[1]}`;
        this.origin.port.country = this.originSelections.ports[index].country;
        this.origin.address = this.originSelections.description;
        // //allert(index)

      })
    },

    destinationSelected: function (selections) {
      this.destinationSelections = selections;

      this.$nextTick(() => {
        this.destination.address = this.destinationSelections.description;
        this.destination.city = this.destinationSelections.details.address_components.filter((value) => value
            .types.findIndex(a => a === 'locality') !== -1)[0].long_name
        this.destination.state = this.destinationSelections.details.address_components.filter((value) => value
            .types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.destination.country = this.destinationSelections.details.address_components.filter((value) => value
            .types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.destination.coordinates =
            `${this.destinationSelections.details.location.lat},${this.destinationSelections.details.location.lng}`;

        let index = this.destinationSelections.ports.findIndex(e => e.selected);
        console.log(index)


        this.destination.port.name = this.destinationSelections.ports[index].name;
        this.destination.port.city = this.destinationSelections.ports[index].city;
        this.destination.ports[index].coordinates[0] = parseInt(this.destinationSelections.ports[index]
            .coordinates[0]);
        this.destination.ports[index].coordinates[1] = parseInt(this.destinationSelections.ports[index]
            .coordinates[1]);
        this.destination.port.coordinates =
            `${this.destinationSelections.ports[index].coordinates[0]},${this.destinationSelections.ports[index].coordinates[1]}`;
        this.destination.port.country = this.destinationSelections.ports[index].country;
        this.destination.address = this.destinationSelections.description;
        // //allert(index)
      })
    },

    updateOriginItems(items) {
      this.originItems = items;
    },

    resetFilterValues() {
      this.search = "";
      this.cardStatus = null;
      this.filterPricer = null;
      this.filterSalesman = null;
      this.filterCustomer = null;
    },

    filterByData: debouncePromise(function () {
      this.Data = [];
      this.options.page = 1;
      this.GetData();
      // this.resetFilterValues();
    }, 300),

    GetData: function (page = 1) {
      let status = '';
      if (this.cardStatus === 2) {
        status = `1,2,3,7`
      }
      if (this.cardStatus === 4) {
        status = `4,5`
      }
      if (this.cardStatus === 6) {
        status = `6,8`
      }

      this.$http.get('Orders/Find',{
        params: {
          PageSize: this.options.itemsPerPage,
          PageNumber: page,
          Refcode:this.search,
          Status: status,
          Pricing:this.filterPricer,
          SalesManager:this.filterSalesman,
          Client:this.filterCustomer
        }
      }).then(response => {

        if (response.data.code == 200) {
          let res = response.data.data;
          for (let item of res) {
            this.formatFolio(item);
            let index = this.Data.findIndex(x => x.Id === item.Id);
            if (index === -1) {
              this.Data.push(item)
            }
          }


          this.$nextTick(() => {
            this.isLoading = false;
          })

          this.infiniteScrollProps.page = page;
          this.infiniteScrollProps.lastPage = response.data.meta.totalPages;
          this.infiniteScrollProps.items = this.Data;

        }

        // this.orderByNotifications();


        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")

      }).catch(error => {
        // if(error.response.status == 401){
        //   this.$store.commit('reset');
        //   this.$toast.error("La sesion expiro");
        //   this.$router.replace('/login')
        // }
        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
      this.active = 0;
    },

    orderByNotifications() {
      this.Data.sort((a, b) => {
        const diff = b.Notifications - a.Notifications || b.Id - a.Id;
        switch (diff) {
          case 0:
            return -1;
          default:
            return diff;
        }
      });


      //console.log(this.Data.sort((a, b) => (b.Notifications) - (a.Notifications)))
    },


    selectListItem: function () {
      let index = this.Data.findIndex(e => e.id == this.id);
      this.selectedItem = index;
    },

    RecibeData: function (data) {

      console.log(data);

      this.Email.suppliers.push({
        id: data[0].id,
        contact: data[0].idContact
      })

      console.log(this.Email);

    },

    proceed: function (id) {
      this.dialog = true;
      this.id = id;
    },

    addElement() {

      this.counterElement = this.counterElement + 1;

      this.tabs.push({
        id: this.counterElement,
      })

    },

    removeElement(id) {
      //this.counterElement--;
      console.log(id);
      ////allert(id)
      this.tabs.forEach((element, index) => {
        if (element.id == id) {
          this.tabs.splice(index, 1);
        }
      })
    },

    Renegotiation: function () {
      this.Refuse.orderId = this.id;
      this.$store.dispatch('working', true)
      this.$http.put(`Orders/renegotiation/${this.id}`, this.Refuse).then(response => {
        if (response.data.code == 200) {
          console.log(response.data.data);
          this.$toast.info("Enviado a pricing");
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      });
    },

    GetQuotations() {

      let status = this.statusOrder.toLowerCase();

      if (status == 'enviado a ventas' ||
          status == 'enviada a cliente' ||
          status == 'vendida' ||
          status == 'renegociacion' ||
          status == 'cancelada' ||
          status == 'cotizacion incompleta') {
        this.ShowQuotes = true;
      } else {
        this.ShowQuotes = false;
      }
      this.$http.get(`Quotation/Order/${this.id}`).then(response => {
        if (response.data.code == 200) {
          if (response.data.data.length > 0) {
            this.Quotations = response.data.data


          }

          if (response.data.data.length == 0) {
            this.AddQuotation();
          }


        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
        console.log(error);
      }).finally(() => {
      })
    },

    GetSignalData(data) {
      console.log(data)
    },

    UpdateFees() {
      this.$refs.fees[0].UpdateFees();
    },
    deleteQuotation(quotation, index) {
      this.$store.dispatch('working', true);

      // console.log(quotation);

      // First we delete Quotation Details Becouse "BACKEND"
      quotation.quotationDetails.forEach(({id}) => {
        this.$http.delete(`Quotation/QuotationDetails/${quotation.id}/${id}`);
      });

      this.$http.delete(`Quotation/${quotation.id}`).then(() => {
        this.$toast.info("Cotización eliminada");
        this.Quotations.splice(index, 1);
      }).finally(() => {
        this.$store.dispatch("working", false);
      })
    },
    NewQuotation() {
      this.$store.dispatch('working', true);
      this.$http.post(`Quotation`, this.Quotation).then(response => {
        if (response.data.code == 200) {
          this.GetQuotations();
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    MapOrderToList(obj) {
      let order = this.mapOrder(obj)

      this.formatFolio(order);

      return order
    },
    AssignOrderToList(obj) {
      console.log("Esta entrando");
      let index = this.Data.findIndex(x => x.Id == obj.id)
      obj.notifications += 1;
      let order = this.MapOrderToList(obj)

      if (index !== -1) {
        this.Data.splice(index, 1)

      }

      this.Data.splice(0, 0, order)
      this.updateOrderCount();
    },
    UpdateOrderToList(obj) {
      // console.log(obj)
      let index = this.Data.findIndex(x => x.Id == obj.id)
      // console.log(index)

      if (index !== -1) {
        let order = this.MapOrderToList(obj)

        console.log(this.Data[index])

        this.Data[index] = order;

        this.updateOrderCount();

      }
    },
    mapOrder(order) {
      return {
        Id: order.id,
        TransportationId: order.transportationId,
        CommentsToPricer: order.commentsToPricer,
        TransportationCategoryId: order.transportationCategoryId,
        IncotermId: order.incotermId,
        OrderTypeId: order.orderTypeId,
        LandTransfer: order.landTransfer,
        LandTransportation: order.landTransportation,
        LandDedicated: order.landDedicated,
        LandFull: order.landFull,
        Terms: order.terms,
        Origin: order.origin,
        Destination: order.destination,
        StatusOrder: order.statusOrder,
        Pol: order.pol,
        Pod: order.pod,
        DepartureDate: order.departureDate,
        IndustryId: order.industryId,
        Budget: order.budget,
        Commodity: order.commodity,
        InsideBillOfLanding: order.insideBillOfLanding,
        Cargo: order.cargo,
        Services: order.services,
        ClientId: order.clientId,
        PricerId: order.pricerId,
        SalesManagerId: order.salesManagerId,
        Pricer: order.pricer,
        SalesManager: order.salesManager,
        Client: order.client,
        OrderType: order.orderType,
        Currency: order.currency,
        Incoterm: order.incoterm,
        Transportation: order.transportation,
        TransportationCategory: order.transportationCategory,
        Industry: order.industry,
        Frequency: order.frequency,
        Urgent: order.urgent,
        Comments: order.comments,
        CreatedAt: order.createdAt,
        UpdatedAt: order.updatedAt,
        Notifications: order.notifications
      }
    },
  },
  created() {
    const pageAccessedByReload = (
        (window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance
            .getEntriesByType('navigation')
            .map((nav) => nav.type)
            .includes('reload')
    );
    console.log(pageAccessedByReload)


  },
  mounted() {
    this.detailKey += 1;
    this.editKey += 1;
    this.id = this.$route.params.id;
    this.id = parseInt(this.id);
    this.GetDataOrder();
    this.GetData();
    this.GetQuotations();
    this.getCustomers();
    this.getSalesManager();
    this.getPricer();
    this.updateOrderCount();

    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('html').style.overflow = 'hidden';

    const pageAccessedByReload = (
        (window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance
            .getEntriesByType('navigation')
            .map((nav) => nav.type)
            .includes('reload')
    );

    if (!pageAccessedByReload) {
      this.$hub.Connection.on("RequestedToSupplier", (obj) => {

        this.UpdateOrderToList(obj)
      })
      this.$hub.Connection.on("OrderSendToSales", (obj) => {
        console.log('Llego de precios');
        this.AssignOrderToList(obj)
      })
      this.$hub.Connection.on("OrderReturnToSales", (obj) => {

        this.AssignOrderToList(obj)
      })
      this.$hub.Connection.on("OrderCreated", (obj) => {

        this.AssignOrderToList(obj)
      })
    }



    // this.$hub.Connection.on("OrderUpdated", () => {
    //   console.log('Si funciono esto');
    //   this.GetDataOrder();
    // })
  },

  beforeDestroy() {
    document.querySelector('body').style.overflow = 'auto';
    document.querySelector('html').style.overflow = 'auto';

    this.$hub.Connection.off("RequestedToSupplier", this.UpdateOrderToList)
    this.$hub.Connection.off("OrderSendToSales", this.AssignOrderToList)
    this.$hub.Connection.off("OrderReturnToSales", this.AssignOrderToList)
    this.$hub.Connection.off("OrderCreated", this.AssignOrderToList)
    // this.$hub.Connection.off("OrderUpdated")
  },
  destroyed() {
  }
}
</script>

<style scoped>
.fixed-col-size {
  min-width: 250px;
}

.position-relative {
  position: relative;
}

.order-search-section {
  max-height: 90vh;
  overflow-y: scroll;
}

.prices-section {
  max-height: 50vh;
  overflow-y: scroll;
}

>>> .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
</style>
