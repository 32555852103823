
<template>
  <div>
    <NotificationBar ref="navBar"></NotificationBar>
    <v-navigation-drawer
        v-model="drawer"
        app
        :dark="darkTheme"
        mini-variant
        style="background: rgb(220,85,0);
background: linear-gradient(0deg, rgba(220,85,0,0) 0%, rgba(1,75,152,1) 50%);"
    >

      <img src="@/assets/LogoCon.png"  width="50" class="ml-1"/>
      <v-list expand :dark="darkTheme" shaped>
        <template v-for="(item) in menus">
          <v-list-group
              style="padding-top: 0px"
              v-if="item.items"
              :key="item.name"
              :prepend-icon="item.icon"
              :active-class="darkTheme ? 'white--text' : ''"
          >
            <template v-slot:activator>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </template>

          </v-list-group>

          <!-- Top Level-->
          <v-list-item
              :disabled="item.disabled"
              :href="item.href"
              :key="item.name"
              :target="item.target"
              @click="Redirect(item)"
              v-else
          >
            <v-list-item-icon v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>




<!--        <v-list-item>
          <v-btn icon @click="$router.push('/salesCompraYVenta')">
            <v-icon>mdi-transit-connection-horizontal</v-icon>
          </v-btn>
        </v-list-item>-->
      </v-list>
<!--      <div class="pa-2">
        <v-btn v-bind="props" icon @click="users">
          <v-icon style="color: white">mdi-account-tie</v-icon>
        </v-btn>
      </div>-->
      <template  v-slot:append>
        <div class="pa-2">
          <v-btn icon @click="LogOut">
            <v-icon style="color: #014b98">mdi-logout</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </div>
</template>

<script>

import NotificationBar from "../../components/NotificationBar";
export default {
name: "Index",
  components:{
    NotificationBar
  },
  data: () => ({
    shipment: {
      deliveryTypeId: null,
      supplierId: null,
      ShipName: null,
      viaje: null,
      id: null,
      orderId: null,
      transportationId: null,
      transportationCategoryId: null,
      incoterm: null,
      incotermId: null,
      users: [],
      pol: null,
      pod: null,
      masterBl: {},
      origin: null,
      destination: null,
      shippingStatusId: null,
      orderFolio: null,
      containers: [],
      emails: [],
      routes: [],
      supplier: null,
    },
    drawer: null,
    UserRole:null,
    message: '',
    icons:[
      'mdi-comment-alert',
    ],
    menus: [
      {
        title: 'Usuarios',
        group: 'Users',
        icon: 'mdi-account',
        name: 'UserList',
      },

      {
        title: 'Ventas',
        group: 'Ventas',
        icon: 'mdi-account-tie',
        name: 'Sales',
      },

      {
        title: 'Pricing',
        group: 'Pricing',
        icon: 'las la-balance-scale',
        name: 'Pricing',

      },
      {
        title: 'Operaciones',
        group: 'Operaciones',
        icon: 'mdi-transit-connection-horizontal',
        name: 'operationList2',
      },
      {
        title: 'Analitica',
        group: 'Ventas',
        icon: 'mdi-monitor-dashboard',
        name: 'seniorAnalytics',
      },
    ],
    menuWidth:80, // Ancho de la barra lateral principal
    //mainLogo: require("../../assets/branding/Color logo - no background.png"), // Este es el logo que aparece en la barra lateral
    prominentLogo: true, // Logo pequeño o Grande
    logoBackColor: "primary", // Color de fondo para el logo de la barra lateral
    sideBackColor: "primary", // Color de fondo de la barra lateral
    darkTheme: true, // Tema obscuro ( letras en blanco en caso de colores obscuros de la barra lateral)
    appBarColor: "primary", // Color de la barra principal de la app
    appBarDark: true // Tema obscuro ( letras en blanco en caso de colores obscuros de la barra principal)
  }),
  methods:{
    toggleMarker () {
      this.$router.push('/Quote')
    },
    seeList(){
      this.$router.push('/operationList2')
    },
    seeList2(){
      this.$router.push('/Operation/list')
    },
    Redirect(item){
      let UserRole = this.$store.getters.roles;

      if (UserRole!='Ventas' && item.name == 'Quote'){
        this.$toast.info('Su usuario no puede crear cotizaciones');
      }

     else
        this.$router.push({name: `${item.name}`});
    },

    LogOut:function(){


      // this.$store.dispatch('session/reset');
      this.$store.commit('reset');

      this.$router.replace('/login')

      console.log("Token logOut: ",this.$store.getters.token);
      console.log("Name logOut: ",this.$store.getters.name);
      console.log("lastName logOut: ",this.$store.getters.lastname);

    },

    users:function(){


      // this.$store.dispatch('session/reset');

      this.$router.replace('/users')


    },

    GetPermissionMenu(){
      let UserRole = this.$store.getters.roles;
      this.menus = [];

      UserRole.includes('Pricing') ? this.menus.push({
        title: 'Pricing',
        group: 'Pricing',
        icon: 'mdi-book-multiple',
        name: 'Pricing',
      }) : null;

      UserRole.includes('Ventas') ? this.menus.push({
        title: 'Analitica',
        group: 'Ventas',
        icon: 'mdi-monitor-dashboard',
        name: 'seniorAnalytics',
      },{
        title: 'Ventas',
        group: 'Ventas',
        icon: 'mdi-book-multiple',
        name: 'Sales',
      },{
        title: 'Embarques',
        group: 'Ventas',
        icon: 'mdi-transit-connection-horizontal',
        name: 'operationList2',
      }) : null;

      UserRole.includes('Operaciones') ? this.menus.push({
        title: 'Operaciones',
        group: 'Operaciones',
        icon: 'mdi-transit-connection-horizontal',
        name: 'operationList2',
      }) : null;
    }
  },
  mounted() {
    this.GetPermissionMenu();
  }
}
</script>

<style scoped>

</style>
