<template>
  <v-row class="mt-4">
    <v-col :cols="esVendedor ? '12' : '8'">
      <v-card :disabled="shipment.shippingStatusId === 9">
        <v-row>
          <h4 class="mt-4 ml-7">Profit incial: {{
              shipment.sale.profitGoalAmount > 0 ?
                  shipment.sale.profitGoalAmount.toFixed(2) : '0'
            }}$</h4>
          <h4 class="mt-4 ml-2">{{
              !isNaN(shipment.sale.profitGoalPercentage) && shipment.sale.profitGoalPercentage > 0 ?
                  Number(shipment.sale.profitGoalPercentage).toFixed(2) : '0'
            }}%</h4>
          <h4 class="mt-4 ml-3">|</h4>
          <h4 class="mt-4 ml-3"
              :style="shipment.sale.realProfitAmount >= shipment.sale.profitGoalAmount ? 'color:green' : 'color:red'">
            Profit Actual: {{ shipment.sale.realProfitAmount ? shipment.sale.realProfitAmount.toFixed(2) : '0' }}$</h4>
          <h4 class="mt-4 ml-2"
              :style="shipment.sale.realProfitAmount >= shipment.sale.profitGoalAmount ? 'color:green' : 'color:red'">{{
              shipment.sale.realProfitPercentage > 0 ? shipment.sale.realProfitPercentage.toFixed(2) : '0'
            }}%</h4>
          <v-spacer></v-spacer>
          <h4 class="mt-4 mr-2">Compra Total (USD): ${{ shipment.totalPurchaseUsd.toFixed(2) }}</h4>
          <h4 class="mt-4 mr-2"
              :style="shipment.totalSaleUsd >= shipment.totalPurchaseUsd ? 'color:green' : 'color:red'">
            Venta Total (USD): {{ shipment.totalSaleUsd.toFixed(2) }}$</h4>
          <!--            <h4 class="mt-4 ml-2" :style="shipment.sale.totalSale >= shipment.totalPurchase ? 'color:green' : 'color:red'"> Venta Total: {{shipment.sale.totalSale}}$</h4>-->
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn icon v-on="on" color="primary" large fab @click.native.stop @click="updateSale"
                :disabled="!(isSaleValid && sales.isEditable)">
                <v-icon> mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Salvar cambios</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn icon v-on="on" color="primary" large fab @click.native.stop @click="dialogEdit = true"
                     :disabled="sales.isEditable">
                <v-icon> mdi-table-edit</v-icon>
              </v-btn>
            </template>
            <span>Editar venta</span>
          </v-tooltip>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table v-if="sales.details.length > 0" :headers="headers" :items="saleFees"
                          :hide-default-footer="false" show-select dense :item-class="bgColor">
              <template v-slot:header.data-table-select></template>
              <template v-slot:item.data-table-select="{ item }">
                <v-checkbox v-model="item.selected" @click="sendSaleFeeToInvoiceSummary(item)"
                            :disabled="item.disable || sales.isEditable || item.isSuggested"></v-checkbox>
              </template>
              <template v-slot:item.name="{ item }">
                  <v-combobox
                      v-model="item.saleConcept"
                      dense
                      label="Editando nombre"
                      single-line
                      :items="item.conceptItems"
                      :disabled="item.disable || !sales.isEditable"
                      :loading="item.searchConceptLoading"
                      @update:search-input="updateConceptSearch($event, item, shipment.client.id)"
                      @change="onChangeSaleFeeConcept($event, item)"
                      item-value="id"
                      item-text="name"
                      clearable
                      return-object
                  >
                  </v-combobox>
              </template>
              <template v-slot:item.quantity="{ item }">
                <v-text-field :rules="[numberInputValidation]" type="number" v-model="item.quantity" label="Editando cantidad"
                              single-line autofocus :disabled="item.disable || !sales.isEditable"></v-text-field>
              </template>
              <template v-slot:item.ratableWeight="{ item }">
                <v-text-field :rules="[numberInputValidation]" type="number" v-model="item.ratableWeight"
                              label="Editando Ratable Weight" single-line autofocus
                              :disabled="item.disable || !sales.isEditable"></v-text-field>
              </template>
              <template v-slot:item.price="{ item }">
                <v-text-field :rules="[numberInputValidation]" type="number" :return-value.sync="item.price"
                              v-model="item.price"
                              label="Editando venta" single-line autofocus
                              :disabled="item.disable || !sales.isEditable"></v-text-field>
              </template>
              <template v-slot:item.ventaProfit="{ item }">
                <div
                    v-if="(shipment.transportationId === 1 || shipment.transportationId === 3) && shipment.transportationCategoryId !== 9">
                  {{ (item.quantity * item.price).toFixed(2) }}
                </div>
                <div v-else>
                  {{ (item.ratableWeight * item.price).toFixed(2) }}
                </div>
              </template>
              <template v-slot:item.profit="{ item }">
                <v-text-field :return-value.sync="item.profit" v-model.number="item.profit" label="Editando profit"
                              single-line autofocus type="number"
                              :disabled="item.disable || !sales.isEditable"></v-text-field>
              </template>
              <template v-slot:item.ventaTotal="{ item }">
                <div
                    v-if="(shipment.transportationId === 1 || shipment.transportationId === 3) && shipment.transportationCategoryId !== 9">
                  {{ ((item.quantity * item.price) + item.profit).toFixed(2) }}
                </div>
                <div v-else>
                  {{ ((item.ratableWeight * item.price) + item.profit).toFixed(2) }}
                </div>
              </template>
              <template v-slot:item.total="{ item }">
                <v-text-field :return-value.sync="item.total" v-model="item.total" label="Editando venta" single-line
                              autofocus type="number" :disabled="item.disable || !sales.isEditable"></v-text-field>
              </template>
              <template v-slot:item.currency="{ item }">
                <v-select item-value="id" single-line :items="currencies2" item-text="code" v-model="item.currencyId"
                          style="width: 150px" @click="item" :disabled="item.disable || !sales.isEditable"></v-select>
              </template>
              <template v-slot:item.hasVat="{ item }">
                <v-checkbox v-model="item.hasVat" color="primary" disabled></v-checkbox>
              </template>
              <template v-slot:item.action="{ item, index }">
                <div v-if="item.isSuggested">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="success" icon v-bind="attrs" v-on="on" @click="addSuggestedFee(index)"
                             :disabled="!sales.isEditable || item.disable">
                        <v-icon>
                          mdi-playlist-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar fee sugerido</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-btn icon color="error" small @click="deleteFee(item, index)"
                         :disabled="!sales.isEditable || item.disable">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon color="info" small v-if="item.isLast" @click="addFee()" :disabled="!sales.isEditable">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <div class="flex d-flex justify-end">
              <v-btn v-if="!saleFees.some(fee => !fee.isSuggested)" icon color="primary" small
                 @click="addFee()">
              <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="12">
          <v-card :disabled="shipment.shippingStatusId === 9">
            <h4 class="mt-4 ml-4">Solicitudes de facturas</h4>
            <v-data-table :headers="headersSolicitudes" :items="itemsSolicitudes" :hide-default-footer="false"
                          @current-items="setFirstLast" dense class="mr-12 ml-12">
              <template v-slot:item.createAt="{ item }">
                {{ item.createAt | luxon }}
              </template>
              <template v-slot:item.detalle="{ item }">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn v-if="item.status !== 'Solicitado'" icon v-on="on" color="primary" @click="GeneateZip(item)"
                           large fab class="ml-2" @click.native.stop>
                      <v-icon> mdi-cloud-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar Factura</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn v-if="item.status !== 'Solicitado'" icon v-on="on" color="primary" @click="getDetail(item)"
                           large fab class="ml-2" @click.native.stop>
                      <v-icon> mdi-credit-card-off</v-icon>
                    </v-btn>
                  </template>
                  <span>Nota de credito</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" color="primary" @click="getDetail(item)" large fab class="ml-2"
                           @click.native.stop>
                      <v-icon> mdi-eye-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver detalle</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="!esVendedor" cols="4">
      <v-container>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header color="orange" expand-icon="" readonly>
              <h3 class="white--text">
                <v-icon color="white" class="mb-1 mr-1">mdi-arrow-right-bold-circle
                </v-icon>
                {{ summarie.status }}
              </h3>
              <v-spacer></v-spacer>
              <v-row class="justify-end mr-2">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" color="white" large class="mt-0 " @click="cleanSummarie()" @click.native.stop>
                      <v-icon> mdi-eraser</v-icon>
                    </v-btn>
                  </template>
                  <span>Limpiar solicitud</span>
                </v-tooltip>
              </v-row>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card elevation="0">
          <v-card-text>
            <v-row v-if="summarie.folio">
              <v-text-field readonly v-model="summarie.folio" label="Folio" class="ml-5 mr-5"></v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete label="Cliente" :items="clients" v-model="summarie.clientId" item-value="id"
                                item-text="name" required :loading="clientSearchLoading"
                                :search-input.sync="clientSearch"
                                @keydown.delete="clients = []"
                                @click="clients = []"
                                hide-no-data
                >
                </v-autocomplete>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                        transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="summarie.dueDate" label="Envio de factura" prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="getCurrentDate()" v-model="date" no-title scrollable color="primary">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-text-field 
              v-model="summarie.orderClientReference"
              label="Orden Cliente" 
              class="ml-3 mr-3" 
              :rules="[v => !!v || 'Favor de llenar la referencia del cliente']">
              </v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                    label="Tipo de moneda"
                    item-text="code"
                    disabled
                    item-value="code"
                    v-model="currencieSelected"
                    :items="currencies2"
                    dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
              :headers="headersSummarie"
              :items="summarie.fees"
              class="elevation-0"
              :item-class="bgColor2"
          >
            <template v-slot:item.purchaseFee="{ item }">
              <v-autocomplete
                  v-model="item.saleConcept"
                  no-filter
                  :search-input.sync="item.searchConcept"
                  @update:search-input="(e) => updateConceptSearch(e, item, summarie.clientId)"
                  @change="onChangeInvoiceFeeConcept($event, item)"
                  :loading="item.searchConceptLoading"
                  item-value="id"
                  item-text="name"
                  :items="item.conceptItems"
                  :label="item.feeLabel"
                  dense
                  class="mt-3"
                  return-object
                  :error-messages="item.conceptMessage"
              >
              </v-autocomplete>
            </template>
            <template v-slot:item.sendNC="{ item, index }">
              <v-checkbox :disabled="summarie.status !== 'Facturado' ||  item.NC === true"
                          v-model="item.NC"
                          @click="sendInvoiceFeeToNc(index)"></v-checkbox>
            </template>
            <template slot="body.append">
              <tr>
                <td>Subtotal</td>
                <td colspan="4" align="left" class="mr-2">{{totals.subTotal | toCurrency}}</td>
              </tr>
              <tr>
                <td>IVA</td>
                <td colspan="4" align="left" class="mr-2">{{totals.iva | toCurrency }}</td>
              </tr>
              <tr>
                <td>Retención</td>
                <td colspan="4" align="left" class="mr-2">{{totals.retencion | toCurrency}}</td>
              </tr>
              <tr class="font-weight-bold">
                <td>Total</td>
                <td colspan="4" align="left" class="mr-2">{{totals.total | toCurrency}}</td>
              </tr>
            </template>
          </v-data-table>
          <v-textarea name="input-7-1" label="Notas" v-model="notas"></v-textarea>
          <v-row>
            <v-col cols="12" style="min-height: 100px">
                <file-drag v-model="invoiceDocuments" class="mb-1"
                           :rules="filRule"
                           :loading-progress="invoiceDocsProgress"
                           :show-loading="showInvoiceDocsLoading"
                           required
                           @change="mapFileInvoice(invoiceDocuments)"></file-drag>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="offset-md-7">
              <v-btn small left="150px" color="error"
                     v-if="summarie.status === 'Solicitado'"
                     @click="dialog2 = true">Cancelar Factura
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="summarie.creditNotes.length > 0">
            <span class="mt-2 text-subtitle-2 font-weight-bold">Notas de Credito</span>
            <v-tabs
              v-model="tabCreditNote"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="(creditNote, index) in summarie.creditNotes" :key="`nc-tab-${index}`">
              <span class="my-auto text-caption font-weight-bold">NC {{ creditNote.id || 0 }}</span>
              <v-btn class="my-auto mx-2" icon small @click="onRemoveCreditNote(creditNote, index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-tab>

            <v-btn
                text
                @click="addCreditNote()"
                icon
                class="my-auto"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-tabs>
          <v-tabs-items v-model="tabCreditNote">
            <v-tab-item v-for="creditNote in summarie.creditNotes" :key="`nc-tab-item-${creditNote.id || 0}`">
              <v-card class="py-6 px-2 mx-0" color="grey lighten-5">
                <v-row>
                  <v-select v-if="summarie.status === 'Facturado' || summarie.status === 'Pago Completo'" class="mr-15 ml-6"
                            :items="creditNotesResons" item-value="id" label="Razon de nota de credito" item-text="name"
                            v-model="creditNote.cancellationReasonId"></v-select>
                </v-row>
                <v-col cols="12">
                  <v-data-table
                      :headers="headersSimple"
                      :items="creditNote.fees"
                      hide-default-footer
                      class="elevation-0"
                  >
                    <template v-slot:item.concepto="{ item }">
                      <v-autocomplete
                        v-model="item.saleConcept"
                        no-filter
                        :search-input.sync="item.searchConcept"
                        @update:search-input="updateConceptSearch($event, item, summarie.clientId)"
                        item-value="id"
                        item-text="name"
                        :items="item.conceptItems"
                        :loading="item.searchConceptLoading"
                        label="Concepto general"
                        dense
                        class="mt-3"
                        return-object
                      >
                      </v-autocomplete>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <v-text-field v-model.number="item.total"></v-text-field>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      <v-text-field v-model.number="item.quantity"></v-text-field>
                    </template>
                    <template v-slot:item.action="{ index }">
                      <v-btn icon color="error" small v-if="creditNote.fees.length > 1" @click="deleteNCitem(creditNote, index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn icon color="info" small @click="addCreditNoteFee(creditNote)">
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <td class="text-body-1">Subtotal</td>
                        <td class="text-body-1">{{ totalsNC.subtotal | toCurrency }}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-body-1">IVA</td>
                        <td class="text-body-1">{{ totalsNC.iva | toCurrency }}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-body-1">Ret. IVA</td>
                        <td class="text-body-1">{{ totalsNC.retIva | toCurrency }}</td>
                        <td></td>
                      </tr>
                      <!-- <tr>
                        <td class="text-body-1">Ret. ISR</td>
                        <td class="text-body-1">{{ totalsNC.retIsr | toCurrency }}</td>
                        <td></td>
                      </tr> -->
                      <tr class="font-weight-bold">
                        <td class="text-body-1">Total</td>
                        <td class="text-body-1 mr-2" colspan="4" align="left">{{ totalsNC.total | toCurrency }}</td>
                        <td></td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col>
                  <v-btn small class="mt-3 " color="error" @click="creditNoteDialog = true"
                     v-if="summarie.status === 'Facturado' || summarie.status === 'Pago Completo'"
                     :disabled="!isConcept || summarie.clientId === null || creditNote.cancellationReasonId === 0 || !validationCreditNote() || creditNote.id !== 0">
                      Generar Nota De Credito
                  </v-btn>
                </v-col>
              </v-card>
              <v-dialog v-model="creditNoteDialog" width="500">

                <v-card>
                  <v-card-title class="text-h5 red lighten-1" style="color: white">
                    Generacion de nota de credito
                  </v-card-title>
          
                  <v-card-text class="mt-4">
                    La siguiente acción afectará al Servicio de Administración Tributaria <b>(SAT)</b> en el momento en que la nota
                    de credito sea acepte sera timbrada y sera notificado el cliente. Es sumamente importante verificar
                    minuciosamente que la información que estás enviando sea completamente precisa.
                  </v-card-text>
          
                  <v-divider></v-divider>
          
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" style="color: white" @click="createCreditNote(creditNote)">
                      GENERAR NOTA DE CREDITO
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-tab-item>
          </v-tabs-items>
          </v-row>

          <!-- <hr v-if="summarie.payments.length > 0">
          <v-data-table v-if="summarie.payments.length > 0" :items="summarie.payments" :headers="headersPayments">
            <template slot="body.append">
              <tr>
                <th>Total</th>
                <th>${{ totalsPayments.paidAmount }}</th>
              </tr>
            </template>
            <template v-slot:item.paymentDate="{ item }">
              {{ item.paymentDate | luxon }}
            </template>
          </v-data-table>
          <div v-if="summarie.creditNotes.length > 0">
            <div class="d-flex justify-space-between">
              <h4>Notas de credito</h4>
            </div>
            <v-data-table v-for="(creditNote, index) in summarie.creditNotes" :key="`NC-${index}`"
                          :items="creditNote.fees"
                          :headers="headersCreditNotes">
              <template v-slot:header.cancelNC>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" color="error" @click="openCancelNC(creditNote.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Cancelar Nota de Credito</span>
                </v-tooltip>
              </template>
            </v-data-table>

          </div> -->
          <v-card-actions v-if="!esVendedor">
            <v-row class="mb-2">
              <!--              <v-btn
                  v-if="summarie.creditNotes.length > 0"
                  color="error"
                  block
                  @click="confirmCanselRequest = true"
              >Cancelar Nota de credito
              </v-btn>-->
              <div v-if="invoiceValidations.length > 0" class="red lighten-5 my-2 pa-2">
                <v-row v-for="validation in invoiceValidations" :key="`nc-validation-${validation}`">
                  <v-col cols="12" class="pl-4">
                    <v-icon color="red">mdi-alert-circle</v-icon>
                    <span class="red--text">{{ validation }}</span>
                  </v-col>
                </v-row>
              </div>
              <v-btn color="primary" @click="confirmRequest = true" block v-if="summarie.fees.length >= 1 &&
                summarie.status === 'No hay solicitud' && !esVendedor"
                     :disabled="summarie.clientId === null || summarie.fees.length === 0 || !isConcept">
                Crear Factura
              </v-btn>

              <v-btn class="mt-3" color="primary" block v-if="summarie.status === 'Creada'">Reclamo
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-col>
    <v-dialog v-model="dialog2" width="500">

      <v-card>
        <v-card-title class="text-h5 red lighten-1" style="color: white">
          Cancelación de factura
        </v-card-title>

        <v-card-text class="mt-4">
          SI LA SOLICITUD ESTA FACTURADA SERÁ AFECTADO INSTANTÁNEAMENTE EL <b>SAT</b>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" style="color: white" @click="cancelInvoice">
            GENERAR CANCELACIÓN
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="800">
      <v-card width="800">
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-file-edit</v-icon>
            Editar venta
          </h2>

          <v-spacer></v-spacer>
          <v-btn icon dark @click="cleanEdit()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-textarea outlined label="Comentarios" class="mt-4" v-model="edit.comments" required></v-textarea>
            <v-col cols="12">
              <file-drag v-model="filesDrag2" @change="ConvertFile()" required></file-drag>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="cleanEdit()">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="editVenta()" :disabled="!edit.comments">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmRequest" max-width="500">
      <v-card>
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25" class="mr-2">mdi-send</v-icon>
            Crear Factura
          </h2>

          <v-spacer></v-spacer>
          <v-btn icon dark @click="confirmRequest = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          LA SOLICITUD SE ESTARA TIMBRANDO INSTANTANIAMENTE AL <b>SAT</b> "FAVOR DE REVISAR A DETALLE"
        </v-card-text>

        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="confirmRequest = false">
            CANCELAR
          </v-btn>
          <v-btn color="primary" @click="createInvoice()" :disabled="disableCreateInvoice">
            CREAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmCanselRequest" max-width="500">
      <v-card>
        <v-toolbar app dark class="error white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25" class="mr-2">mdi-send</v-icon>
            Cancelar Nota de Credito
          </h2>

          <v-spacer></v-spacer>
          <v-btn icon dark @click="confirmCanselRequest = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          LA SOLICITUD SE ESTARA CANCELANDO INSTANTANIAMENTE AL <b>SAT</b> "FAVOR DE REVISAR A DETALLE"
        </v-card-text>

        <v-card-actions elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="confirmCanselRequest = false">
            CANCELAR
          </v-btn>
          <v-btn color="primary" @click="canselarInvoce()">
            CREAR CANCELACION
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FileDrag from "@/components/Global/FileDrag/FileDrag";
import debouncePromise from "debounce-promise";
import {DateTime} from "luxon";
import {getS3} from "@/composables/filesS3";

var fileDownload = require('js-file-download')

export default {
  name: "ventaBL",
  components: {
    FileDrag,

  },
  props: {
    esVendedor: {
      type: Boolean,
      default: false,
    },
    shipment: {
      type: Object,
    },
  },
  data: (vm) => ({
    tabCreditNote: 0,
    invoiceDocsProgress: 0,
    showInvoiceDocsLoading: false,
    invoiceValidations: [],
    clientSearchLoading: false,
    isSaleValid: true,
    elSuperTotal2: 0,
    invoiceDocuments: [],
    documents: [],
    dateTime: DateTime,
    exampleRules: [
      v => (v && v >= 1) || "Min tiene que valer 1",
    ],
    filRule: [(v) => (v && v.length > 0) || "Porfavor llene el campo"],
    indexConcept2: null,
    totalProfit: 0,
    guardoIndex: null,
    dialog2: false,
    creditNoteDialog: false,
    confirmCanselRequest: false,
    oldSearch: null,
    notas: "",
    currencieSelected: "",
    invoceID: null,
    attachmentsId: null,
    indexConcept: null,
    menu: false,
    isConcept: null,
    isConcept2: null,
    itemsClientes: [],
    clients: [],
    clientSearch: null,
    creditNotesResons: [
      {
        name: 'Nota de crédito de los documentos relacionados',
        id: 1,
      },
      {
        name: 'Nota de débito de los documentos relacionados',
        id: 2,
      },
      {
        name: 'Devolución de mercancía sobre facturas o traslados previos',
        id: 3,
      },
      {
        name: 'Sustitución de CFDI previos',
        id: 4,
      },
      {
        name: 'Traslados de mercancías facturadas previamente',
        id: 5,
      },
      {
        name: 'Factura generada por los traslados previo',
        id: 6,
      },
      {
        name: 'CFDI por aplicación de anticipo',
        id: 7,
      },
    ],
    currencies2: [
      {
        code: 'USD',
        id: 1,
        name: 'US dollar',
        status: true,

        symbol: '$'
      },
      {
        code: 'EUR',
        id: 34,
        name: 'Euro',
        status: true,
        symbol: '€'
      },
      {
        code: 'MXN',
        id: 27,
        name: 'Mexican peso',
        status: true,
        symbol: '$'
      },
    ],
    Currencies: [],
    concepts: [],
    conceptSearch: null,
    rfc: "",
    edit: {
      comments: "",
      documents: [],
    },
    saveComment: "",
    filesDrag2: [],
    dialogEdit: false,
    confirmRequest: false,
    disableCreateInvoice: false,
    date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    itemsInvoiceTo: [],
    summarie: {
      itemsNC: [],
      creditNotes: [],
      folio: null,
      payments: [],
      shippingSaleId: 0,
      clientId: null,
      razonSocial: 'string',
      message: "string",
      dueDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fees: [],
      status: "No hay solicitud",
      searchInvoiceTo: null,
      documents: [],
      orderClientReference: ""
    },
    itemsSolicitudes: [],
    headersCreditNotes: [
      {
        text: 'Cargo',
        value: 'charge'
      },
      {
        text: 'Cantidad',
        value: 'quantity'
      },
      {
        text: 'Venta total',
        value: 'total'
      },
      {
        text: 'Cancelar NC',
        value: 'cancelNC'
      }
    ],
    headersPayments: [
      {
        text: 'Id de Pago',
        value: 'id',
      },
      {
        text: 'Pagado',
        value: 'paidAmount',
      },
      {
        text: 'Por Pagar',
        value: 'pendingAmount',
      },
      {
        text: 'Fecha de pago',
        value: 'paymentDate',
      }
    ],
    headersSimple: [
      {
        text: "Concepto General",
        value: "concepto",
      },
      {
        text: "Cantidad",
        value: "quantity",
      },
      {
        text: "Venta",
        value: "total",
      },
      {
        text: "",
        value: "action",
      },
    ],
    headersSummarie: [
      {
        text: "Concepto General",
        value: "purchaseFee",
      },
      {
        text: "Cantidad",
        value: "quantity",
      },
      {
        text: "Venta",
        value: "total",
      },
      {
        text: 'NC',
        value: "sendNC"
      }
    ],
    elSuperTotal: 0,
    headersSolicitudes: [
      {
        text: "Id de solicitud",
        value: "id",
      },
      {
        text: "Folio",
        value: "refCode",
      },
      {
        text: "Cliente",
        value: "client",
        align: "center",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Total",
        value: "total",
      },
      {
        text: "Fecha de creación",
        value: "createAt",
      },
      {
        text: "Total NC",
        value: "totalCreditNotes",
      },
      {
        text: "",
        value: "detalle",
        align: "right",
      },
    ],
    headers: [
      {
        text: "Cargo",
        value: "name",
      },
      {
        text: "Cantidad",
        value: "quantity",
      },
      {
        text: "Ratable weight",
        value: "ratableWeight",
      },
      {
        text: "Compra",
        value: "price",
      },
      {
        text: "Profit",
        value: "profit",
      },
      {
        text: "Venta",
        value: "total",
      },
      {
        text: "Moneda",
        value: "currency",
      },
      {
        text: "IVA",
        value: "hasVat",
      },
      {
        text: "Folio de Factura",
        value: "invoiceRefCode",
      },
      {
        text: "",
        value: "action",
      },
    ],
    sales: {
      details: [],
    },
    saleFees: [],
    cancelNCId: null,
    mini: !vm.$vuetify.breakpoint.xl,
  }),
  methods: {
    validationCreditNote() {
      return this.summarie.creditNotes.some(creditNote => creditNote.id === 0)
    },
    addCreditNote(itemsNcFees = []) {

    if(itemsNcFees.length === 0) itemsNcFees.push({
        id: 0,
        invoiceFeeId: null,
        invoiceCancellationId: 0,
        saleConcept: {},
        charge: '',
        quantity: 1,
        total: 0,
        saleAmount: 0,
        currencyId: 0,
        conceptItems: []
      })


    this.summarie.creditNotes.push({
        id: 0,
        cancellationReasonId: 0,
        invoiceId: this.summarie.invoiceId,
        ClientId: this.summarie.clientId,
        BusinessName: '',
        InvoiceUuid: '',
        Xml: '',
        Pdf: '',
        fees: itemsNcFees,
      })
    },
    onRemoveCreditNote(creditNote, index) {
      if(creditNote.id === 0) {
        return this.summarie.creditNotes.splice(index, 1)
      }
      this.$toast.error("Actualmente, no está soportado eliminar notas de crédito ya creadas. Favor contactar a soporte técnico.");
    },
    mapFileInvoice(invoiceDocuments) {
      if (invoiceDocuments.length == 0) return this.summarie.documents = []
      invoiceDocuments.map((document) => {
        let invoiceDocuments = {
          fileName: document.name,
          path: null,
          id: 0,
          invoiceId: this.summarie.invoiceId ? this.summarie.invoiceId : 0
        }
        this.summarie.documents.push(invoiceDocuments)
      })
    },
    setIva(subTotal, concept) {
        if (concept?.includes('*')) {
          if(concept?.includes('*/')){
            let ivaAcreditable = subTotal * 0.25
            return ivaAcreditable * 0.16
          }
          return subTotal * 0.16
        }
        return 0
    },
    setRetencion(subTotal, concept) {
      if (concept?.includes('^')) {
        return subTotal * 0.04
      }
      return 0
    },
    setRetencionISR(subTotal, concept) {
      if (concept?.includes('RESICO')) {
        return subTotal * 0.0125
      }
      return 0
    },
    numberInputValidation(value) {
      const isGreaterZero = value > 0
      if(isGreaterZero) {
        this.isSaleValid = true
        return true
      }

      this.isSaleValid = false
      return 'Tiene que ser mayor a 0'
    },
    async updateConceptSearch(val, item, clientId) {
      item.searchConceptLoading = true
      if (!item.saleConcept) item.conceptItems = []
      const selectedConcept = item.conceptItems?.find(x => x.name === val)
      if (selectedConcept?.name === val) {
        item.searchConceptLoading = false
        return
      }
      const concepts = await this.searchConcepts(val, clientId)
      item.conceptItems = concepts.filter(concept => concept.currencyId === item.currencyId)
      item.searchConceptLoading = false
    },
    onChangeSaleFeeConcept(concept, item){
      item.saleConceptId = concept.id
      item.currencyId = concept.currencyId

      if(concept.ivaAcreditable > 0){
        item.hasVat = true
      }
    },
    onChangeInvoiceFeeConcept(concept, item){
      item.saleConceptId = item.saleConcept.id
      if(concept.name.contains('*')){
        item.hasVat = true
      }

      // if(concept.ivaAcreditable > 0){
      //   item.hasVat = true
      // }
    },
    getCurrentDate() {
      return new Date().toISOString().substr(0, 10); // Returns current date in 'YYYY-MM-DD' format
    },
    getClients(search) {
      this.clients = []
      this.$http.get(`Companies/find`, {
            params: {
              name: search,
            }
          }
      ).then((response) => {
        this.clients = response.data.data
      })
    },
    deleteNCitem(creditNote, index) {
      this.summarie.fees.map(x => {
          x.NC = x.id === creditNote.fees[index].invoiceFeeId ? false : x.NC
      })
      creditNote.fees.splice(index, 1);
    },
    sendInvoiceFeeToNc(index) {
      let obj = this.summarie.fees[index]

      let creditNoteFee = {
        id: 0,
        invoiceFeeId: obj.id,
        saleConcept: obj.saleConcept,
        currencyId: obj.saleConcept.currencyId,
        searchConcept: obj.searchConcept,
        conceptItems: obj.conceptItems,
        quantity: obj.ratableWeight > obj.quantity ? obj.ratableWeight : obj.quantity,
        total: obj.total,
        saleAmount: obj.total
      }
      if (this.summarie.creditNotes[this.tabCreditNote] === undefined) {
        this.addCreditNote([creditNoteFee])
        return
      } else if(this.summarie.creditNotes[this.tabCreditNote].id !== 0) {
        this.addCreditNote([creditNoteFee])
        return
      }
      this.summarie.creditNotes[this.tabCreditNote].fees.push(creditNoteFee);
    },
    openCancelNC(id) {
      console.log(id)
      this.cancelNCId = id;
      this.confirmCanselRequest = true
    },
    clearInvoiceTo() {
      this.itemsInvoiceTo = []
    },
    searchInvoiceTo: debouncePromise(function (search) {
      if (this.itemsInvoiceTo.length > 0) return
      this.getAllSupplyers(search);
    }, 1000),
    getAllSupplyers(search) {
      this.itemsInvoiceTo = []
      this.$http.get(`Suppliers/all/search`, {
            params: {
              businessPartnerName: search,
            }
          }
      ).then((response) => {
        let aux = response.data.data
        if (aux === null) {
          console.log(aux)
        } else {
          aux.forEach((element) => {
            this.itemsInvoiceTo.push(element)
          })
        }
      })
    },
    /*getClientes() {
      this.$http("Companies")
          .then((response) => {
            this.itemsClientes = response.data.data;
          })
          .finally();
    },*/
    ChaingeConsept(item) {
      let index = this.itemsConcept.findIndex(
          (x) => x.id === item.purchaseFeeId
      );
      item.purchaseFee = JSON.parse(JSON.stringify(item));
      delete item.purchaseFee.purchaseFee;
      delete item.purchaseFee.container;
      delete item.purchaseFee.currency;
      delete item.purchaseFee.discount;
      delete item.purchaseFee.discountPercentage;
      delete item.purchaseFee.isFirst;
      delete item.purchaseFee.isLast;
      delete item.purchaseFee.profit;
      delete item.purchaseFee.profitPercentage;
      delete item.purchaseFee.purchaseFeeId;
      delete item.purchaseFee.salesDetailId;
      item.purchaseFee.purchaseDetailId = this.sales.details[0].id;

      item.currency = this.itemsConcept[index].currencyId;
      item.hasRet = this.itemsConcept[index].retIsr;
      item.hasVat = this.itemsConcept[index].retIva;
      console.log(item);
    },


    canselarInvoce() {
      this.$store.dispatch("working", true);
      this.$http
          .post(
              `shipping/invoices/${this.summarie.invoiceId}/cancellation/${this.cancelNCId}/cancel`,
          )
          .then((response) => {
            if (response.data.code === 400) {
              this.$toast.error("Algo salio mal, intente mas tarde");
            } else {
              this.$toast.info("Nota de credito cancelada");
            }
            console.log(response);
          }).catch((error) => {
        console.log(error)
        this.$toast.error("Algo salio mal, intente mas tarde");
      }).finally(() => {
        this.cancelNCId = null;
        this.$store.dispatch("working", false);
        this.confirmCanselRequest = false;
        this.getSale();
        this.cleanEdit();
        setTimeout(() => {
          this.getInvoces();
          this.cleanSummarie();
        }, 1500);
      })
    },


    createCreditNote(creditNote) {
      if (this.summarie.dueDate === "") {
        this.$toast.error("Favor de llenar la fecha!");
        return
      }

      let totalPR = 0
      let totalNC = 0
      this.summarie.fees.forEach(x => totalPR += x.total)
      this.summarie.creditNotes.forEach(nc => nc.fees.forEach(f => totalNC += f.total))
      if (totalPR < totalNC) {
        this.$toast.error("El total de la Nota de Credito no puede ser mayor que el total de la factura");
        return
      }
      this.$store.dispatch("working", true);
      creditNote.fees.forEach((element) => {
        element.charge = element.searchConcept;
        element.saleConceptId = element.saleConcept.id;
        element.invoiceCancellationId = 0;
        element.saleConcept = null;
      });

      this.$http
          .post(`shipping/${this.shipment.id}/invoice/${this.summarie.invoiceId}/InvoiceCancellation`, creditNote)
          .then((response) => {
            if (response.data.code === 400) {
              this.$toast.error("Algo salio mal, intente mas tarde");
              this.creditNoteDialog = false;
            } else {
              this.$toast.info("Solicitud creada con exito");
              this.creditNoteDialog = false;
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
            this.creditNoteDialog = false;
          })
          .finally(() => {
            this.$store.dispatch("working", false);
            setTimeout(() => {
              this.getInvoces();
              this.cleanSummarie();
            }, 1500);
          });
    },

    cancelInvoice() {
      console.log(this.summarie.invoiceId);
      delete this.summarie.id;
      delete this.summarie.status;
      delete this.summarie.SupplierId;
      delete this.summarie.TotalAdvance;
      this.summarie.fees.forEach((element) => {
        delete element.saleConcept;
        delete element.currency;
        element.cargo = element.name;
        delete element.name;
        element.saleAmount = element.cargo;
        delete element.cargo;
        element.cantidad = element.quantity;
        delete element.quantity;
      });
      const config = {headers: {'Content-Type': 'application/json'}};
      this.$store.dispatch("working", true);
      this.$http
          .post(
              `shipping/invoice/${this.summarie.invoiceId}/cancel`, "'soy un string'", config)
          .then((response) => {
            if (response.data.code === 200) {
              this.dialog2 = false;
              this.$toast.info("Solicitud cancelada con exito");
            } else {
              this.$toast.error("Algo salio mal, intente mas tarde");
            }
          }).catch((error) => {
          if(error.response.data.code === 400){
            this.$toast.error(error.response.data.message[0]);
          }else {
            this.$toast.error("Algo salio mal, intente mas tarde");
          }
      }).finally(() => {
        this.$store.dispatch("working", false);
        this.cleanSummarie();
        this.getSale();
        console.log('tiro store dispatch')
      })
    },

    getConcept(search, clientId) {
      return new Promise((resolve) => {
        this.$http.get(`shipping/sale/concepts?name=${search}&clientId=${clientId}`).then((response) => {
          resolve(response.data.data)
        })
      })
    },
    getCurrencies() {
      // this.$store.dispatch("working", true);
      this.$http.get("currencies").then((response) => {
        if (response.data.code == 200) {
          this.Currencies = response.data.data;
        }
        console.log(this.Currencies);
      });
    },
    getRfc() {
      this.$http
          .get(`Companies/${this.shipment.client.id}/names`)
          .then((response) => {
            console.log(response);
            this.rfc = response.data.data;
          });
    },
    deleteFee(item, elIndex) {
      if (item.id === 0) {
        console.log(elIndex)
        this.saleFees.splice(elIndex, 1)
        //this.sales.details[0].fees.splice(elIndex, 1)
        this.saleFees = this.saleFees.map((element, index) => {
          return {
            ...element,
            isFirst: index === 0,
            isLast: index === this.saleFees.findLastIndex((x) => !x.isSuggested),
          }
        })
        return
      }
      // let index = this.prices.findIndex(x=> x.id === this.shipmentId)
      // el codigo de arriba fue donde mireles me enseño -Quintero
      // realmente lo de abajo no se necesita porque ya tengo el id del fee pero era para practicar xd
      console.log(item);
      let index = this.saleFees.findIndex((x) => x.id === item.id);
      if (index === -1) {
        this.$toast.error("No se pudo eliminar");
      } else {
        this.$http
            .delete(
                `shipping/${this.shipment.id}/sale/details/${item.salesDetailId}/fee/${item.id}`
            )
            .then((response) => {
              console.log(response);
            }).catch((error) => {
          console.log(error)
        }).finally(() => {
          setTimeout(() => {
            this.getSale()
          }, 1000)
        })
      }
    },
    addFee() {
      let defaultFee = {
        conceptItems: [],
        containerId: 1,
        currencyId: 1,
        currency: {"name": "US dollar", "code": "USD", "symbol": "$", "id": 1, "status": true},
        discount: 0,
        discountPercentage: 0,
        hasVat: false,
        ventaProfit: 1,
        ventaTotal: 1,
        id: 0,
        name: "Default Cargo",
        price: 1,
        profit: 0,
        profitPercentage: 0,
        quantity: 1,
        ratableWeight: 1,
        salesDetailId: this.sales.details[0].id,
        total: 1,
        isLast: true,
        isSuggested: false,
      };

      const existingFees = this.saleFees.map(fee => {
        return {
          ...fee,
          isLast: false
        }
      })
      this.saleFees = [...existingFees, defaultFee]
    },
    addCreditNoteFee(creditNote) {
      let creditNoteFee = {
        id: 0,
        invoiceFeeId: null,
        invoiceCancellationId: 0,
        saleConcept: {},
        charge: '',
        quantity: 1,
        total: 0,
        saleAmount: 0,
        currencyId: this.summarie.fees[0].saleConcept.currencyId,
        conceptItems: []
      }
      creditNote.fees.push(creditNoteFee)
    },
    UploadFile: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    ConvertFile: async function () {
      let documents = this.filesDrag2;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(",")[1];
        const dotPosition = doc.name.lastIndexOf(".");
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName =
            dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.edit.documents.push({
          id: 0,
          documentTypeId: 103,
          file: data,
          fileName: fileName,
          fileExtension: fileExt,
        });
        /*  this.edit.documents.documentTypeId = 103;
        (this.edit.documents.file = data),
          (this.edit.documents.fileName = fileName);
        this.edit.documents.fileExtension = fileExt; */
      }
      this.docLleno = true;
      console.log(this.edit);
    },
    editVenta() {
      this.$store.dispatch("working", true);
      this.$http
          .post(
              `shipping/${this.shipment.id}/sale/${this.sales.id}/change`,
              this.edit
          )
          .then((response) => {
            if (response.data.code === 400) {
              this.$toast.error("Algo salio mal, intente mas tarde");
            } else {
              this.$toast.info("Ahora puede editar");
            }
            console.log(response);
          }).catch((error) => {
        console.log(error)
        this.$toast.error("Algo salio mal, intente mas tarde");
      }).finally(() => {
        this.$store.dispatch("working", false);
        this.getSale();
        this.cleanEdit();
        setTimeout(() => {
          this.getInvoces();
          this.cleanSummarie();
        }, 1500);
      })
    },
    cleanEdit() {
      this.$refs.form.reset();
      this.filesDrag2 = [];
      this.saveComment = "";
      this.edit.documents = [];
      this.comments = "";
      this.dialogEdit = false;
    },
    //ready V2
    updateSale() {
      this.$store.dispatch("working", true);
      const saleDetails = this.sales.details.map(detail => {
        const detailFees = this.saleFees.filter(fee => !fee.isSuggested && fee.salesDetailId === detail.id)

        const fees = detailFees.map(fee => {

          if (fee.ratableWeight > 1) {
            fee.total = fee.ratableWeight * parseInt(fee.price) + fee.profit
            fee.saleAmount = fee.ratableWeight * parseInt(fee.price) + fee.profit
            fee.quantity = 1;
          } else {
            fee.total = fee.quantity * parseInt(fee.price) + fee.profit
            fee.saleAmount = fee.quantity * parseInt(fee.price) + fee.profit
            fee.ratableWeight = 1;
          }

          delete fee.conceptItems
          delete fee.isFirst
          delete fee.isLast
          delete fee.saleConcept
          return fee
        })

        return {
          ...detail,
          fees
        }
      })

      const saleUpdateRequest = this.sales
      if(saleDetails.some(detail => detail.fees.some(fee => fee.currencyId === null))){
        this.$toast.error("Favor de seleccionar una moneda para todos los cargos")
        return this.$store.dispatch("working", false)
      }
      saleUpdateRequest.details = saleDetails

      delete saleUpdateRequest.detailsFees


      console.log(saleUpdateRequest)

      this.$http
          .put(`Shipping/${this.shipment.id}/sale/${this.sales.id}`, saleUpdateRequest)
          .then((response) => {
            this.$store.dispatch("working", false);
            if (response.data.code === 400) {
              const jsonString = JSON.stringify(response);
              const targetMessage = 'Shipping route must be created first';
              if (jsonString.includes(targetMessage)) {
                this.$toast.error("Favor de llenar todos los eventos de la ruta (evento, origen y fecha)");
              } else {
                this.$toast.error("Algo salio mal");
              }
            } else {
              this.$toast.info("Venta Actualizada");
            }
          }).catch((error) => {
        console.log(error)
        this.$toast.error("Algo salio mal, intente mas tarde");
      }).finally(() => {
        this.getSale(true)
        this.getInvoces();
        this.cleanSummarie();
        let item = {
          id: this.shipment.id
        }
        this.$emit('updateCargo', item);
        this.$store.dispatch("working", false);
      })
    },
    bgColor2(item) {
      console.log('bgcolor', item)
      if (item.feeStatusPaymentId === 1) {
        return 'red lighten-4'
      }
      if (item.feeStatusPaymentId === 2) {
        return 'green lighten-4'
      }
    },
    bgColor(item) {
      return item.isSuggested ? 'yellow lighten-4' : '';
    },
    validationCreditNoteFees(invoiceFee, creditNotes){
      return creditNotes.some(creditNote => creditNote.fees.some(creditNoteFee => creditNoteFee.invoiceFeeId === invoiceFee.id))
    },
    getDetail(item) {
      this.$store.dispatch("working", true);
      this.cleanSummarie();
      console.log(item);
      console.log(item.id);
      this.summarie.invoiceId = item.id;
      this.$http
          .get(
              `shipping/${this.shipment.id}/sale/${this.sales.details[0].shippingSaleId}/invoice/${item.id}`
          )
          .then((response) => {
            this.invoceID = response.data.data.id;
            // this.attachmentsId = response.data.data.attachments.id;
            if (response.data.data.creditNotes.length > 0) {
              this.summarie.creditNotes = response.data.data.creditNotes.map(creditNote => {
                return {
                  ...creditNote,
                  fees: creditNote.fees.map(fee => {
                    return {
                      ...fee,
                      currencyId: fee.saleConcept.currencyId,
                      conceptItems: [{
                        id: fee.saleConceptId,
                        name: fee.charge
                      }],
                      searchConcept: fee.charge,
                    }
                  })
                }
              });
            } else {
              this.summarie.creditNotes = [];
            }
            this.summarie.folio = response.data.data.attachments ? response.data.data.attachments.folio : null;
            this.summarie.clientId = response.data.data.clientId
            this.summarie.orderClientReference = response.data.data.orderClientReference
            this.summarie.fees = response.data.data.fees;
            this.currencieSelected = response.data.data.fees[0].saleConcept.currency.code;
            console.log(this.currencieSelected)
            this.summarie.payments = response.data.data.payments;
            this.summarie.fees = this.summarie.fees.map((element, index) => {
              let obj = {
                name: this.summarie.fees[index].saleConcept.description,
                id: this.summarie.fees[index].saleConceptId
              }
              let arr = []
              arr.push(obj)
              return {
                ratableWeight: 0,
                ...element,
                conceptItems: arr,
                elTotal: 0,                searchConcept: this.summarie.fees[index].saleConcept.description,
                ventaTotal: 0,
                NC: this.validationCreditNoteFees(element, this.summarie.creditNotes)
              }
            })
            this.invoiceDocuments = response.data.data.documents.map((document) => {
              return {
                name: document.fileName,
              }
            })
            this.summarie.status = response.data.data.statusInvoice.description;
            let date = new Date(response.data.data.createdAt);
            this.date = date.toISOString().substr(0, 10);
            this.summarie.fees.forEach((element) => {
              //this.summarie.fees[index].conceptItems.push(this.summarie.fees[index].saleConcept)
              element.name = element.cargo;
              delete element.cargo;
              /* if (20221214 <= fechaNum) {
                 element.total = element.cantidad * element.saleAmount;
               } else {
                 element.total = element.saleAmount;
               }*/
              element.total = element.saleAmount;
              delete element.saleAmount;
              element.quantity = element.cantidad;
              element.ratableWeight = element.cantidad
              delete element.cantidad;
              element.elTotal = element.saleAmount
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },

    GeneateZip(item) {
      this.$store.dispatch("working", true);
      this.$http
      this.$http({
        url: `Shipping/${this.shipment.id}/invoice/${item.id}/attachments`,
        method: "GET",
        responseType: "blob", // important
      })
          .then((response) => {
            console.log(response.data.data);
            fileDownload(response.data, `${this.shipment.refCode}.zip`);
            this.$toast.info('Pdf Generado');
            //var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          })
          .catch(() => {
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    validateInvoice(invoice){
      console.log("=>(ventaBL.vue:1558) invoice", invoice);

      this.invoiceValidations = []
      // if(invoice.documents.length === 0){
      //   this.invoiceValidations.push('Favor de subir un archivo. POD o documentos que sirvan como comprobante de facturación')
      // }
      if(invoice.clientId === null){
        this.invoiceValidations.push('Favor de seleccionar un cliente')
      }
    },
    loadingInvoiceDocs() {
      this.invoiceDocsInterval = setInterval(() => {
        this.invoiceDocsProgress += 25
      }, 1000)
    },
    async createInvoice() {
      this.disableCreateInvoice = true
      if (this.summarie.dueDate === "") return this.$toast.error("Favor de llenar la fecha.");
      if (this.summarie.orderClientReference === "") return this.$toast.error("Favor de llenar la referencia del cliente.");
        
        // this.$store.dispatch("working", true);
        let createInvoiceRequest = {
          shippingSaleId: this.sales.details[0].shippingSaleId,
          razonSocial: this.summarie.razonSocial,
          message: this.notas === "" ? 'Favor facturar' : this.notas,
          dueDate: this.summarie.dueDate,
          clientId: this.summarie.clientId,
          orderClientReference : this.summarie.orderClientReference,
          fees: [],
          documents: this.summarie.documents
        };

        this.summarie.fees.forEach((element) => {
          createInvoiceRequest.fees.push({
            id: 0,
            invoiceId: 0,
            currencyId: element.currencyId,
            saleConceptId: element.saleConceptId,
            cargo: element.feeLabel,
            cantidad: element.ratableWeight > element.quantity ? element.ratableWeight : element.quantity,
            saleAmount: element.elTotal ?? element.total,
            saleFeeId: element.saleFeeId,
          });
        });

        this.validateInvoice(createInvoiceRequest)

        console.log("=>(ventaBL.vue:1599) this.invoiceValidations", this.invoiceValidations);
        if(this.invoiceValidations.length > 0){
          this.$toast.error("Algunas validaciones no se cumplieron para crear la factura")
          this.confirmRequest = false
          this.disableCreateInvoice = false

          return
        }

        this.$http
            .post(`Shipping/${this.shipment.id}/invoice/create`, createInvoiceRequest).then(async (response) => {
              if (response.data.code === 200) {
                this.$toast.info("Solicitud creada con exito");
                if(response.data.data.documents.length > 0){
                  this.invoiceDocsInterval = setInterval(() => {
                    if(this.invoiceDocsProgress >= 100) {
                      this.showInvoiceDocsLoading = false
                      clearInterval(this.invoiceDocsInterval)
                    }
                    this.invoiceDocsProgress += 25
                  }, 200)
                  for (const doc of response.data.data.documents) {
                    for (const file of this.invoiceDocuments) {
                      if(doc.fileName === file.name){
                        await getS3(doc.path, file).catch((error) => {
                          this.$toast.error("Ocurrio un error al subir el archivo", error)
                        })
                      }
                    }
                  }
                }
              } else {
                this.$toast.error("Ocurrio un error");
              }
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
              this.$toast.error("Ocurrio un error");
            })
            .finally(() => {
              this.$store.dispatch("working", false);
              this.confirmRequest = false
              this.getInvoces();
              this.cleanSummarie();
              this.disableCreateInvoice = false
            });
    },
    cleanSummarie2(item) {
      console.log(item)
      /*this.sales.details.forEach((element) => {
        element.fees.forEach((fee) => {
          if (fee.id !== item.id) {
            fee.selected = false;
            this.currencieSelected = item.currency.code
          } else {
            this.currencieSelected = "";
          }
        });
      });*/
      this.summarie.folio = null
      this.currencieSelected = "";
      this.summarie.fees = [];
      this.summarie.payments = [];
      this.summarie.clientId = null;
      this.summarie.SupplierId = null;
      this.summarie.freightForwarderId = null;
      this.summarie.total = 0;
      this.summarie.invoiceIssuedAt = null;
      this.summarie.TotalAdvance = 0;
      this.summarie.payTo = "";
      this.summarie.status = "No hay solicitud";
      this.DueDate = "2022-01-18";
      this.dateOfPayment = "2022-01-18";
      this.date = this.summarie.DueDate;
      this.invoiceNumber = this.summarie.invoiceNumber;
      this.sFecha = true;
      this.eEmbarque = false;
      this.summarie.documents = [];
      this.fileDrag4 = [];
    },
    sendSaleFeeToInvoiceSummary(item) {
      console.log("=>(ventaBL.vue:1563) item", item);
      let isDifferent = false
      if (item.selected) {
        this.summarie.payments = [];
        this.summarie.orderClientReference = this.shipment.referenceNumber ? this.shipment.referenceNumber : '';

        const invoiceFee = {
          searchConcept: item.name,
          feeLabel: item.name,
          quantity: item.ratableWeight > item.quantity ? item.ratableWeight : item.quantity,
          total: item.total,
          currencyId: item.currencyId,
          saleFeeId: item.id,
          saleConcept: item.saleConcept,
          saleConceptId: item.saleConceptId,
          conceptItems: item.conceptItems,
          price: item.price,
          profit: item.profit
        }

        let clientId = this.summarie.clientId ?? this.shipment.client.id
        this.updateConceptSearch(item.name, invoiceFee, clientId)
        console.log("=>(ventaBL.vue:1581) invoiceFee", invoiceFee);
        this.summarie.fees.push(invoiceFee);
        this.currencieSelected = item.currency.code
        this.summarie.fees.forEach((element) => {
          if (element.currencyId) {
            if (element.currencyId !== item.currencyId) {
              isDifferent = true
            }
          }
        })
        if (isDifferent) {
          this.cleanSummarie2()
          this.getSale()
          this.$toast.error('Las monedas no pueden ser diferentes')
        }
      } else {
        item.selected = false
        this.summarie.fees.splice(this.summarie.fees.findIndex(x => x.saleFeeId === item.id), 1)
      }
    },
    print() {
      console.log(this.summarie.fees.length > 0);
    },
    cleanSummarie() {
      this.summarie.itemsNC = []
      this.summarie.creditNotes = []
      this.summarie.payments = []
      this.notas = ''
      this.summarie.documents = []
      this.summarie.orderClientReference = ''
      this.invoiceDocuments = []
      this.sales.details[0].fees.forEach((element) => {
        if (element.selected) {
          element.selected = false;
        }
      });
      this.summarie.folio = null
      this.date = new Date().toISOString().slice(0, 10);
      // this.summarie.dueDate = new Date();
      this.fecha = true;
      let arr = [];
      this.sales.details[0].fees.push(arr);
      this.sales.details[0].fees.splice(-1);
      this.summarie.fees = [];
      this.summarie.clientId = null;
      this.summarie.SupplierId = 0;
      this.summarie.TotalAdvance = 0;
      this.summarie.status = "No hay solicitud";
      this.dateOfPayment = "2022-01-18";
      // this.date = this.summarie.dateOfPayment;
      this.sFecha = false;
      this.eEmbarque = false;
      this.summarie.invoiceId = null;
    },
    getInvoces() {
      this.itemsSolicitudes = [];
      this.$http
          .get(
              `Shipping/${this.shipment.id}/sale/${this.sales.details[0].shippingSaleId}/invoices`
          )
          .then((response) => {
            this.itemsSolicitudes = response.data.data;
            const invoiceSaleFeeIds = this.itemsSolicitudes.filter(x => x.status !== "Cancelado").map(x => x.fees).flat()
            console.log("=>(ventaBL.vue:1640) this.itemsSolicitudes", this.itemsSolicitudes);

            this.sales.details.forEach((detail) => {
              let fees = detail.fees.map((fee) => {
                if (invoiceSaleFeeIds.includes(fee.id)) {
                  fee.disable = true;
                }
                let invoiceFee = this.itemsSolicitudes.find(x => x.fees.find(f => f === fee.id))
                if (invoiceFee?.refCode) {
                  return {
                  ...fee,
                  invoiceRefCode: invoiceFee.refCode
                  }
                }
                return {
                  ...fee,
                  invoiceRefCode: ''
                }
              });
              detail.fees = fees
            });
          });
    },
    addSuggestedFee(index) {
      let suggestedFee = this.saleFees[index]
      suggestedFee = {
        ...suggestedFee,
        salesDetailId: this.sales.details[0].id,
        isLast: true,
        isSuggested: false
      }
      const existingFees = this.saleFees.map(fee => {
        return {
          ...fee,
          isLast: false
        }
      })
      this.saleFees = [...existingFees]
      this.saleFees[index] = suggestedFee
    },
    getSale(isSuggested) {
      this.sales = [];
      this.totalProfit = 0;
      if (!this.shipment.id) {
        setTimeout(() => {
          this.getSale()
        }, 2000)
      } else {
        this.$http
            .get(`Shipping/${this.shipment.id}/sale`)
            .then((response) => {
              this.sales = response.data.data;
              let ventaTotal = 0;
              let profitTotal = 0;

              if ((this.shipment.transportationId === 1 || this.shipment.transportationId === 3) && this.shipment.transportationCategoryId !== 9) {
                this.headers = [
                  {
                    text: "Cargo",
                    value: "name",
                  },
                  {
                    text: "Cantidad",
                    value: "quantity",
                  },
                  {
                    text: "Compra",
                    value: "price",
                  },
                  {
                    text: "Venta sin profit",
                    value: "ventaProfit",
                  },
                  {
                    text: "Profit",
                    value: "profit",
                  },
                  {
                    text: "Venta total",
                    value: "ventaTotal",
                  },
                  {
                    text: "Moneda",
                    value: "currency",
                  },
                  {
                    text: "IVA",
                    value: "hasVat",
                  },
                  {
                    text: "Folio de Factura",
                    value: "invoiceRefCode",
                  },
                  {
                    text: "",
                    value: "action",
                  },
                ];

              } else {
                this.headers = [
                  {
                    text: "Cargo",
                    value: "name",
                  },
                  {
                    text: "Ratable weight",
                    value: "ratableWeight",
                  },
                  {
                    text: "Compra",
                    value: "price",
                  },
                  {
                    text: "Venta sin profit",
                    value: "ventaProfit",
                  },
                  {
                    text: "Profit",
                    value: "profit",
                  },
                  {
                    text: "Venta total",
                    value: "ventaTotal",
                  },
                  {
                    text: "Moneda",
                    value: "currency",
                  },
                  {
                    text: "IVA",
                    value: "hasVat",
                  },
                  {
                    text: "Folio de Factura",
                    value: "invoiceRefCode",
                  },
                  {
                    text: "",
                    value: "action",
                  },
                ];
              }
              this.sales.details[0].fees.forEach((element) => {
                ventaTotal = ventaTotal + element.total
                profitTotal = profitTotal + element.price
              })
              this.sales.details[0].fees = this.sales.details[0].fees.map((element, index) => {
                return {
                  conceptItems: [],
                  isFirst: index === 0,
                  isLast: index === this.sales.details[0].fees.length - 1,
                  isSuggested: false,
                  disable: false,
                  ...element
                }
              })
              if (!isSuggested) {
                let sFees = this.shipment.sale.suggestedFees.map((element) => {
                  return {
                    isSuggested: true,
                    disable: false,
                    ...element
                  }
                })

                sFees.forEach((element) => {
                  if(!this.sales.details[0].fees.some(fee => fee.purchaseFeeId === element.purchaseFeeId)){
                    this.sales.details[0].fees.push(element)
                  } 
                })
              }


              let restante = ventaTotal - profitTotal
              restante = restante * 100 / ventaTotal

              this.totalProfit = restante
              if (isNaN(this.totalProfit)) {
                this.totalProfit = 0
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.getInvoces();
            });
      }
    },
    setFirstLast: function (currItems) {
      // first check that actually exists values
      if (currItems.length) {
        // toggle all to false
        currItems.forEach((x) => {
          x.isLast = false;
          x.isFirst = false;
        });
        // just set first to true
        currItems[currItems.length - 1].isLast = true;
        currItems[0].isFirst = true;
      }
    },
    getAllConcepts2(search) {
      if (search !== null) {
        if (this.oldSearch2 === null) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/payment/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            toFilter.forEach((element) => {
              let newCurrency = ''
              // por alguna razon me cambiaron los currencies alv xd
              if (this.currencieSelected === "USD") {
                newCurrency = 'US dollar'
              }
              if (this.currencieSelected === "EUR") {
                newCurrency = ''
              }
              if (this.currencieSelected === 'MXN') {
                newCurrency = 'Mexican peso'
              }
              if (element.currency === newCurrency) {
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
          })
        }
        if (this.oldSearch2 !== search && this.guardoIndex2 !== this.indexConcept2) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/sale/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            let newCurrency = ''
            // por alguna razon me cambiaron los currencies alv xd
            if (this.currencieSelected === "USD") {
              newCurrency = 'US dollar'
            }
            if (this.currencieSelected === "EUR") {
              newCurrency = 'Euro'
            }
            if (this.currencieSelected === 'MXN') {
              newCurrency = 'Mexican peso'
            }
            toFilter.forEach((element) => {
              console.log(element.currency, newCurrency)
              if (element.currency === newCurrency) {
                console.log('sientra')
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
        if (this.guardoIndex2 !== this.indexConcept2 && this.oldSearch2 === search) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/payment/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            toFilter.forEach((element) => {
              let newCurrency = ''
              // por alguna razon me cambiaron los currencies alv xd
              if (this.currencieSelected === "USD") {
                newCurrency = 'US dollar'
              }
              if (this.currencieSelected === "EUR") {
                newCurrency = ''
              }
              if (this.currencieSelected === 'MXN') {
                newCurrency = 'Mexican peso'
              }
              if (element.currency === newCurrency) {
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
        if (this.guardoIndex2 === this.indexConcept2 && this.oldSearch2 !== search) {
          this.guardoIndex2 = JSON.parse(JSON.stringify(this.indexConcept2))
          this.oldSearch2 = search
          this.$http.get(`shipping/sale/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            let toFilter = response.data.data
            this.summarie.itemsNC[this.indexConcept2].conceptItems = []
            console.log(toFilter)
            let newCurrency = ''
            // por alguna razon me cambiaron los currencies alv xd
            if (this.currencieSelected === "USD") {
              newCurrency = 'US dollar'
            }
            if (this.currencieSelected === "EUR") {
              newCurrency = 'Euro'
            }
            if (this.currencieSelected === 'MXN') {
              newCurrency = 'Mexican peso'
            }
            toFilter.forEach((element) => {

              console.log(element.currency, newCurrency)
              if (element.currency === newCurrency) {
                this.summarie.itemsNC[this.indexConcept2].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
      }
    },
    searchClients: debouncePromise(function (search) {
      if (search === '') {
        return
      }
      this.getClients(search)
    }, 300),
    searchConcepts: debouncePromise(function (search, clientId) {
      if (!search) {
        return []
      }
      return this.getConcept(search, clientId)
    }, 300),
    searchDebounce2: debouncePromise(function (search) {
      if (search === '') {
        return
      }
      this.getAllConcepts2(search)
    }, 1000),
    searchDebounce: debouncePromise(function (search) {
      if (search !== '') {
        if (this.oldSearch === null) {
          this.guardoIndex = JSON.parse(JSON.stringify(this.indexConcept))
          this.oldSearch = search
          this.$http.get(`shipping/sale/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            this.summarie.fees[this.indexConcept].conceptItems = []
            let toFilter = response.data.data
            console.log(toFilter)
            let newCurrency = '';
            if (this.currencieSelected === 'USD') {
              newCurrency = 'US dollar'
            }
            if (this.currencieSelected === 'MXN') {
              newCurrency = 'Mexican peso'
            }
            if (this.currencieSelected === 'EUR') {
              newCurrency = 'Euro'
            }
            let concepts = []
            toFilter.forEach((element) => {
              if (element.currency === newCurrency) {
                // this.summarie.fees[this.indexConcept].conceptItems.push(element)
                concepts.push(element)
              }
            })
            concepts.forEach((element) => {
              this.summarie.fees[this.indexConcept].conceptItems.push(element)
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
        if (this.oldSearch !== search && this.guardoIndex !== this.indexConcept) {
          this.guardoIndex = JSON.parse(JSON.stringify(this.indexConcept))
          this.oldSearch = search
          this.$http.get(`shipping/sale/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            this.summarie.fees[this.indexConcept].conceptItems = []
            let toFilter = response.data.data
            console.log(toFilter)
            let newCurrency = '';
            if (this.currencieSelected === 'USD') {
              newCurrency = 'US dollar'
            }
            if (this.currencieSelected === 'MXN') {
              newCurrency = 'Mexican peso'
            }
            if (this.currencieSelected === 'EUR') {
              newCurrency = 'Euro'
            }
            toFilter.forEach((element) => {
              if (element.currency === newCurrency) {
                this.summarie.fees[this.indexConcept].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
        if (this.guardoIndex !== this.indexConcept && this.oldSearch === search) {
          this.guardoIndex = JSON.parse(JSON.stringify(this.indexConcept))
          this.oldSearch = search
          this.$http.get(`shipping/sale/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            this.summarie.fees[this.indexConcept].conceptItems = []
            let toFilter = response.data.data
            console.log(toFilter)
            let newCurrency = '';
            if (this.currencieSelected === 'USD') {
              newCurrency = 'US dollar'
            }
            if (this.currencieSelected === 'MXN') {
              newCurrency = 'Mexican peso'
            }
            if (this.currencieSelected === 'EUR') {
              newCurrency = 'Euro'
            }
            toFilter.forEach((element) => {
              if (element.currency === newCurrency) {
                this.summarie.fees[this.indexConcept].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
        if (this.guardoIndex === this.indexConcept && this.oldSearch !== search) {
          this.guardoIndex = JSON.parse(JSON.stringify(this.indexConcept))
          this.oldSearch = search
          this.$http.get(`shipping/sale/concepts`, {
            params: {
              name: search
            }
          }).then((response) => {
            this.summarie.fees[this.indexConcept].conceptItems = []
            let toFilter = response.data.data
            console.log(toFilter)
            let newCurrency = '';
            if (this.currencieSelected === 'USD') {
              newCurrency = 'US dollar'
            }
            if (this.currencieSelected === 'MXN') {
              newCurrency = 'Mexican peso'
            }
            if (this.currencieSelected === 'EUR') {
              newCurrency = 'Euro'
            }
            toFilter.forEach((element) => {
              if (element.currency === newCurrency) {
                this.summarie.fees[this.indexConcept].conceptItems.push(element)
              }
            })
            console.log(response)
          }).catch((error) => {
            console.log(error)
            this.$toast.error('No hay conceptos generales');
          })
        }
      }
    }, 1000)
  },
  computed: {
    totalsPayments() {
      let totalPayments = 0
      if (this.summarie.payments.length > 0) {
        totalPayments = this.summarie.payments.reduce(
            (acc, d) => {
              acc.paidAmount += d.paidAmount;
              acc.pendingAmount += d.pendingAmount;

              return acc;
            },
            {
              pendingAmount: 0,
              paidAmount: 0,
            }
        );
      }
      return totalPayments;
    },
    totals() {
      console.log("=>(ventaBL.vue:2179) this.summarie.fees", this.summarie.fees);
      const totals = this.summarie.fees.reduce(
          (acc, d) => {
            d.subTotal = d.price ? d.quantity * d.price + d.profit : d.total;
            acc.quantity += +d.quantity;
            acc.ratableWeight += d.ratableWeight;
            acc.price += d.price;
            acc.subTotal +=  d.price ? d.quantity * d.price + d.profit : d.total;
            acc.iva += d.saleConceptId ? this.setIva(d.subTotal, d.saleConcept.description) : 0
            acc.retencion += d.saleConceptId ? this.setRetencion(d.subTotal, d.saleConcept.description) : 0
            return acc;
          },
          {
            ratableWeight: 0,
            total: 0,
            quantity: 0,
            price: 0,
            iva: 0,
            retencion: 0,
            subTotal: 0,
          }
      );
      totals.total = totals.subTotal + totals.iva - totals.retencion
      return totals;
    },
    totalsNC() {
      const totals = this.summarie.creditNotes[this.tabCreditNote].fees.reduce(
          (acc, d) => {
            acc.subtotal += d.total
            acc.quantity += d.quantity;
            acc.iva += d.saleConceptId ? this.setIva(d.total, d.saleConcept.description) : 0
            acc.retIva += d.saleConceptId ? this.setRetencion(d.total, d.saleConcept.description) : 0
            //acc.retIsr += d.saleConceptId ? this.setRetencionISR(d.total, d.saleConcept.description) : 0
            return acc;
          },
          {
            subtotal: 0,
            quantity: 0,
            iva: 0,
            retIva: 0,
            retIsr: 0,
            total: 0
          }
      );
      totals.total = totals.subtotal + totals.iva - totals.retIva - totals.retIsr
      return totals;
    },
  },

  watch: {
    'summarie.creditNotes': {
      handler: debouncePromise(function () {
        let check = false;
        this.elSuperTotal2 = 0
        this.summarie.creditNotes.forEach((element) => {
          this.elSuperTotal2 += (element.price)
          if (!element.conceptId) {
            check = true;
          }
          if (check) {
            this.isConcept2 = true;
          } else {
            this.isConcept2 = false
          }
        })
        if (this.indexConcept2 !== null) {
          this.searchDebounce2(this.summarie.creditNotes[this.indexConcept2].searchConcept)
        }
      }, 1500),
      deep: true
    },
    'summarie.searchInvoiceTo': {
      handler() {
        this.searchInvoiceTo(this.summarie.searchInvoiceTo)
      }
    },
    "shipment.id": {
      handler(oldVa, newVa) {
        console.log("=>(ventaBL.vue:2244)", oldVa);
        console.log("=>(ventaBL.vue:2244)", newVa);
        this.cleanSummarie()
        this.getSale();
        setTimeout(() => {
          this.getInvoces();
        }, 500);

      },
      deep: true,
    },
    'summarie.fees': {
      handler() {
        this.elSuperTotal = 0
        this.summarie.fees.forEach((element) => {
          this.elSuperTotal += element.total

          if (!element.saleConceptId || element.saleConceptId === 0) {
            element.conceptMessage = 'Hubo un error al seleccionar el concepto. Favor de seleccionar nuevamente'
            this.isConcept = false;
          } else {
            element.conceptMessage = ''
            this.isConcept = true
          }
        })
      },
      deep: true,
    },
    conceptSearch: {
      handler(val) {
        console.log("Conceptos de inicio", this.concepts)
        const selectedConcept = this.concepts?.find(x => x.name == val)
        if (selectedConcept?.name == val) return
        if (this.concepts?.length > 0) return this.concepts
        if (val) this.searchConcepts(this.conceptSearch)
      },
      deep: true
    },
    clientSearch: {
      handler(val) {
        this.clientSearchLoading = true
        const selectedClient = this.clients.find(x => x.name === val)
        if (selectedClient?.name === val) {
          this.clientSearchLoading = false
          return
        }
        if (val) this.searchClients(this.clientSearch)
        this.clientSearchLoading = false
      }
    },
    date() {
      this.summarie.dueDate = this.date;
    },
    sales: {
      handler() {
        let suggestedFees = [];
        let noSuggestedFees = [];
        let newFees = [];
        let fees = [];

        this.sales?.details?.forEach(detail => {
          noSuggestedFees.push(...detail.fees?.filter(fee => fee.id !== 0 && !fee.isSuggested))
          suggestedFees.push(...detail.fees?.filter(fee => fee.id === 0 && fee.isSuggested))
        })

        noSuggestedFees = noSuggestedFees.map((fee, index) => {
          return {
            ...fee,
            isLast: index === noSuggestedFees.length - 1
          }
        })

        fees = [...noSuggestedFees, ...suggestedFees, ...newFees]

        fees.concat(newFees);

        this.saleFees = fees.map(fee => {

          fee.saleConcept = fee.saleConceptId ? {
            ...fee.saleConcept,
            name: fee.saleConcept?.description
          } : null
          return {
            ...fee,
            name: fee.saleConcept ? fee.saleConcept.name : fee.name,
            saleConcept: fee.saleConcept ?? {name: fee.name},
          }
        })
      },
      deep: true
    },
  },
  mounted() {
    this.getSale();
    //this.getRfc();
    //this.getConcept();
    //this.getClientes();
    // this.getCurrencies();
  },
};
</script>
<style scoped>
.bgGreen {
  background-color: #c9c97c;
}

.v-expansion-panel::before {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
