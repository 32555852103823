<template>
  <index-quote></index-quote>
</template>

<script>
import IndexQuote from "@/components/Index-Quote";
export default {
name: "Quote",
  components: {IndexQuote}
}
</script>

<style scoped>

</style>