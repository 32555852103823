<template>
  <div>

    <v-row no-gutters justify="center">
      <v-col md="3" class="pl-2">

        <v-card class="mb-5 offset-7" width="200" tile v-if="showRateOptions">
          <v-card-title>Servicios</v-card-title>
          <v-card-text>
            <v-checkbox
                v-model="rateItem.services[0].value"
                label="PickUp"
            ></v-checkbox>

            <v-checkbox
                v-model="rateItem.services[1].value"
                label="Gastos de Origen"
            ></v-checkbox>

            <v-checkbox
                v-model="rateItem.services[2].value"
                label="Flete Internacional"
            ></v-checkbox>

            <v-checkbox
                v-model="rateItem.services[3].value"
                label="Gastos de Destino"
            ></v-checkbox>

            <v-checkbox
                v-model="rateItem.services[4].value"
                label="Entrega"
            ></v-checkbox>
          </v-card-text>
        </v-card>

        <v-card class="mt-3 mb-5 offset-7" width="200" tile v-if="showRateOptions">
          <v-card-title>Shiping Line</v-card-title>
          <v-card-text>
            <div v-for="(ship,index) in shippers" :key="ship.id">
              <v-checkbox
                  v-model="rate[index].enable"
                  :label=ship.name

              ></v-checkbox>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6">
                  <v-row no-gutters>
                    <v-col md="12" :key="increment">
                      <RateCard
                          class="mt-2"
                          v-for="(rateItem,index) in rate"
                          :key="index"
                          v-bind:QuotData="QuotData"
                          v-bind:cargo="cargo"
                          v-bind:data-item="rateItem"
                          v-on:showRateOptions="showRate"
                          :variables="search"
                      />
                    </v-col>
                  </v-row>
      </v-col>

      <v-col md="3" class="pl-12">
        <v-row class="pt-2" no-gutters>
          <v-card class="mb-5" width="350" tile v-if="showRateOptions">
            <v-card-title>Tipo de Contenedor</v-card-title>
            <v-card-text>
              <div v-for="cargoItem in cargo" :key="cargoItem.id">
                <v-row>
                  <v-col md="6">
                    <h3 class="font-weight-light pt-3">{{ cargoItem.container.description }}</h3>
                  </v-col>

                  <v-col md="6">
                    <v-row no-gutters>
                      <v-col md="2">
                        <v-btn icon class="pt-2" @click="cargoItem.quantity++">
                          <v-icon color="primary">mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col md="6" class="ml-5">
                        <v-text-field class="pa-0 col-md-12 centered-input" type="number"
                                      v-model="cargoItem.quantity"></v-text-field>
                      </v-col>

                      <v-col md="2">
                        <v-btn icon class="pt-2" @click="cargoItem.quantity--">
                          <v-icon color="primary">mdi-minus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>


    </v-row>


  </div>
</template>

<script>
import gql from 'graphql-tag'


import RateCard from "../Rates/RateCard";
export default {
  name: "Offers",
  components: {RateCard},
  props: {
    search: {
      type: Object,
      default: () => {
      },
    },
    QuotData: {
      type: Object
    }
  },
  watch: {
    search: function () {
      this.isMapVisible = false;
    },



    getFCLSeaRate:function (){
      this.getResults();
    },

  },


  data() {
    return {
      date: '2021-01-18',
      markers: [],
      showRateOptions: false,
      selected: false,
      cargo: [],
      increment:0,
      result: {},
      rateItem: {},
      quantity: 0,
      shippers: [],
      Containers: [],
      rateTest: [],
      rate: [],
      itemss: [
        {
          color: 'white',
          src: 'https://lp-cms-production.imgix.net/2019-06/GettyImages-122180577_high.jpg?auto=format&fit=crop&ixlib=react-8.6.4&h=520&w=1312',
          title: 'New York',
          servico: 'Maritmo',
          fecha: 'Jan 14-70',
          costo: '600 USD',

        },
        {
          color: '#white',
          src: 'https://assets.simpleviewcms.com/simpleview/image/upload/c_limit,h_1200,q_75,w_1200/v1/clients/lasvegas/strip_b86ddbea-3add-4995-b449-ac85d700b027.jpg',
          title: 'Las Vegas',
          servico: 'Maritmo',
          fecha: 'Jan 14-70',
          costo: '200 USD',
        },
        {
          color: '#white',
          src: 'https://recruit4languages.com/app/uploads/2020/05/Paris.jpg',
          title: 'Paris',
          servico: 'Maritmo',
          fecha: 'Jan 14-70',
          costo: '1,200 USD',
        },
      ],
      isMapVisible: true,
    }
  },
  apollo: {
    getFCLSeaRate: {
      query: gql`query GetRate($search: Search!) {
  getFCLSeaRate(search: $search, page:1 , limit: 3) {
    totalPages
    currentPage
    result {
      ... on StandardFCLRate {
        __typename
        maritime {
          _id
          frequency
          reference_number
          validity_from
          validity
          validity_to
          travel_time
          supplier {
            _id
            name
            details {
              logo
            }
          }
          pol {
            _id
            name
            city
            country
            code
            unlocs
          }
          pod {
            _id
            name
            city
            country
            code
            unlocs
          }
          container {
            type
            name
            price
            currency
            fees {
              rate

              amount
              type
            }
          }
          vesselFees {
            rate
            amount
            currency
            description
          }
        }
        origin {
          _id
          validity
          truckService
          supplier {
            _id
            name
            details {
              logo
            }
          }
          origin {
            Coordinates
            Country
            Location
            Name
          }
          destination {
            name
            city
            country
            code
            coordinates
            unlocs
          }
          container{
            size
            type
            name
            prices{
              type
              amount
            }
          }
        }
        bestOffer{
          originId
          maritimeId
          price
        }
      }
    }

  }
}
`,
      variables() {
        return {
          search: this.search
        }
      }
    }
  },

  methods: {

    searchOffers(){
      this.$apollo.queries.getFCLSeaRate.skip = false

    },

    setRate() {
      console.log("getResult");
    },

    showRate(data) {
      console.log("data", data)
      this.rateItem = data;
      let index = this.rate.findIndex(e => e.id === data.id)
      this.rate.forEach(element => {
        if (element.id != data.id) {
          element.isExpanded = false;
        } else
          this.rate[index].isExpanded = true;
      })
      this.cargo = [];

      this.getContainerFromRate(data);
      this.showRateOptions = true;
    },

    getContainerFromRate(data) {
      let containers = [];
      data.maritime.container.forEach(element => {
        containers.push(
            {
              type: element.type[0],
              name: element.name,
              price: element.price,
              currency: element.currency
            }
        )
      })
      let container = null;
      containers.forEach(element => {
        container = this.Containers.find(e => e.description == element.type);
        this.cargo.push(
            {
              name: element.name,
              price: element.price,
              stackable: false,
              tiltable: false,
              totalWeight: false,
              container: container,
              containerId: container.id,
              currency: element.currency,
              type: "Container",
              quantity: 0,
              landFull: false,
              weight: 21000,
              dangerous: false,
              large: 0,
              width: 0,
              legal: true,
              height: 0,
              unitMeasure: "Mts",
              unit: "Kg",
              weightUnitMeasure: "Kg",
              modified: false,
              loadingTypeId: null,
              IMO: null,
              UN: null,

            },
        )

      })
      console.log(this.cargo)
    },

     getResults() {
      this.rate = JSON.parse(JSON.stringify(this.getFCLSeaRate.result))
      console.log('this rate', this.rate);
       this.getContainers();
       this.getShippingByOffer();
      this.rate = this.rate.map((item)=>{
        this.count++;

        return {
          ...item,
          id:this.count,
          services: [
            {
              id: 1,
              name: "Recoleccion",
              value: false
            },
            {
              id: 2,
              name: "gastosEnOrigen",
              value: false,
            },

            {
              id: 3,
              name: "internationalMaritimeTransportation",
              value: true
            },

            {
              id: 4,
              name: "gastosEnDestino",
              value: false
            },
            {
              id: 5,
              name: "Entrega",
              value: false
            }
          ],
          enable: true,
          isExpanded: false,
        }
      })
       this.increment++;
     },

    getShippingByOffer() {
      this.shippers = [];
      this.count = 0;
      this.rate.forEach(element => {
        this.count++;
        this.shippers.push({
          id:this.count,
          enable:true,
          name:element.maritime.supplier.name
        })
      })
    },

    getContainers: function () {
      this.$http.get("Containers")
          .then(response => {
            this.Containers = response.data.data;
            this.Containers = this.Containers.filter(element => element.transportationId === 1)
            // console.log(this.containers)

          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
  },

  mounted() {
    this.$apollo.queries.getFCLSeaRate.skip = true;
    this.getContainers();
    this.getShippingByOffer();

  }
}
</script>

<style scoped>

.centered-input >>> input {
  text-align: center
}

</style>
