<template>
  <IndexLogin></IndexLogin>
</template>

<script>
import IndexLogin from "@/components/Index-Login";
export default {
name: "Login",
  components: {IndexLogin}
}
</script>

<style scoped>

</style>