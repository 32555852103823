export default {
    data: () => ({
        pricingUsers: [],
    }),
    methods: {
        getPricers: function () {
            this.$http.get('Employees/Pricing')
                .then(response => {
                    this.pricingUsers = response.data.data;
                })
                .catch(error => {
                    console.log(error)
                })
        },
        updatePricer: function(item){
            this.$store.dispatch('working', true)
            this.$http.patch(`Orders/${item.id}`, [
                {
                    "op": "replace",
                    "path": "/pricerId",
                    "value": item.pricerId
                },
            ])
                .finally(() => {
                    this.$store.dispatch('working', false)
                })
        },
    },
    mounted() {
        this.getPricers();
    }
}