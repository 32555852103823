var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-table-edit")]),_vm._v(" Agregar Proveedor ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":"","left":""},on:{"click":function($event){_vm.sheet = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('Suppliers')],1)],1),_c('v-card',{staticClass:"pt-10",attrs:{"elevation":"0","tile":"","min-width":"2000"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-container',{staticStyle:{"margin-left":"0px","margin-right":"20px"},attrs:{"tile":"","elevation":"0"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","dense":"","solo":"","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-btn',{attrs:{"color":"blue darken-2","dark":""},on:{"click":function($event){_vm.sheet = true}}},[_vm._v(" Agregar Proveedor "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1)],1),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.HeaderSuppliers,"items-per-page":50,"items":_vm.Suppliers,"hide-default-footer":true,"item-key":"id"},scopedSlots:_vm._u([{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyName ? item.companyName : 'NA')+" ")]}},{key:"item.type.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.description ? item.type.description : 'NA')+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone ? item.phone : 'NA')+" ")]}},{key:"item.configuration.creditDays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.configuration.creditDays ? item.configuration.creditDays : 'NA')+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.GetDataSupplier(item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-table-edit")])],1)]}}])})],1)],1)],1)]],2)],1)],1),_c('EditDialogBase',{attrs:{"show":_vm.dialog},on:{"setDialog":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"primary white--text"},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-table-edit")]),_vm._v("Editar Proveedor")],1)]},proxy:true},{key:"data",fn:function(){return undefined},proxy:true}])}),_c('v-dialog',{attrs:{"width":"1500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Suppliers',{attrs:{"SupplierData":_vm.DataSupplier},on:{"closeDialog":function($event){_vm.dialog = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }