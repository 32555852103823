<template>
  <div>
    <v-row justify="center">
      <v-col md="8">
        <v-card color="primary" dark elevation="15">
          <v-card-title class="justify-center offset-1">

            <h1 class="font-weight-regular display-1 white--text">
              Administracion de Cuentas
            </h1>
          </v-card-title>

          <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              grow
          >

            <v-tab>Empleados</v-tab>
            <v-tab>Clientes</v-tab>
            <v-tab>Proveedores</v-tab>


          </v-tabs>

          <v-tabs-items v-model="tab">
            <template>
              <v-tab-item>
                <slot name="employees"></slot>
              </v-tab-item>
            </template>

            <template>
              <v-tab-item>
                <slot name="customers"></slot>
              </v-tab-item>
            </template>

            <template>
              <v-tab-item>
                <slot name="suppliers"></slot>
              </v-tab-item>
            </template>

          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>


  </div>
</template>

<script>
export default {
  name: "Account_Admin-Card",
  data() {
    return {
      tab: 0,
    }
  }
}
</script>

<style scoped>

</style>
