<template>
  <div>
      <h1 class="text-center secondary white">UNIT TEST</h1>
      <br/><br/>
      <testComponent/>
  </div>
</template>

<script>
    // Accounts Admin
// import testComponent from '../Accounts_Admin/Account_Admin-Card.vue'
// import testComponent from '../Accounts_Admin/Customers.vue'
// import testComponent from '../Accounts_Admin/Employees.vue'
// import testComponent from '../Accounts_Admin/Register.vue'
// import testComponent from '../Accounts_Admin/Suppliers.vue'
    // Auth
// import testComponent from '../Auth/AuthBase.vue'
// import testComponent from '../Auth/LoginFields.vue'
    // Global
// import testComponent from '../Global/loading/loader.vue'
// import testComponent from '../Global/Toast/Toast.vue'
    // Global components
// import testComponent from '../GlobalComponents/AutocompletePlaces/AutocompletePlaces.vue'
// import testComponent from '../GlobalComponents/Card-Status.vue'
    // Google Maps
// import testComponent from '../google-maps-autocomplete-vuetify/AutocompleteDetails/AutocompleteDetails.vue'
// import testComponent from '../google-maps-autocomplete-vuetify/index_resp.vue'
// import testComponent from '../google-maps-autocomplete-vuetify/index.vue'
    // Map
// import testComponent from '../Map/Map.vue'
    // Pricing
// import testComponent from '../Pricing/Tables/Pending.vue'
// import testComponent from '../Pricing/Tables/Request.vue'
// import testComponent from '../Pricing/Tables/Send.vue'
// import testComponent from '../Pricing/CancelationComments.vue'
// import testComponent from '../Pricing/DetailOrder.vue'
// import testComponent from '../Pricing/EditQuote.vue'
// import testComponent from '../Pricing/FormatFees.vue'
// import testComponent from '../Pricing/.vue'
// import testComponent from '../Pricing/NewOrderComments.vue'
// import testComponent from '../Pricing/OrderBaseData.vue'
// import testComponent from '../Pricing/PricerComments.vue'
// import testComponent from '../Pricing/ProceedQuote.vue'
// import testComponent from '../Pricing/RenegotiationComments.vue'
// import testComponent from '../Pricing/SuppliersAucomplete.vue'
// import testComponent from '../Pricing/Table-Menu.vue'
    // Quote
// import testComponent from '../Quote/QuotDetail/Card_Base.vue'
// import testComponent from '../Quote/QuotDetail/load_detail.vue'
// import testComponent from '../Quote/QuotDetail/Services.vue'
// import testComponent from '../Quote/Offers.vue'
// import testComponent from '../Quote/QuickQuote.vue' (Empty component)
// import testComponent from '../Quote/QuotData.vue'
// import testComponent from '../Quote/RequestQuote.vue'
    // Rates
// import testComponent from '../Rates/RateCard.vue'
    // Sales
// import testComponent from '../Sales/List/Tables/Received.vue'
// import testComponent from '../Sales/List/Tables/Request.vue'
// import testComponent from '../Sales/List/Tables/Send.vue'
// import testComponent from '../Sales/List/Card-Status.vue'
// import testComponent from '../Sales/List/CardTableBase.vue'
// import testComponent from '../Sales/List/Table-Menu.vue'
// import testComponent from '../Sales/DetailOrderSales.vue'
// import testComponent from '../Sales/FormatFeesSales.vue'
// import testComponent from '../Sales/ProceedQuoteSales.vue'
// import testComponent from '../Sales/TableMenuSales.vue'
    // Users
// import testComponent from '../Users/Categories/Categories.vue'
// import testComponent from '../Users/Employees/List-Employees.vue'
// import testComponent from '../Users/Card-Base.vue'
// import testComponent from '../Users/EditDialogBase.vue'
// import testComponent from '../Users/List-Customers.vue'
// import testComponent from '../Users/List-Suppliers.vue'
// import testComponent from '../Users/Listado.vue'
// import testComponent from '../Users/Table-Menu-Users.vue'
    // Testing
import testComponent from '../Test/TestComponent.vue'

export default {
    name: 'previewComponent',
    components: {
        testComponent
    }
}
</script>

<style scoped>
.white {
    color: #fff
}
</style>
