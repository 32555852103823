var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-row',{staticClass:"fill-height pb-14",attrs:{"align":"end"}},[_c('v-col',_vm._l((_vm.DataOrder.history),function(history,index){return _c('div',{key:index,class:['d-flex flex-row align-center my-2', _vm.isCurrentUser(history.user) ? 'justify-end': null]},[(_vm.isCurrentUser(history.user))?_c('v-sheet',{staticClass:"mr-3 text-right",attrs:{"dark":"","color":"primary"}},[_c('v-list-item',{attrs:{"dense":""}},[(history.historyDocuments.length > 0)?_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-paperclip ")])],1)]}}],null,true)},[_c('v-card',{attrs:{"flat":"","tile":"","width":"300"}},[_c('v-virtual-scroll',{attrs:{"items":history.historyDocuments,"height":"250","item-height":"42"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.downloadDocument(item,history.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.fileName)+"."+_vm._s(item.fileExtension)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)],1)],1)]}}],null,true)})],1)],1)],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(history.user.firstName)+" "+_vm._s(history.user.lastName)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(history.comments)+" ")])],1)],1)],1):_vm._e(),(!_vm.isCurrentUser(history.user))?_c('v-sheet',{staticClass:"ml-3 text-left",attrs:{"dark":"","color":"secondary"}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(history.user.firstName)+" "+_vm._s(history.user.lastName)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"}),_c('v-list-item-subtitle',{staticClass:"text-wrap"}),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(history.reason.description)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(history.comments)+" ")])],1),(history.historyDocuments.length > 0)?_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-paperclip ")])],1)]}}],null,true)},[_c('v-card',{attrs:{"flat":"","tile":"","width":"300"}},[_c('v-virtual-scroll',{attrs:{"items":history.historyDocuments,"height":"250","item-height":"42"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.downloadDocument(item,history.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.fileName)+"."+_vm._s(item.fileExtension)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)],1)],1)]}}],null,true)})],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }