<template>
  <v-btn
      color="blue darken-2"
      dark
      class="fixedOption"
      v-on:click="$emit('data', true)"
  >
    <v-icon x-small>mdi-frequently-asked-questions</v-icon>

    <span class="ml-1">Request Quote</span>
  </v-btn>
</template>

<script>
export default {
name: "RequestQuote"
}
</script>

<style scoped>

</style>
