<template>
  <v-row justify="center" no-gutters class="w-100">
    <v-tabs
      v-model="tab"
      background-color="white"
      icons-and-text
      centered
      color="grey"
      height="150"
    >
      <v-tab href="#provisional" @click="getOrders('', 1)">
        <CardStatus>
          <template v-slot:card-icon>
            mdi-battery-clock-outline
          </template>
          <template v-slot:card-title>
            Provisional
          </template>
          <template v-slot:QuantityStatus>
            <h1 class="font-weight-regular pt-3 text-center primary--text">
              {{ counts.provisional }}
            </h1>
          </template>
        </CardStatus>
      </v-tab>
      <v-tab href="#enTransito" @click="getOrders('', 2)">
        <CardStatus>
          <template v-slot:card-icon>
            mdi-transit-connection-horizontal
          </template>
          <template v-slot:card-title>
            En Tránsito
          </template>
          <template v-slot:QuantityStatus>
            <h1 class="font-weight-regular pt-3 text-center primary--text">
              {{ counts.inTransit }}
            </h1>
          </template>
        </CardStatus>
      </v-tab>
      <v-tab href="#arribados" @click="getOrders('', 3)">
        <CardStatus>
          <template v-slot:card-icon>
            mdi-email-send-outline
          </template>
          <template v-slot:card-title>
            Arribados
          </template>
          <template v-slot:QuantityStatus>
            <h1 class="font-weight-regular pt-3 text-center primary--text">
              {{ counts.arrived }}
            </h1>
          </template>
        </CardStatus>
      </v-tab>
      <v-tab href="#detenidos" @click="getOrders('', 4)">
        <CardStatus>
          <template v-slot:card-icon>
            mdi-stop-circle-outline
          </template>
          <template v-slot:card-title>
            Detenidos
          </template>
          <template v-slot:QuantityStatus>
            <h1 class="font-weight-regular pt-3 text-center primary--text">
              {{ counts.detained }}
            </h1>
          </template>
        </CardStatus>
      </v-tab>
      <v-tab href="#entregados" @click="getOrders('', 5)">
        <CardStatus>
          <template v-slot:card-icon>
            mdi-check-all
          </template>

          <template v-slot:card-title>
            Entregados
          </template>

          <template v-slot:QuantityStatus>
            <h1 class="font-weight-regular pt-3 text-center primary--text">
              {{ counts.delivered }}
            </h1>
          </template>
        </CardStatus>
      </v-tab>
      <v-tab href="#Terminadas" @click="getOrders('', 9)">
        <CardStatus>
          <template v-slot:card-icon>
            mdi-umbrella-closed-outline
          </template>
          <template v-slot:card-title>
            Terminadas
          </template>

          <template v-slot:QuantityStatus>
            <h1 class="font-weight-regular pt-3 text-center primary--text">
              {{ counts.finished }}
            </h1>
          </template>
        </CardStatus>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="width: 100%">
      <v-row justify="center" class="mt-3 ">
        <v-col md="7">
          <v-text-field
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            dense
            label="Buscar"
            single-line
            hide-details
            @input="SearchOrder(search)"
          ><template v-slot:append>
          <v-menu
            offset-y
            content-class="included"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    icon
                    small
                    class="mx-1"
                    v-bind="attrs"
                    v-on="{ ...menu, ...tooltip }"
                  >
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                Buscar por...
              </v-tooltip>
            </template>
            <v-card width="300">
              <v-card-text>
                <h2 class="font-weight-regular">Filtrar</h2>
                <v-row>
                  <v-col md="12">
                    <v-row justify="center">
                      <v-col md="12">
                        <v-autocomplete
                        label="Cliente"
                        v-model="filterCustomer"
                        :items="customers"
                        dense
                        item-value="id"
                        item-text="name"
                        clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12">
                    <v-row justify="center">
                      <v-col md="12">
                        <v-autocomplete
                        label="Incoterm"
                        v-model="filterIncoterm"
                        :items="incoterms"
                        item-text="code"
                        item-value="id"
                        dense
                        clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="filterOrders" color="primary">Buscar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </template></v-text-field>
        </v-col>
      </v-row>
      <div v-if="itemsOrders.length > 0" class="ml-12 mr-12 mt-5">
        <v-card elevation="0" width="2000" tile>
          <v-data-table
            :headers="headersOrders"
            :hide-default-footer="true"
            :items="itemsOrders"
          >
            <template v-slot:item.view="{ item }">
              <v-btn text ripple @click="seeDetail(item.id)">
                <v-icon color="primary">mdi-notebook-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-tab-item value="provisional"></v-tab-item>
    </v-tabs-items>
  </v-row>
</template>

<script>
import CardStatus from "@/components/Sales/List/Card-Status";
import debouncePromise from "debounce-promise";

export default {
  name: "operationsList",
  components: { CardStatus },
  data: () => ({
    filterCustomer: null,
    filterStatus: null,
    customers: [],
    incoterms: [],
    counts: {},
    filterBy: {},
    search: "",
    itemsOrders: [],
    headersOrders: [
      {
        text: "id",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Numero de referencia",
        value: "referenceNumber",
      },
      {
        text: "Cliente",
        value: "clientId",
      },
      {
        text: "Embarcador",
        value: "clientSupplierName",
      },
      {
        text: "Detalles",
        value: "view",
      },
    ],
    tab: 0,
  }),
  mounted() {
    this.getOrder();
    this.getCount();
    this.getCustomers();
    this.getIncoterms();
  },
  methods: {
    getIncoterms() {
      this.$store.dispatch("working", true);

      this.$http
        .get("Incoterms")
        .then((response) => {
          this.incoterms = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => this.$store.dispatch("working", false));
    },
    getCustomers() {
      this.$http
        .get("Companies")
        .then((response) => {
          console.log(response);
          this.customers = response.data.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    seeDetail(id){
      console.log(id)
      this.$router.push({path: `Operation/${id}`})
    },
    SearchOrder: debouncePromise(function(e) {
      console.log(e);
      this.getOrders(e);
    }, 1000),
    getCount() {
      this.$http.get("Shipping/count").then((response) => {
        console.log(response);
        this.counts = response.data.data;
      });
    },
    getOrders(e, status) {
      this.$store.dispatch("working", true);
      if (e) {
        this.$http
          .get(`shipping/Find?RefCode=${e}`)
          .then((response) => {
            console.log(response);
            this.$toast.info("Ordenes filtradas con exito!");
            this.itemsOrders = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un errorr");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
      } else {
        if (!status) {
          this.getOrder();
          this.$store.dispatch("working", false);
        } else {
          this.$http
            .get(`shipping/Find?ShippingStatusId=${status}`)
            .then((response) => {
              if (response.data.data) {
                this.$toast.info("Ordenes filtradas con exito!");
                this.itemsOrders = response.data.data;
              } else {
                this.$toast.error("Ocurrio un errorr");
              }
            })
            .catch((error) => {
              console.log(error);
              this.$toast.error("Ocurrio un errorr");
            })
            .finally(() => {
              this.$store.dispatch("working", false);
            });
        }
      }
    },
    getOrder() {
      this.$http.get("shipping/Find").then((response) => {
        console.log(response)
        this.itemsOrders = response.data.data;
      });
    },
    filterOrders() {
      this.filterBy = {};
      if (this.filterCustomer) {
        this.filterBy.ClientId = this.filterCustomer;
      }

      if (this.filterIncoterm) {
        this.filterBy.IncotermId = this.filterIncoterm;
      }

      this.$store.dispatch("working", true);
      let apiUrl = "shipping/Find";
      this.$http
        .get(apiUrl, { params: this.filterBy })
        .then((response) => {
          console.log(response.data.data);
          this.itemsOrders = response.data.data;
          this.$toast.info("Ordenes filtradas con exito!");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
  },
};
</script>

<style scoped>
.v-tab /deep/ {
  text-transform: none !important;
}

>>> .v-data-table td {
  padding: 0 20px;
}
</style>
