var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-grid tw-grid-cols-[40%_auto]"},[_c('section',{staticClass:"tw-px-2"},[_c('v-form',[_c('span',{staticClass:"tw-text-xs"},[_vm._v("Grupos")]),_c('v-chip-group',{attrs:{"multiple":"","show-arrows":""},model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}},_vm._l((_vm.items.pricerGroups),function(group){return _c('v-chip',{key:("chip-group-" + (group.groupName)),attrs:{"x-small":"","value":group.groupName}},[_vm._v(" "+_vm._s(group.groupName)+" ")])}),1),_c('v-autocomplete',{ref:"users",staticClass:"tw-text-sm",attrs:{"items":_vm.users,"item-text":function (item) { return item.fullName; },"item-value":function (item) { return item.userId; },"label":"Selecciona Usuarios","multiple":"","rules":_vm.userRules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item.fullName))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedUsers.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}},[_c('template',{slot:"prepend-item"},[_c('v-checkbox',{attrs:{"label":"Seleccionar Todos"},on:{"change":function () {
                if (_vm.selectAllUsers) {
                  _vm.selectedUsers = _vm.users.map(function (item) { return item.userId; })
                } else {
                  _vm.selectedUsers = []
                }
              }},model:{value:(_vm.selectAllUsers),callback:function ($$v) {_vm.selectAllUsers=$$v},expression:"selectAllUsers"}})],1)],2)],1),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"date",staticClass:"tw-text-sm",attrs:{"label":"Selecciona fecha","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.dateRules},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDatePickerOpen),callback:function ($$v) {_vm.isDatePickerOpen=$$v},expression:"isDatePickerOpen"}},[_c('v-date-picker',{attrs:{"range":""},on:{"input":function($event){_vm.isDate = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('div',[_c('v-btn-toggle',{attrs:{"tile":"","group":""},model:{value:(_vm.groupByPeriod),callback:function ($$v) {_vm.groupByPeriod=$$v},expression:"groupByPeriod"}},[_c('v-btn',{attrs:{"value":"DAY","x-small":""}},[_vm._v(" Día ")]),_c('v-btn',{attrs:{"value":"WEEK","x-small":""}},[_vm._v(" Semana ")]),_c('v-btn',{attrs:{"value":"MONTH","x-small":""}},[_vm._v(" Mes ")]),_c('v-btn',{attrs:{"value":"YEAR","x-small":""}},[_vm._v(" Año ")])],1)],1)]),_c('div',{staticClass:"tw-border-t"},[_c('v-data-table',{staticClass:"tw-text-xs",attrs:{"items":_vm.pricingOrders.orders,"headers":_vm.headersOrders,"items-per-page":15,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.refCode",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"tw-w-[70px]"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"tw-text-xs tw-truncate"},'span',attrs,false),on),[_vm._v(_vm._s(item.refCode))])]}}],null,true)},[_c('span',{staticClass:"tw-text-xs"},[_vm._v(_vm._s(item.refCode))])])],1)]}}])}),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":7,"circle":""},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}})],1)],1),_c('section',[_c('section',{staticClass:"tw-grid tw-grid-cols-[25%_auto]"},[_c('div',{staticClass:"tw-border tw-border-gray-400 tw-justify-between"},[_c('div',{staticClass:"tw-border-b tw-border-gray-400 tw-h-1/2 tw-pb-2 tw-px-1 tw-overflow-y-scroll"},[_c('v-list',[_c('v-subheader',{staticClass:"tw-text-sm tw-font-semibold"},[_vm._v("Usuarios")]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedOrderUsers),callback:function ($$v) {_vm.selectedOrderUsers=$$v},expression:"selectedOrderUsers"}},_vm._l((_vm.pricingAnalytics.perUser),function(user){return _c('v-list-item',{key:user.userId,attrs:{"value":user.userId}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"!tw-text-xs"},[_vm._v(_vm._s(user.fullName))])],1),_c('v-list-item-action',[_c('v-badge',{staticClass:"tw-mr-2",attrs:{"color":"primary","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{staticClass:"!tw-text-xs"},[_vm._v(_vm._s(user.total))])]},proxy:true}],null,true)})],1)],1)}),1)],1)],1),_c('div',{staticClass:" tw-h-1/2 tw-px-1"},[_c('v-list',[_c('v-subheader',{staticClass:"tw-text-sm tw-font-semibold"},[_vm._v("Estatus")]),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedOrderStatus),callback:function ($$v) {_vm.selectedOrderStatus=$$v},expression:"selectedOrderStatus"}},_vm._l((_vm.pricingAnalytics.perStatus),function(ref){
              var status = ref.status;
              var total = ref.total;
return _c('v-list-item',{key:status,attrs:{"value":status}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"!tw-text-xs"},[_vm._v(_vm._s(_vm.orderStatus[status]))])],1),_c('v-list-item-action',[_c('v-badge',{staticClass:"tw-mr-2",attrs:{"color":"primary","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{staticClass:"!tw-text-xs"},[_vm._v(_vm._s(total))])]},proxy:true}],null,true)})],1)],1)}),1)],1)],1)]),_c('div',{staticClass:"tw-min-h-screen"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAnalyticsLoading),expression:"isAnalyticsLoading"}],staticClass:"tw-flex tw-items-center tw-h-full"},[_c('v-progress-circular',{staticClass:"tw-mx-auto",attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAnalyticsLoading),expression:"!isAnalyticsLoading"}]},[_c('apexchart',{ref:"ordersPerPeriodChart",attrs:{"type":"area","height":"350","options":_vm.chartOptions,"series":_vm.ordersPerPeriod}}),_c('apexchart',{ref:"orderSentPerPeriodChart",attrs:{"type":"area","height":"350","options":_vm.orderSentChartOptions,"series":_vm.orderSentPerPeriod}}),_c('apexchart',{ref:"orderSoldPerPeriodChart",attrs:{"type":"area","height":"350","options":_vm.orderSoldChartOptions,"series":_vm.orderSoldPerPeriod}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }