<template>
  <div>
    <v-dialog v-model="dialog" width="1000">
      <v-card>
        <slot name="title"></slot>
        <slot name="data"></slot>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditDialogBase",
  props:{
    show:Boolean,
    id:Number
  },
  watch:{
    id:function (val) {
      if (val){
        this.GetDataOrder();

      }
    }
  },
  computed:{
    dialog:{
      get:function (){
        return this.show
      },

      set:function (val){
        this.$emit('setDialog', val)
      }
    }
  },
  data(){
    return{
      DataEmployee:[],

    }
  },
  methods:{
    GetData:function () {

      this.$emit('idOrder',this.id);

      console.log('Hello')

      this.$store.dispatch('working',true)

      this.$http.get(`Employees/${this.id}`).then(response => {
        console.log(response)
        this.DataEmployee = response.data.data;
        this.id = 0;

      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    }

  },
  mounted() {
    // Mutating the prop :(
    this.show = false;
    this.GetData();
  }
}
</script>

<style scoped>

</style>
